.land-overlay {
  /* min-height: 100vh; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.land-top {
  background: url("image/curtain3.png") center / cover;
}

.land-container {
  width: 90%;
  max-width: 1000px;
  margin: 50px auto 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.land-left {
  width: 60%;
  height: 600px;
  background: url(image/e2.jpg) no-repeat center / cover;
  border-radius: 8px;
  -webkit-transition: background 2s;
  -moz-transition: background 2s;
  -o-transition: background 2s;
  transition: background 2s;
}

.land-right {
  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; */
  font-family: "Source Sans Pro", sans-serif;
  width: 50%;
  min-height: 400px;
  /* background-color: #fee440; */
  /* background: linear-gradient(30deg, yellow, red); */
  /* background: linear-gradient(135deg, #fdbb2d 0%, #22c1c3 100%); */
  background: linear-gradient(135deg, #93ca9c 0%, #76cff3 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border-radius: 8px;
  color: black;
  margin-left: -150px;
}

.land-right h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 25px;
}

.land-trans-para {
  margin: 20px 0;
  font-weight: 500;
  line-height: 25px;
  font-size: 1.1rem;
  animation: fadeIn linear 4.98s;
  -webkit-animation: fadeIn linear 4.98s;
  -moz-animation: fadeIn linear 4.98s;
  -o-animation: fadeIn linear 4.98s;
  -ms-animation: fadeIn linear 4.98s;
  animation-duration: 4.98s;
  animation-iteration-count: infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.land-right a {
  text-decoration: none;
  text-transform: uppercase;
  background-color: #fff;
  color: black;
  margin-top: 15px;
  padding: 15px 30px;
  display: inline-block;
  letter-spacing: 2px;
  border-radius: 5px;
  transition: 1s;
  font-weight: 700;
}
.land-right a:hover {
  background-color: black;
  color: white;
}

@media only screen and (max-width: 1300px) {
  .land-container {
    width: 80%;
  }
  .land-left {
    width: 50%;
  }
}
@media only screen and (max-width: 1000px) {
  .land-top {
    background: rgb(240, 239, 239);
  }
  .land-container {
    flex-direction: column;
    width: 100%;
    margin: 0 10px;
  }
  .land-left {
    width: 75%;
    height: 650px;
  }
  .land-right {
    width: 60%;
    margin: 0;
    margin-top: -170px;
    padding: 20px;
    min-height: 250px;
  }

  .land-right h1 {
    font-size: 2.5rem;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 0;
  }

  .land-trans-para {
    font-size: 1.1rem;
    line-height: 30px;
    margin: 10px 0;
  }

  .land-button-set {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .land-right a {
    padding: 15px 60px;
  }
}

@media only screen and (max-width: 760px) {
  /* .land-container{
        flex-direction: column;
        width: 100%;
        margin: 0 10px ;
    } */
  .land-left {
    width: 90%;
    height: 550px;
  }
  .land-right {
    width: 75%;
    margin: 0;
    margin-top: -150px;
    padding: 20px;
    min-height: 250px;
  }
  .land-trans-para {
    font-size: 1rem;
    line-height: 30px;
    margin: 10px 0;
  }
  .land-right h1 {
    font-size: 2.2rem;
  }
  /* .land-container{
        padding-bottom: 130px ;
        
    } */
}

.dimag-kharab {
  margin-left: 0;
  font-family: "PT Serif", serif;
  font-weight: 400;
}

@media only screen and (max-width: 560px) {
  /* .land-container{
        flex-direction: column;
        width: 100%;
        margin: 0 10px ;
    } */
  .land-left {
    width: 90%;
    height: 450px;
  }

  .land-right h1 {
    font-size: 2rem;
    /* margin-bottom: -5px; */
  }
  .land-right {
    font-size: 0.9rem;
    width: 80%;
  }
  .land-right p {
    line-height: 25px;
  }
}

@media only screen and (max-width: 420px) {
  .land-left {
    width: 90%;
    height: 350px;
  }
  .land-right {
    margin-top: -100px;
    width: 85%;
  }
  .land-right h1 {
    font-size: 2rem;
    /* margin-bottom: -10px; */
  }
  .land-right p {
    font-size: 12px;
    line-height: 20px;
  }
}
