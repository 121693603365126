@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Serif&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900);
@import url(https://fonts.googleapis.com/css?family=Robot:300,400,500,700,900&display=swap);
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css2?family=Oleo+Script+Swash+Caps&family=Parisienne&family=Sail&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Delius+Swash+Caps&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
@import url(https://fonts.googleapis.com/css?family=Varela+Round);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
@import url(https://fonts.googleapis.com/css?family=Varela+Round);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans:300,400,500,700,300italic,400italic,500italic,700italic);
@import url(https://fonts.googleapis.com/css?family=Varela+Round);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,700);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900);
@import url(https://fonts.googleapis.com/css?family=Lato:400,300,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,700);
@import url(https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&family=Merriweather:ital@1&family=Mochiy+Pop+P+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}
body {
  background-color: #161616 !important;
}

.navbar {
  background-color: #161616;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.logo {
  margin-left: 1%;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  margin-right: 1%;
}

.nav-menu {
  background-color: #1b1b1b;
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: left;
  position: fixed;
  top: 0;
  right: -25%;
  transition: 950ms;
  z-index: 1000;
}

.nav-menu.active {
  right: 0;
  transition: 450ms;
}

.nav-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 12%;
}

.nav-text a {
  text-decoration: none;
  color: #f2f2f2;
  font-size: 150%;
  font-weight: bold;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: left;
  justify-content: left;
  align-content: space-around;
  padding: 0 12px;
  border-radius: 9px;
  margin: auto;
}

.nav-text a:hover {
  background-color: #fec701;
  color: #161616;
  font-size: 28px;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #161616;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

span {
  margin-left: 25px;
}

@media only screen and (max-width: 500px) {
  .nav-menu {
    background-color: #1b1b1b;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: left;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 950ms;
    z-index: 1000;
  }

  .nav-text a {
    text-decoration: none;
    color: #f2f2f2;
    font-size: 180%;
    font-weight: bold;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    align-content: space-around;
    padding: 0 12px;
    border-radius: 9px;
    margin: auto;
  }

  .nav-text a:hover {
    background-color: #fec701;
    color: #161616;
    font-size: 25px;
  }

  .navbar-toggle {
    background-color: #161616;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
@media only screen and (max-width: 920px) and (min-width: 500px) {
  .nav-menu {
    background-color: #1b1b1b;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: left;
    position: fixed;
    top: 0;
    right: -50%;
    transition: 950ms;
    z-index: 1000;
  }

  .nav-text a {
    text-decoration: none;
    color: #f2f2f2;
    font-size: 200%;
    font-weight: bold;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: left;
    justify-content: left;
    align-content: space-around;
    padding: 0 12px;
    border-radius: 9px;
    margin: auto;
  }

  .nav-text a:hover {
    background-color: #fec701;
    color: #161616;
    font-size: 28px;
  }
}

.npath {
  text-decoration: none;
  font-size: 200%;
  font-weight: bold;
  margin-top: 22px;
  font-family: "Rubik Beastly", cursive;
  color: #fec701;
  -webkit-animation: blinker 2s step-start infinite;
          animation: blinker 2s step-start infinite;
}
@-webkit-keyframes blinker {
  50% {
    opacity: 0.8;
  }
}
@keyframes blinker {
  50% {
    opacity: 0.8;
  }
}

@media only screen and (max-width: 500px) {
  .npath {
    display: none;
  }
}

.cursor{
  cursor:pointer !important; 
}
.containers {
  overflow: hidden;
  padding: 4rem 0 0;
  font-size: 2rem;
  width: 100%;
  z-index: -1;
  color: black;
  /* font-family: "Roboto", sans-serif; */
  display: flex;
  flex-direction: column;
}

.box2,
.box3 {
  margin: 0rem 12rem;
}
.box2 {
  margin-top: 3rem;
  color: rgb(30, 50, 56);
  display: inline-block;
  position: relative;
}
.line {
  margin-left: 0;
  display: block;
  font-size: 3rem;
}
.text1,
.text12 {
  margin-left: 0;
  font-size: 4rem;
  margin-bottom: 1rem;
  position: relative;
  -webkit-animation: text 2.5s linear infinite;
          animation: text 2.5s linear infinite;
}
.text12 {
  color: rgb(250, 197, 0);
}
@-webkit-keyframes text {
  0% {
    margin-bottom: -3rem;
  }
  35% {
    letter-spacing: 0.2rem;
    margin-bottom: -3rem;
  }
  85% {
    letter-spacing: 0rem;
    margin-bottom: -3rem;
  }
}
@keyframes text {
  0% {
    margin-bottom: -3rem;
  }
  35% {
    letter-spacing: 0.2rem;
    margin-bottom: -3rem;
  }
  85% {
    letter-spacing: 0rem;
    margin-bottom: -3rem;
  }
}
.box3 {
  display: flex;
  justify-content: space-between;
}
.box3first {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}
.box3firstcontent {
  font-family: "PT Serif", serif;
  display: flex;
  align-items: center;
  font-size: 2rem;
}
.box3second {
  display: flex;
  align-items: center;
}
.box3second img {
  padding: 1.5rem;
  width: 30rem;
  height: 29rem;
  -webkit-animation: updown 3s linear infinite;
          animation: updown 3s linear infinite;
}
@-webkit-keyframes updown {
  0% {
    padding-top: 0rem;
  }
  33% {
    padding-top: 0.4rem;
  }
  66% {
    padding-top: 0.8rem;
  }
  100% {
    padding-top: 0rem;
  }
}
@keyframes updown {
  0% {
    padding-top: 0rem;
  }
  33% {
    padding-top: 0.4rem;
  }
  66% {
    padding-top: 0.8rem;
  }
  100% {
    padding-top: 0rem;
  }
}
.land-box-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 210vh;
  overflow: hidden;
}
.land-box-area li {
  position: absolute;
  display: block;
  list-style: none;
  width: 25px;
  height: 25px;
  background: rgba(250, 196, 0, 0.651);
  -webkit-animation: animate 20s linear infinite;
          animation: animate 20s linear infinite;
  bottom: -120px;
  z-index: 0;
}
.land-box-area li:nth-child(1) {
  left: 86%;
  width: 80px;
  height: 80px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.land-box-area li:nth-child(2) {
  left: 12%;
  width: 30px;
  height: 30px;
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
  -webkit-animation-duration: 8s;
          animation-duration: 8s;
}
.land-box-area li:nth-child(3) {
  left: 70%;
  width: 100px;
  height: 100px;
  -webkit-animation-delay: 5s;
          animation-delay: 5s;
}
.land-box-area li:nth-child(4) {
  left: 42%;
  width: 90px;
  height: 90px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 15s;
          animation-duration: 15s;
}
.land-box-area li:nth-child(5) {
  left: 65%;
  width: 40px;
  height: 40px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.land-box-area li:nth-child(6) {
  left: 15%;
  width: 110px;
  height: 110px;
  -webkit-animation-delay: 3.5s;
          animation-delay: 3.5s;
}
@-webkit-keyframes animate {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 0;
  }
  10% {
    -webkit-transform: translateY(0) rotate(20deg);
            transform: translateY(0) rotate(20deg);
    opacity: 0.2;
  }
  30% {
    -webkit-transform: translateY(0) rotate(80deg);
            transform: translateY(0) rotate(80deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-700px) rotate(360deg);
            transform: translateY(-700px) rotate(360deg);
    opacity: 0;
  }
}
@keyframes animate {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 0;
  }
  10% {
    -webkit-transform: translateY(0) rotate(20deg);
            transform: translateY(0) rotate(20deg);
    opacity: 0.2;
  }
  30% {
    -webkit-transform: translateY(0) rotate(80deg);
            transform: translateY(0) rotate(80deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-700px) rotate(360deg);
            transform: translateY(-700px) rotate(360deg);
    opacity: 0;
  }
}
.land-flex-box2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 50px 50px;
}
.land-counter {
  font-size: 3.8rem;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-weight: 900;
}
.land-outer-counter {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}
.counter-title {
  font-size: 2rem;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  font-family: "PT Serif", serif;
}
@media (max-width: 1398px) {
  .box2 {
    font-size: 140%;
  }
  .box3firstcontent {
    font-size: 90%;
  }
  .box3second img {
    width: 25rem;
    height: 24rem;
  }
  /* .land-counter {
    font-size: 120%;

  } */
  .counter-title {
    font-size: 100%;
  }
}
@media (max-width: 1241px) {
  .text1,
  .text12 {
    font-size: 120%;
  }
  .box2,
  .box3 {
    margin: 0rem 5rem;
  }
}
@media (max-width: 1005px) {
  .box2 {
    margin: 0rem 7rem;
  }
  .box2 {
    text-align: justify;
  }
  .text1,
  .text12,
  .line {
    font-size: 100%;
  }
  .box3second img {
    width: 20rem;
    height: 19rem;
  }
  .box3 {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 0;
  }
  .box3first {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .box3firstcontent {
    font-size: 80%;
    height: 25vh;
    text-align: justify;
    padding: 0rem;
    margin-top: 25px;
  }
  .land-box-area li:nth-child(4) {
    display: none;
  }
  .box3second img {
    width: 24rem;
    height: 23rem;
  }
  .land-counter {
    /* font-size: 100%; */
    font-size: 3rem;
    margin: 0px 25px;
  }
  .counter-title {
    font-size: 90%;
  }
  .land-flex-box2 {
    margin-top: 25px;
  }
}
@media (max-width: 837px) {
  .land-counter {
    /* font-size: 90%; */
    font-size: 2.7rem;
  }
  .counter-title {
    font-size: 80%;
  }
  .land-flex-box2 {
    margin-top: 25px;
  }
  .box3firstcontent {
    font-size: 70%;
  }
}
@media (max-width: 693px) {
  .land-counter-set {
    padding-left: 20px;
    /* background-color: brown; */
  }
  .box2,
  .box3 {
    margin: 0rem 3rem;
  }
  .text1,
  .text12 {
    font-size: 85%;
  }
  .line {
    font-size: 75%;
  }
  .box3firstcontent {
    /* height: 38vh; */
    /* font-size: 75%; */
    margin: 25px 0rem;
  }
  .box3second img {
    width: 20rem;
    height: 19rem;
  }
  .land-outer-counter {
    margin: 0 65px;
  }
  .land-flex-box2 {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: -20px;
  }
  .land-counter-set {
    padding-left: 12px;
    /* background-color: brown; */
  }
  .box3firstcontent {
    font-size: 60%;
  }
}
@media (max-width: 510px) {
  .box2 {
    text-align: center;
  }
  .line {
    font-size: 70%;
  }
  .box3firstcontent {
    /* height: 30vh; */
    /* font-size: 80%; */
    margin-top: 0;
  }
  .box3second img {
    width: 15rem;
    height: 15rem;
    padding-bottom: 0;
  }
  .land-counter {
    /* font-size: 80%; */
    font-size: 2.2rem;
  }
  .counter-title {
    font-size: 70%;
  }
  .land-outer-counter {
    margin-left: 50px;
  }
  .land-flex-box2 {
    /* margin-top: 60px; */
  }
  .box3firstcontent {
    font-size: 50%;
  }
}
@media (max-width: 462px) {
  .line {
    font-size: 65%;
  }
  .box2 {
    margin: 0 1rem;
  }
  /* .box3firstcontent {
    font-size: 70%;
  } */
  .land-outer-counter {
    margin: 0 40px;
  }
  .box3firstcontent {
    font-size: 50%;
    margin-top: 20px;
  }
}

@media (max-width: 400px) {
  /* .land-flex-box2 {
    margin-top: 40px;
  } */
  .land-outer-counter {
    margin: 0 35px;
  }
  .box3second {
    padding-bottom: 20px;
  }
  .box3firstcontent {
    margin-top: 0;
    margin-bottom: 30px;
  }
}
@media (max-width: 371px) {
  /* .land-flex-box2 {
    margin-top: 40px;
  } */
  .land-outer-counter {
    margin: 0 20px;
  }
  .box3firstcontent {
    margin-bottom: 30px;
  }
}

@media (max-width: 365px) {
  .text1,
  .text12 {
    font-size: 70%;
  }
  .line {
    font-size: 50%;
  }
  .box3 {
    margin: 0rem 1.5rem;
  }
  /* .box3firstcontent {
    height: 48vh;
  } */
  .land-flex-box2 {
    margin-top: 0;
  }
}
@media (max-width: 320px) {
  .box3second {
    padding-bottom: 30px;
  }
  .land-flex-box2 {
    margin-top: 20px;
  }
  .land-outer-counter {
    margin: 0 10px;
  }
}

.land-box {
  margin: 15px 35px;
  height: 8%;
  width: 15%;
  border-radius: 5px;
  /* padding-bottom: 30px; */
}

.kand-spon-heading {
  margin: 50px 0;
}

.land-flex-box {
  display: flex;
  justify-content: space-evenly;
  padding: 10px 50px 10px;
  align-items: center;
  flex-wrap: wrap;
}

.company-image {
  width: 100%;
  height: auto;
  padding: 10px 20px 5px;
}

.land-sponhead {
  text-align: center;
  font-size: 3rem;
  padding-top: 80px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: rgb(30, 50, 56);
}

@media only screen and (max-width: 800px) {
  .land-box {
    width: 20%;
    margin: 0 100px;
  }
  /* .company-image{
          margin: 0 20px;
      } */
  .land-flex-box {
    /* padding-bottom: 10px; */
    padding: 10px 0;
    /* justify-content: left; */
  }
}

@media only screen and (max-width: 700px) {
  .land-box {
    width: 20%;
    margin: 0 80px;
  }
}

@media only screen and (max-width: 600px) {
  .land-box {
    /* border: 4px solid yellow ; */
    width: 25%;
    margin: 0 60px;
  }
}
@media only screen and (max-width: 500px) {
  .land-box {
    /* border: 4px solid yellow ; */
    width: 25%;
    margin: 0 40px;
  }
  .land-sponhead {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 400px) {
  .land-box {
    /* border: 4px solid yellow ; */
    width: 25%;
    margin: 0 20px;
  }
  .land-sponhead {
    font-size: 2rem;
  }
}

/* @media only screen and (max-width : 350px) {
    .land-box{
        border: 4px solid yellow ;
        width: 45%;
        margin: 5px;
    }
    .land-sponhead{
        font-size: 2rem;
    }
} */
@media only screen and (max-width: 300px) {
  .land-box {
    /* border: 4px solid yellow ; */
    width: 25%;
    /* margin: 5px; */
  }
}

.land-headingcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 7px solid rgba(0, 0, 0, 0.623);
  /* margin: 35px 0 20px; */
  height: 100px;
  background: rgb(46, 34, 27);
  background: linear-gradient(
    70deg,
    rgba(46, 34, 27, 1) 0%,
    rgba(241, 222, 139, 1) 30%,
    rgba(241, 222, 139, 1) 70%,
    rgba(46, 34, 27, 1) 100%
  );
  margin-top: 30px;
}
.land-headingbox {
  text-align: center;
  width: 50%;
  font-family: "Telefon Black";
  font-size: 60px;
  font-weight: 900;
  word-spacing: 5px;
  letter-spacing: 2px;
  -webkit-text-decoration: double;
          text-decoration: double;
  margin: 0;
  margin-bottom: -5px;
  text-shadow: 2px 2px 8px #fdcc0c;
}

@media screen and (max-width: 1035px) {
  .land-headingcontainer {
    width: 100%;
    max-width: 1035px;
  }
}

@media screen and (max-width: 895px) {
  .land-headingbox {
    font-size: 2.5rem;
  }
  .land-headingcontainer {
    height: 80px;
  }
}

@media screen and (max-width: 650px) {
  .land-headingbox {
    font-size: 2.5rem;
  }
  .land-headingcontainer {
    height: 1.5%;
  }
}

@media screen and (max-width: 620px) {
  .land-headingbox {
    font-size: 2rem;
  }
  .land-headingcontainer {
    height: 70px;
  }
}

@media screen and (max-width: 512px) {
  .land-headingbox {
    font-size: 1.7rem;
  }
  .land-headingcontainer {
    height: 50px;
  }
}

@media screen and (max-width: 450px) {
  .land-headingbox {
    font-size: 1.5rem;
  }
  .land-headingcontainer {
    height: 50px;
  }
}

@media screen and (max-width: 402px) {
  .land-headingbox {
    font-size: 1.4rem;
  }
  .land-headingcontainer {
    height: 50px;
  }
}

@media screen and (max-width: 380px) {
  .land-headingbox {
    font-size: 1.3rem;
  }
  .land-headingcontainer {
    height: 50px;
  }
}

@media screen and (max-width: 360px) {
  .land-headingbox {
    font-size: 1.1rem;
  }
  .land-headingcontainer {
    height: 40px;
  }
}

@media screen and (max-width: 360px) {
  .land-headingbox {
    font-size: 0.9rem;
  }
  .land-headingcontainer {
    height: 40px;
  }
}

.home10 {
  background-color: #efefef;
  animation: fadeIn ease 4s;
  -webkit-animation: fadeIn ease 4s;
  -moz-animation: fadeIn ease 4s;
  -o-animation: fadeIn ease 4s;
  -ms-animation: fadeIn ease 4s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.land-overlay {
  /* min-height: 100vh; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.land-top {
  background: url(/static/media/curtain3.858deef6.png) center / cover;
}

.land-container {
  width: 90%;
  max-width: 1000px;
  margin: 50px auto 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.land-left {
  width: 60%;
  height: 600px;
  background: url(/static/media/e2.f3171e41.jpg) no-repeat center / cover;
  border-radius: 8px;
  transition: background 2s;
}

.land-right {
  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; */
  font-family: "Source Sans Pro", sans-serif;
  width: 50%;
  min-height: 400px;
  /* background-color: #fee440; */
  /* background: linear-gradient(30deg, yellow, red); */
  /* background: linear-gradient(135deg, #fdbb2d 0%, #22c1c3 100%); */
  background: linear-gradient(135deg, #93ca9c 0%, #76cff3 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border-radius: 8px;
  color: black;
  margin-left: -150px;
}

.land-right h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 25px;
}

.land-trans-para {
  margin: 20px 0;
  font-weight: 500;
  line-height: 25px;
  font-size: 1.1rem;
  animation: fadeIn linear 4.98s;
  -webkit-animation: fadeIn linear 4.98s;
  -moz-animation: fadeIn linear 4.98s;
  -o-animation: fadeIn linear 4.98s;
  -ms-animation: fadeIn linear 4.98s;
  -webkit-animation-duration: 4.98s;
          animation-duration: 4.98s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.land-right a {
  text-decoration: none;
  text-transform: uppercase;
  background-color: #fff;
  color: black;
  margin-top: 15px;
  padding: 15px 30px;
  display: inline-block;
  letter-spacing: 2px;
  border-radius: 5px;
  transition: 1s;
  font-weight: 700;
}
.land-right a:hover {
  background-color: black;
  color: white;
}

@media only screen and (max-width: 1300px) {
  .land-container {
    width: 80%;
  }
  .land-left {
    width: 50%;
  }
}
@media only screen and (max-width: 1000px) {
  .land-top {
    background: rgb(240, 239, 239);
  }
  .land-container {
    flex-direction: column;
    width: 100%;
    margin: 0 10px;
  }
  .land-left {
    width: 75%;
    height: 650px;
  }
  .land-right {
    width: 60%;
    margin: 0;
    margin-top: -170px;
    padding: 20px;
    min-height: 250px;
  }

  .land-right h1 {
    font-size: 2.5rem;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 0;
  }

  .land-trans-para {
    font-size: 1.1rem;
    line-height: 30px;
    margin: 10px 0;
  }

  .land-button-set {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .land-right a {
    padding: 15px 60px;
  }
}

@media only screen and (max-width: 760px) {
  /* .land-container{
        flex-direction: column;
        width: 100%;
        margin: 0 10px ;
    } */
  .land-left {
    width: 90%;
    height: 550px;
  }
  .land-right {
    width: 75%;
    margin: 0;
    margin-top: -150px;
    padding: 20px;
    min-height: 250px;
  }
  .land-trans-para {
    font-size: 1rem;
    line-height: 30px;
    margin: 10px 0;
  }
  .land-right h1 {
    font-size: 2.2rem;
  }
  /* .land-container{
        padding-bottom: 130px ;
        
    } */
}

.dimag-kharab {
  margin-left: 0;
  font-family: "PT Serif", serif;
  font-weight: 400;
}

@media only screen and (max-width: 560px) {
  /* .land-container{
        flex-direction: column;
        width: 100%;
        margin: 0 10px ;
    } */
  .land-left {
    width: 90%;
    height: 450px;
  }

  .land-right h1 {
    font-size: 2rem;
    /* margin-bottom: -5px; */
  }
  .land-right {
    font-size: 0.9rem;
    width: 80%;
  }
  .land-right p {
    line-height: 25px;
  }
}

@media only screen and (max-width: 420px) {
  .land-left {
    width: 90%;
    height: 350px;
  }
  .land-right {
    margin-top: -100px;
    width: 85%;
  }
  .land-right h1 {
    font-size: 2rem;
    /* margin-bottom: -10px; */
  }
  .land-right p {
    font-size: 12px;
    line-height: 20px;
  }
}

.Video {
  padding-top: 2rem;
  box-sizing: content-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}
.containerVideo {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 900px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}
.containerVideo > .boxVideo {
  position: relative;
  width: 450px;
  height: 500px;
  margin-left: 30px;
  margin-right: 30px;
  background: #000;
}
.containerVideo > .boxVideo > .imgBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.containerVideo > .boxVideo > .imgBox > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.containerVideo > .boxVideo > .contentBox {
  position: absolute;
  top: 120px;
  bottom: 120px;
  right: 40px;
  width: 100%;
  height: 60%;
  padding: 0px 0px;
  background: rgba(14, 14, 14, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform-origin: right;
          transform-origin: right;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}
.containerVideo > .boxVideo:hover > .contentBox {
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform-origin: left;
          transform-origin: left;
  border-radius: 0px 10px 10px 0px;
  box-shadow: 2px 2.5px 50px rgba(245, 245, 245, 0.322);
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  background: blur(2px);
}
.containerVideo > .boxVideo > .imgBox > img {
  transition: 0.5s;
  transition-delay: 0.2s;

  -webkit-filter: grayscale(0);

          filter: grayscale(0);
}
.containerVideo > .boxVideo:hover > .imgBox > img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  border-radius: 10px;
  box-shadow: 10px 5px 50px black;
}

.containerVideo > .boxVideo > .contentBox > .content {
  font-size: 1.2em;
  position: relative;
  padding: 20px 60px;
  opacity: 0;
}
.containerVideo > .boxVideo:hover > .contentBox > .content {
  opacity: 1;
  transition: 0.5s;
  transition-delay: 0.5s;
}
.containerVideo > .boxVideo > .contentBox > .content > h2 {
  color: #fff;
  font-weight: 500;
  margin-bottom: 10px;
  font-family: "PT Serif", serif;
  font-weight: 600;
}
.containerVideo > .boxVideo > .contentBox > .content > p {
  color: #fff;
  font-weight: 300;
  font-family: "PT Serif", serif;
}

@media only screen and (max-width: 1000px) {
  .Video {
    padding-top: 0;
  }
  .containerVideo {
    flex-direction: column;
    justify-content: space-between;
  }
  .boxVideo {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .containerVideo > .boxVideo {
    position: relative;
    width: 450px;
    height: 540px;
    background: #000;
  }

  .containerVideo > .boxVideo > .imgBox {
    width: 100%;
    height: 100%;
  }
  .containerVideo > .boxVideo > .contentBox > .content {
    font-size: 1.8em;
  }
  .containerVideo > .boxVideo > .contentBox {
    font-size: 10px;
    position: absolute;
    top: 100px;
    bottom: 120px;
    right: 90px;
    width: 105%;
    height: 60%;
  }
}
@media only screen and (max-width: 680px) {
  .containerVideo > .boxVideo {
    position: relative;
    width: 300px;
    height: 380px;
    background: #000;
  }
  .containerVideo > .boxVideo > .imgBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .containerVideo > .boxVideo > .imgBox > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .containerVideo > .boxVideo > .contentBox {
    font-size: 100px;
    position: absolute;
    top: 70px;
    bottom: 120px;
    right: 60px;
    width: 105%;
    height: 60%;
  }
  .containerVideo > .boxVideo > .contentBox > .content {
    position: relative;
    padding: 10px 30px;
    opacity: 0;
  }
  .containerVideo > .boxVideo > .contentBox > .content {
    font-size: 10px;
  }
}

@media only screen and (max-width: 450px) {
  .Video {
    /* padding-left: 60px;
    padding-right: 60px; */
    width: 100%;
    font-size: 10px;
  }
  .containerVideo > .boxVideo > .contentBox {
    font-size: 8px;
    position: absolute;
    top: 60px;
    bottom: 120px;
    right: 30px;
    width: 105%;
    height: 60%;
    /* margin-left: 50px; */
  }

  .containerVideo > .boxVideo {
    position: relative;
    width: 250px;
    height: 320px;
    background: #000;
  }
  .containerVideo > .boxVideo > .imgBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.slide h1 {
  transition: all 0.3s ease;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0;
}

.slide button {
  transition: all 0.2s ease;
  -webkit-transform: translateY(120px);
  transform: translateY(20px);
  opacity: 0;
}

.slide p {
  transition: all 0.2s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}


.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition-delay: 0.7s;
  opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
  transition-delay: 0.9s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
  transition-delay: 1.1s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
  transition-delay: 1.1s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
  transition-delay: 1.2s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
  transition-delay: 1.3s;
}

.slide.animateOut h1 {
  transition-delay: 0.1s;
}

.slide.animateOut p {
  transition-delay: 0.1s;
}

:root {
  --variable-width: 100vw;
  --variable-height: 100vh;
  --temp: var(--variable-height /);
}

.land-slider-wrapper {
  position: relative;
  top: 65px;
  height: 92vh;
  overflow: hidden;
}

.slide {
  /* height: 100vh; */
  /* background-size: cover !important; */
}

.slider-content img {
  width: 100%;
  height: 92vh;
}

.slide::before {
  content: "";
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
  left: 2px;
}

.nextButton {
  right: 0;
}

.nextButton:hover {
  right: 2px;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 64px;
  line-height: 1;
}

.slider-content .inner p {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}
/* slider media queries  */

@media (max-width: 1100px) {
  .land-slider-wrapper {
    height: 90vh;
  }
  .slider-content img {
    height: 90vh;
  }
}

@media (max-width: 1000px) {
  .land-slider-wrapper {
    height: 550px;
  }
  .slider-content img {
    height: 550px;
  }
}
@media (max-width: 950px) {
  .land-slider-wrapper {
    height: 500px;
  }
  .slider-content img {
    height: 500px;
  }
}

@media (max-width: 900px) {
  .land-slider-wrapper {
    height: 450px;
  }
  .slider-content img {
    height: 450px;
  }
}
@media (max-width: 850px) {
  .land-slider-wrapper {
    height: 425px;
  }
  .slider-content img {
    height: 425px;
  }
}
@media (max-width: 800px) {
  .land-slider-wrapper {
    height: 400px;
  }
  .slider-content img {
    height: 400px;
  }
}
@media (max-width: 750px) {
  .land-slider-wrapper {
    height: 380px;
  }
  .slider-content img {
    height: 380px;
  }
}
@media (max-width: 700px) {
  .land-slider-wrapper {
    height: 365px;
  }
  .slider-content img {
    height: 365px;
  }
}
@media (max-width: 650px) {
  .land-slider-wrapper {
    height: 350px;
  }
  .slider-content img {
    height: 350px;
  }
}
@media (max-width: 600px) {
  .land-slider-wrapper {
    height: 330px;
  }
  .slider-content img {
    height: 330px;
  }
}
@media (max-width: 550px) {
  .land-slider-wrapper {
    height: 310px;
  }
  .slider-content img {
    height: 310px;
  }
}
@media (max-width: 500px) {
  .land-slider-wrapper {
    height: 280px;
  }
  .slider-content img {
    height: 280px;
  }
}
@media (max-width: 475px) {
  .land-slider-wrapper {
    height: 265px;
  }
  .slider-content img {
    height: 265px;
  }
}
@media (max-width: 450px) {
  .land-slider-wrapper {
    height: 250px;
  }
  .slider-content img {
    height: 250px;
  }
}
@media (max-width: 425px) {
  .land-slider-wrapper {
    height: 235px;
  }
  .slider-content img {
    height: 235px;
  }
}
@media (max-width: 400px) {
  .land-slider-wrapper {
    height: 210px;
  }
  .slider-content img {
    height: 210px;
  }
}

@media (max-width: 375px) {
  .land-slider-wrapper {
    height: 195px;
  }
  .slider-content img {
    height: 195px;
  }
}
@media (max-width: 350px) {
  .land-slider-wrapper {
    height: 180px;
  }
  .slider-content img {
    height: 180px;
  }
}
@media (max-width: 325px) {
  .land-slider-wrapper {
    height: 165px;
  }
  .slider-content img {
    height: 165px;
  }
}
@media (max-width: 300px) {
  .land-slider-wrapper {
    height: 150px;
  }
  .slider-content img {
    height: 150px;
  }
}

.about-full {
  background-color: #efefef;
  animation: fadeIn ease 4s;
  -webkit-animation: fadeIn ease 4s;
  -moz-animation: fadeIn ease 4s;
  -o-animation: fadeIn ease 4s;
  -ms-animation: fadeIn ease 4s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
/* about css */

#section_logo {
  display: grid;
  grid-template-columns: 45% 50%;
  margin: 0px auto;
  width: "100%";
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 80px;
  margin-bottom: 120px;
}

.section_logo {
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
  /* border: 3px solid #e21212; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.section_logo:hover {
  transition: 1s;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.section_logo::after {
  transition: 0.4s;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.section_logo img {
  width: 450px;
  height: 430px;
  /* border-radius: 50%; */
  /* margin-top: -20px; */
  border-radius: 50%;
  /* background-color: rgb(255, 255, 255); */
}

.section_logo img:hover {
  box-shadow: 0 25px 30px rgba(0, 0, 0, 0.5);
  /* box-shadow: 0 25px 130px rgba(228, 198, 62, 0.603); */
  /* width: 460px; */
  /* border-radius: 50%; */
  /* height: 440px; */
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all ease-in-out 0.8s;
}

@media (max-width: 900px) {
  #section_logo {
    grid-template-columns: 100%;
    margin: 0 auto;
    width: "90%";
  }
  .section_logo img {
    width: 300px;
    height: 300px;
    border-radius: 1000px;
    /* background-color: rgb(255, 255, 255); */
  }
}

.about-box {
  -webkit-transform: translate(0%, -0%);
          transform: translate(0%, -0%);
  width: 100%; /* accordingly change this */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; /* accordingly change this */
  /* background: #262626; */
  box-sizing: border-box;
  padding: 15px;
  transition: 0.5s;
  overflow: hidden;
  /* background:linear-gradient( #fac117 , #f8e856 ); */
  background: linear-gradient(#ffe608, #ff9900);
  border-radius: 10px;
}

.about-box p {
  margin: 10px;
  padding: 20px;
  /* color: #fff; */
  font-size: 1.5rem;
  transition: 0.5s;
  transition-delay: 0.5s;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

.about-box:before {
  content: "";
  position: absolute;
  width: 0%;
  height: 0%;
  top: 0;
  left: 0;
  border-top: 15px solid #fff;
  /* accordingly change this 8*/
  border-left: 15px solid #fff;
  /* accordingly change this 8*/
  opacity: 0;
  transition: 2.3s;
  box-sizing: border-box;

  -webkit-transform: skew(5deg, 4deg);

          transform: skew(5deg, 4deg);
}

.about-box:after {
  content: "";
  position: absolute;
  width: 0%;
  height: 0%;
  bottom: 0;
  right: 0;
  border-bottom: 15px solid #fff;
  /*accordingly change this*/
  border-right: 15px solid #fff;
  /* accordingly change this*/
  opacity: 0;
  transition: 2.9s;
  box-sizing: border-box;

  -webkit-transform: skew(5deg, 4deg);

          transform: skew(5deg, 4deg);
}

.about-box:hover:before {
  width: 100%;
  height: 100%;
  opacity: 1;
}

.about-box:hover:after {
  width: 100%;
  height: 100%;
  opacity: 1;
}

.about-box:hover p {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.about-box:hover {
  box-shadow: 0 25px 30px rgba(0, 0, 0, 0.5);
  /* background:linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%); */
  /* background:linear-gradient( #fac117 , #f8e856 );
    border-radius: 10px; */
}

.about-box span {
  position: absolute;
  top: 0;
  left: 120%;
  width: 100%;
  height: 100%;
  /* background: rgba(255, 255, 255, 0.1); */
  background: rgba(150, 146, 146, 0.089);
  transition: 0.5s;
  transition-delay: 1s;
  -webkit-transform: skewX(5deg);
          transform: skewX(5deg);
}

.about-box:hover span {
  left: -120%;
}

/*------------ honey comb css----------------- */

h1 {
  font-size: 60px;
}

.comb_0 {
  display: flex;
  /* background-color: #ffeb3b; */
  justify-content: center;
  grid-template-columns: 195px 195px 195px 195px;
  margin: -2.2vw 0px 0px 0vw;
}

.comb_1 {
  display: flex;
  justify-content: center;
  /* background-color: #fdd835; */
  grid-template-columns: 195px 195px 195px 195px;
  margin: -2.2vw 0px 0px 0vw;
}

.comb_2 {
  display: flex;
  justify-content: center;
  /* background-color: #fbc02d; */
  grid-template-columns: 195px 195px 195px 195px;
  margin: -2.2vw 0px 0px 0vw;
}

.comb_3 {
  display: flex;
  justify-content: center;
  /* background-color: #f9a825; */
  grid-template-columns: 15px 15px 95px 15px;
  margin: -2.2vw 0px 0px 0vw;
}

.comb_4 {
  display: flex;
  justify-content: center;
  /* background-color: #f57f17; */
  grid-template-columns: 195px 190px 138px 55px;
  margin: -2.2vw 1vw 0 -29vw;
  /*upper ki height   left side  #  right side */
}

.comb_5 {
  display: flex;
  justify-content: center;
  /* background-color: #f57f17; */
  grid-template-columns: 195px 10px 18px 5px;
  margin: -10vw 1vw 0 31vw;
}

/* ------------------------------------------------- */

.comb_0 .item {
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: -150px 0px; */
}

.comb_1 .item {
  background-size: cover;
  background-repeat: no-repeat;
}

.comb_2 .item:nth-child(1) {
  background-size: cover;
  background-repeat: no-repeat;
}

.comb_2 .item:nth-child(2) {
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position:0 -60px; */
}

.comb_2 .item:nth-child(3) {
  background-size: cover;
  background-repeat: no-repeat;
}

.comb_2 .item:nth-child(4) {
  background-size: cover;
  background-repeat: no-repeat;
}

.comb_2 .item:nth-child(5) {
  background-size: cover;
}

.comb_3 .item:nth-child(1) {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: -50px 0px;
}

.comb_3 .item:nth-child(2) {
  /* background-image: url('./1.jpg'); */
  background-size: cover;
}

.comb_3 .item:nth-child(3) {
  /* background-image: url('./1.jpg'); */
  background-size: cover;
  background-position: -50px 0px;
}

.comb_3 .item:nth-child(4) {
  /* background-image: url('./1.jpg'); */
  background-size: cover;
}

.comb_3 .item:nth-child(5) {
  /* background-image: url('./1.jpg'); */
  background-size: cover;
}

.comb_3 .item:nth-child(6) {
  /* background-image: url('./1.jpg'); */
  background-size: cover;
}

.comb_4 .item:nth-child(1) {
  /* background-image: url('./1.jpg'); */
  background-size: 200px 225px;
  background-repeat: no-repeat;
  background-position: 0 -30px;
}

.comb_4 .item:nth-child(2) {
  /* background-image: url('./1.jpg'); */
  background-size: cover;
}

.comb_5 .item:nth-child(1) {
  /* background-image: url('./1.jpg'); */
  background-size: cover;
}

.comb_5 .item:nth-child(2) {
  /* background-image: url('./1.jpg'); */
  background-size: cover;
}

.honeycomb-cell_title {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
      hyphens: auto;
  word-break: break-word;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  font-size: 1em;
  transition: opacity 350ms;
}

.item:hover .honeycomb-cell_title {
  opacity: 0;
}

.honeycomb-cell:hover .honeycomb-cell_title {
  opacity: 0;
}

.honeycomb-cell:hover::after {
  opacity: 0;
}

.honeycomb_hidden {
  display: none;
  opacity: 0;
  width: 250px;
  margin: 0 12.5px;
}
.honey {
  /* background: linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%); */
  /* background:linear-gradient( #fac117 , #f8e856 ); */
  /* background:linear-gradient( #ffe608,#ff9900  ); */
  background-image: url(/static/media/temp9.543ace71.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0px auto;
}

.item {
  /* */
  width: 9vw;
  height: 9vw;
  background-size: cover;
  background-color: #fff;
  -webkit-clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
          clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  margin: 0.5vw;
  cursor: pointer;
}

.item:hover {
  transition: 0.5s;
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}
.item:before {
  content: " ";
  position: absolute;
  width: 9vw;
  height: 9vw;
  background-color: rgba(0, 0, 0, 0.493);
}

.item:hover:before {
  background-color: rgb(24, 29, 35, 0);
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.honeyDiv {
  display: block;
  margin-left: 11.6vw;
  position: absolute;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 5px;
  top: 1;
  z-index: 900;
  border-radius: 15px;
  -webkit-transform: scale(1);
          transform: scale(1);
  /* background: linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%); */
  background-color: #161616;
  color: #fed76b;
  box-shadow: 0 30px 40px rgba(0, 0, 0, 0.3);
  min-width: 150px;
  /* background: linear-gradient(#f3bb15, #ece380); */
}

.honeyDiv h1 {
  padding-top: 9px;
  font-size: 26px;
  margin-bottom: -10px;
  margin-left: 4px;
  margin-right: 4px;
  margin-top: -5px;
  font-weight: 800;
  color: #fed76b;
  text-align: center;
}

.honeyDiv p {
  padding-top: 6px;
  margin-top: -10px;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 17px;
  text-align: center;
  /* font-weight: 400; */
  font-family: "PT Serif", serif;
}

@media (max-width: 650px) {
  .honeyDiv {
    display: none;
  }
}

.notHoneyDiv {
  display: none;
}

/*--------------- department css-------------------------- */

#department {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-bottom: 40px;
}

@media (max-width: 700px) {
  #department {
    grid-template-columns: auto;
  }
}
@media (min-width: 700px) {
  .dept {
    margin: 0 23%;
  }
}
.card {
  background: #fff;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: perspective(1000px);
          transform: perspective(1000px);
  box-shadow: 10px 20px 40px rgba(251, 201, 15, 0.5);
  transition: 10s;
  margin-top: 50px;
  margin-left: 7%;
  margin-right: 7%;
  background: linear-gradient(#fcbf09, #faf9ef);
  border-radius: 15px;
}

.card .imgBox {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  background-color: white;
  transition: 7s;
  /* box-shadow: 10px 20px 40px rgba(226, 67, 67, 0.5); */

  /* background: linear-gradient(#fcbf09, #faf9ef); */
  background: linear-gradient(120deg, #ffc60a, yellow, #ad8605);
  border-color: solid;
  border-radius: 15px;
  border-width: 0 100px 100px 0;
  /* background:linear-gradient( #ff7300 , #ffe608 ); */
}
.card .imgBox:hover {
  box-shadow: 10px 20px 40px rgba(251, 201, 15, 0.5);
}
.card .imgBox:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  width: 3rem;
  height: 5rem;
  -webkit-transform: translate(-1rem, -1.5rem) rotate(-32.7deg);
          transform: translate(-1rem, -1.5rem) rotate(-32.7deg);
  background: linear-gradient(
    to left bottom,
    transparent 50%,
    #7da9b3 50px,
    #d2f1fb 60px,
    #025364 50px
  );
  /* box-shadow: 0 6px 4px -4px rgba(251, 201, 15, 0.5); */
}

.card .imgBox p {
  font-size: 18px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  padding: 0 40px 0 40px;
  text-align: justify;
  font-weight: 400;
}

@media (max-width: 1300px) {
  .card .imgBox p {
    padding: 10px;
    font-size: 16.5px;
  }
}
@media (max-width: 990px) {
  .card .imgBox p {
    padding: 10px;
    font-size: 15px;
  }
}
@media (max-width: 790px) {
  .card .imgBox p {
    padding: 10px;
    font-size: 20px;
  }
}
@media (max-width: 540px) {
  .card .imgBox p {
    padding: 10px;
    font-size: 15px;
  }
}
@media (max-width: 370px) {
  .card .imgBox p {
    padding: 10px;
    font-size: 13px;
  }
}
h1 {
  font-size: 70px;
  font-family: "Courier New", Courier, monospace;
}

.card:hover .imgBox {
  -webkit-transform: rotateY(-350deg);
          transform: rotateY(-350deg);
  transition-duration: 2s;
}

.card .imgBox div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.card .imgBox div img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.card .details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#faf9ef, #87cefa);
  color: rgb(32, 32, 32);
  border-radius: 15px;
}

.card .details .content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  flex-direction: column;
}

.card .details .content h2 {
  text-align: center;
  font-weight: 700;
  letter-spacing: 1px;
  word-spacing: 3px;
  margin-top: -50px;
  font-size: 32px;
  color: #913831;
}
.card .details .content h3 {
  font-weight: 500;
  margin-right: 30px;
  align-self: flex-end;
}
#social p {
  font-size: 18px;
}

#social {
  padding: 20px;
}
.card .details .content h2 span {
  font-size: 0.8em;
  color: rgb(32, 32, 32);
  background-color: yellow;
}

.card .details .content .social-icons {
  position: relative;
  display: flex;
}

.card .details .content .social-icons a {
  display: inline-block;
  margin-top: 10px;
  width: 35px;
  height: 35px;
  background: #333;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin: 5px;
  font-size: 18px;
  transition: 0.2s;
}

.card .details .content .social-icons a:hover {
  background: #e21212;
}
#social {
  margin: 20px 10px;
  text-align: center;
}

.headingEffect::before {
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
}
.headingEffect:hover::before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.headingEffect::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  /* background: rgba(251, 201, 15, 0.5); */
  background-color: #fec90f;
  z-index: -1;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.headingEffect {
  position: relative;
  font-size: 2.4rem !important;
}

.drop {
  position: relative;
  width: 450px;
  height: 450px;
  /* background-color: rgb(231, 231, 231); */
  /* opacity: .6; */
  box-shadow: inset 10px 10px 10px rgba(0, 0, 0, 0.05),
    15px 25px 10px rgba(0, 0, 0, 0.05), 15px 25px 10px rgba(0, 0, 0, 0.05),
    inset -10px -10px 15px rgba(225, 225, 225, 0.9);
  border-radius: 50%;
  /* background-color: red; */
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.drop::before {
  content: "";
  position: absolute;
  top: 105px;
  left: 55px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #fff;
}

.drop::after {
  content: "";
  position: absolute;
  top: 75px;
  left: 80px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
}
.drop img {
  width: 100%;
}

@media (max-width: 1090px) {
  .drop {
    width: 400px;
    height: 400px;
  }
}
@media (max-width: 960px) {
  .drop {
    width: 350px;
    height: 350px;
  }
}
@media (max-width: 900px) {
  .drop {
    width: 350px;
    height: 350px;
    margin-bottom: 40px;
  }
}

@media (max-width: 400px) {
  .drop {
    width: 80%;
    height: auto;
  }
}

@media (max-width: 330px) {
  .drop {
    width: 80%;
  }
}

/* 
#department {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 40px;
}

@media (max-width:800px) {
    #department {
        grid-template-columns: auto;
    }
}

.card {
    background: #fff;
    transform-style: preserve-3d;
    transform: perspective(1000px);
    box-shadow: 10px 20px 40px rgba(2, 2, 2, 0.5);
    transition: 10s;
    margin-top: 50px;
    margin-left: 7%;
    margin-right: 7%;


    background: linear-gradient(#fcbf09, #faf9ef);
}

.card .imgBox {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    transform-origin: left;
    transform-style: preserve-3d;
    background-color: white;
    transition: 1s;
    
    box-shadow: 10px 20px 40px rgba(2, 2, 2, 0.5);

    
    background:linear-gradient( #f87509 , #ffe608 );
}
.card .imgBox p{
    padding: 20px;
    font-size: 20px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  
}


@media (max-width:1300px){
    .card .imgBox p{
        padding: 10PX;
    font-size: 16.5px;
    }
}
@media (max-width:990px){
    .card .imgBox p{
        padding: 10PX;
    font-size: 15px;
    }
}
@media (max-width:790px){
    .card .imgBox p{
        padding: 10PX;
    font-size: 20px;
    }
}
@media (max-width:540px){
    .card .imgBox p{
        padding: 10PX;
    font-size: 15px;
    }
}
@media (max-width:370px){
    .card .imgBox p{
        padding: 10PX;
    font-size: 13px;
    }
}




.card:hover .imgBox {
    
    transform: translateY(-930px);
    transition-duration: 1s;
}

.card .imgBox div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform-style: preserve-3d;
    backface-visibility: hidden;
}

.card .imgBox div img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    transform: rotateY(180deg);
}

.card .details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card .details .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}

.card .details .content h2 {
    text-align: center;
    font-weight: 700;
    font-size: 50px;
    line-height: 1em;
    
}
.card .details .social p{
    padding: 20PX;
    font-size: 60px;

}
.card .details .content h2 span {
    font-size: 0.8em;
    color: #e21212;
}

.card .details .content .social-icons {
    position: relative;
    display: flex;
}

.card .details .content .social-icons a {
    display: inline-block;
    margin-top: 10px;
    width: 35px;
    height: 35px;
    background: #333;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 5px;
    font-size: 18px;
    transition: 0.2s;
}

.card .details .content .social-icons a:hover {
    background: #e21212;
}

@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css');
#social {
    margin: 20px 10px;
    text-align: center;
} */

.body {
  margin: 0;
  padding: 0;
}
.tcontainer {
  /* margin: 50 0px; */
  margin-top: 50px;
  /* background: rgb(240, 240, 238); */
  padding: 0 153px;
  /* height: 60vh; */
  /* border: 2px solid black; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.teacher-pic {
  border-radius: 50%;
}

.tbox2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  text-align: left;
  /* border: 2px solid red; */
}
.tbox2 p {
  font-family: "PT Serif", serif;
}
.tbox1 {
  margin: 50px 0;
  /* border: 2px solid red; */
  box-shadow: 0 0 5px #bababa;
  overflow: hidden;
  position: relative;
  /* height: 450px; */
  width: 400px;
  border-radius: 50%;
}
.tbox1 img {
  width: 100%;
  margin: auto;
  display: block;
}
.tbox1 .tbox1-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.tbox1 .tbox1-content:before,
.tbox1 .tbox1-content:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #ffebcc;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  transition: all 0.5s;
  opacity: 0;
}
.tbox1 .tbox1-content:before {
  top: -20%;
}
.tbox1 .tbox1-content:after {
  bottom: -20%;
}
.tbox1:hover .tbox1-content:before {
  top: 3%;
  opacity: 0.5;
}
.tbox1:hover .tbox1-content:after {
  bottom: 3%;
  opacity: 0.5;
}
.tbox1-content-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  /* height: 45px; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* margin: auto; */
  z-index: 1;
  opacity: 0;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: all 0.5s;
}
.tbox1:hover .tbox1-content-cont {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  opacity: 1;
}
.tsocial {
  padding: 0;
  /* margin: 0 0 20px 0; */
  list-style: none;
  float: right;
  padding-left: 20px;
  padding-bottom: 5px;
}
.tbox1 .tsocial li {
  display: inline-block;
  margin-right: 10px;
}
.tbox1 .tsocial li a {
  color: #000;
  background-color: #fff;
  display: block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  font-size: 18px;
}
.tbox1 .tsocial li a:hover {
  color: blue;
}
.tbox1 .tsocial li i:hover {
  background-color: none;
}
.ttitle {
  /* color: #fff; */
  font-size: 35px;
  margin-bottom: 7px;
  font-family: "PT Serif", serif;
  font-weight: 600;
}
.tpost {
  margin-right: 18px;
  /* color: #fff; */
  font-size: 25px;
  font-family: "PT Serif", serif;
}
.tbox2 p {
  font-size: 20px;
}

.c-twitter,
.c-fb,
.c-insta,
.c-link,
.c-pin {
  padding-top: 5px;
  color: #3c4043;
}
.c-twitter:hover {
  color: #1da1f2;
}
.c-fb:hover {
  color: #4267b2;
}

@media (max-width: 1410px) {
  .tcontainer {
    padding: 0 100px;
  }
  .tbox1 {
    width: 450px;
  }
  .tbox2 {
    width: 500px;
  }
}
@media (max-width: 1151px) {
  .tcontainer {
    padding: 0 50px;
  }
  .tbox1 {
    width: 400px;
  }
  .tbox2 {
    width: 450px;
  }
}
@media (max-width: 955px) {
  .tcontainer {
    margin: 0px;
  }
  .tbox2 {
    width: 80%;
  }
  .tbox2 p {
    text-align: justify;
  }
}
@media (max-width: 600px) {
  .tbox1 {
    width: 80%;
  }
  .tbox2 {
    width: 100%;
  }
  .tbox2 p {
    font-size: 16px;
  }
  .ttitle {
    font-size: 25px;
    text-align: center;
  }
  .tpost {
    font-size: 20px;
    text-align: center;
  }
}

@media (max-width: 430px) {
  .ttitle {
    font-size: 20px;
  }
}

@media (max-width: 350px) {
  .tbox1 {
    width: 90%;
  }
  .ttitle {
    font-size: 20px;
  }
  .tpost {
    font-size: 15px;
  }
}

/* qoute */

.ab-container {
  padding-top: 4rem;
  margin-bottom: 2rem;
}
.ab-image {
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ab-image img {
  width: 50%;
  height: auto;
}
.ab-heading {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding: 1rem 0;
  text-align: center;
  font-weight: 600;
  font-size: 4rem;
}
.ab-quote {
  padding: 1.8rem 180px 1.2rem;
  font-size: 45px;
  color: #484848;
  font-family: "Dancing Script", cursive;
  letter-spacing: 2px;
  text-align: right;
  font-weight: 700;
}
.ab-box {
  display: flex;
  padding: 0 7rem;
}
.ab-box1 {
  text-align: justify;
  font-size: 1.2rem;
  padding: 1rem 2rem;
  font-family: "PT Serif", serif;
}

@media (max-width: 1235px) {
  .ab-heading {
    font-size: 3.8rem;
  }
  .ab-quote {
    padding: 2rem 150px 1.2rem;
    font-size: 35px;
  }
}
@media (max-width: 1000px) {
  .ab-heading {
    font-size: 3.5rem;
  }
  .ab-quote {
    padding: 2rem 110px 1.2rem;
    font-size: 27px;
  }
  .ab-box {
    padding: 0 5rem;
    flex-direction: column;
  }
}
@media (max-width: 800px) {
  .ab-box {
    padding: 0 3rem;
  }
  .ab-quote {
    padding: 2rem 80px 1.2rem;
    font-size: 25px;
  }
}
@media (max-width: 750px) {
  .ab-image img {
    width: 100%;
  }
}
@media (max-width: 645px) {
  .ab-heading {
    font-size: 3rem;
  }
  .ab-quote {
    padding: 2rem 60px 1.2rem 20px;
    font-size: 25px;
  }
  .ab-box {
    padding: 0 1.5rem;
  }
  .ab-box1 {
    font-size: 1rem;
  }
}
@media (max-width: 505px) {
  .ab-heading {
    font-size: 2rem;
  }
  .ab-quote {
    padding: 2rem 40px 1.2rem 10px;
    font-size: 25px;
  }
  .ab-box {
    padding: 0 1rem;
  }
}
@media (max-width: 420px) {
  .ab-quote {
    /* padding: 1.8rem 50px 1.2rem; */
    font-size: 20px;
  }
}
@media (max-width: 380px) {
  .ab-quote {
    padding: 2rem 2rem 1.2rem;
    font-size: 1.2rem;
  }
  .ab-box {
    padding: 0rem;
  }
  .ab-box1 {
    font-size: 1rem;
  }
}
@media (max-width: 330px) {
}

.main1 {
  padding: 1px 0 1px 0;
}

.member {
  list-style: none;
  margin-bottom: 0;
}

/* style member component */
.list-members {
  background: #fffdde;
  width: 90%;
  margin: 4% auto;
  display: flex;
  flex-wrap: wrap;
}

.member {
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.member-image {
  width: 50%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.member-image img {
  width: 100%;
  height: 100%;
  transition: 1s;
}

.member-image:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.member-info {
  width: 50%;
  text-align: center;
  font-family: "PT Serif", serif;
}

.member-info h6 {
  margin: 20px 0;
  padding: 0 10px;
  font-weight: lighter;
  font-family: "PT Serif", serif;
}

.before-info {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  font-family: "PT Serif", serif;
}

.before-info h3 {
  font-size: 1.5rem;
  padding-top: 10px;
  transition: all 0.5s;
  text-align: center;
  margin-bottom: -10px;
  font-family: "PT Serif", serif;
  font-weight: 600;
}

.before-info h6 {
  font-size: 1rem;
  transition: all 0.5s;
  font-family: "PT Serif", serif;
}

.about-showit {
  display: none;
  margin-left: 0;
  font-family: "PT Serif", serif;
}

.about-hideit {
  margin-left: 0;
  font-family: "PT Serif", serif;
}
.about-firstletter {
  margin-left: 0;
  font-family: "PT Serif", serif;
}
.after-info {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  transition: all 0.1s;
  opacity: 0;
  font-family: "PT Serif", serif;
}

.about-techhead {
  margin-bottom: 50px;
  font-family: "PT Serif", serif;
}

.after-info p {
  padding: 0 10px;
  font-size: 1rem;
  margin-top: -40px;
  text-align: center;
  margin-left: 2px;
  font-family: "PT Serif", serif;
}

.after-info h4 {
  padding: 0 10px 15px;
  margin-top: 15px;
  font-size: 1.4rem;
  text-align: center;
  font-family: "PT Serif", serif;
  font-weight: 600;
}

.before-info:hover h3 {
  -webkit-transform: rotateX(720deg) scale(0);
          transform: rotateX(720deg) scale(0);
  opacity: 0;
  font-family: "PT Serif", serif;
  font-weight: 600;
}

.before-info:hover h6 {
  -webkit-transform: rotateX(720deg) scale(0);
          transform: rotateX(720deg) scale(0);
  opacity: 0;
  font-family: "PT Serif", serif;
}

.before-info:hover .after-info {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition-delay: 0.6s;
  opacity: 1;
  font-family: "PT Serif", serif;
}

/* style social link */
.social-link .fab {
  width: 35px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #000;
  margin: 0 7px;
  cursor: pointer;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.social-link .fab:hover {
  background: #000;
  color: #ffe501;
  -webkit-transform: translateY(-7px);
          transform: translateY(-7px);
}

/* Membuat segitiga */
.member-image::after {
  content: "";
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 15px solid #fffdde;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

/* Merubah posisi member-image dengan member-info */
@media screen and (min-width: 950px) {
  .member:nth-child(4n + 3) .member-info,
  .member:nth-child(4n + 4) .member-info {
    order: 1;
  }
  .member:nth-child(4n + 3) .member-image,
  .member:nth-child(4n + 4) .member-image {
    order: 2;
  }

  /* Merubah posisi sigitiga pada baris genap */
  .member:nth-child(4n + 3) .member-image::after,
  .member:nth-child(4n + 4) .member-image::after {
    left: 0;
    right: auto;
    -webkit-transform: translateY(-50%) rotateZ(180deg);
            transform: translateY(-50%) rotateZ(180deg);
  }
}

/* Mobile Styles */
@media screen and (max-width: 950px) {
  .list-members {
    width: 95%;
  }
  .member {
    flex-basis: 100%;
    font-size: 14px;
  }
  .social-link .fab {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .member:nth-child(even) .member-info {
    order: 1;
  }
  .member:nth-child(even) .member-image {
    order: 2;
  }

  /* Merubah posisi sigitiga elemen genap */
  .member:nth-child(even) .member-image::after {
    left: 0;
    right: auto;
    -webkit-transform: translateY(-50%) rotateZ(180deg);
            transform: translateY(-50%) rotateZ(180deg);
  }
}

@media (max-width: 500px) {
  .about-hideit {
    display: none;
  }
  .about-firstletter {
    text-transform: uppercase;
    margin-left: 0;
  }
  .before-info h3 {
    font-size: 1rem;
    margin-bottom: -10px;
  }
  .before-info h6 {
    font-size: 0.8rem;
  }
  .about-showit {
    display: inline;
    margin-left: 0;
  }
  .after-info h4 {
    font-size: 1rem;
    margin-bottom: -20px;
  }

  .after-info p {
    margin-top: 10px;
    font-size: 0.7rem;
  }
}

@font-face {
	font-family: 'Telefon Black';
	src: url('https://raw.githubusercontent.com/arnabmunshi/fontface/master/TelefonBlack/Telefon-Black.woff2') format('woff2'), url('https://raw.githubusercontent.com/arnabmunshi/fontface/master/TelefonBlack/Telefon-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}
.page-event {
	background-color: #fdca1294 ;
	
	/* padding-bottom: 40px; */
	font-family: "Varela Round", sans-serif !important;
}
.page-event .event-cover {
	/* background-color: #e0b107e1; */
	width: calc(98vw +5px);
	/* height: 75px; */
	padding: 0 15px;
	border-bottom: 7px solid rgba(0, 0, 0, 0.623);
	/* background-image: url('https://goo.gl/NA3iQP'); */
	/* background-position: center center;
	background-size: cover; */
}
.page-event .event-cover .event-heading {
	font-family: 'Telefon Black';
	font-size: 120px;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	text-align: center;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: black;
	text-shadow: 2px 2px 8px #fdcc0c;
	transition: font-size 0.3s;
}
.page-event .event-cover .event-heading::first-letter {
	color: #fdcc0c;
	text-shadow: 2px 2px 8px black;
}
.page-event .upcoming-sec {
	width: 70%; /* or whatever width you want. */
   	max-width:70%;
	display: inline-block;
    height: 5vh;
	margin: 0px auto;
	border-bottom: 7px solid rgba(0, 0, 0, 0.623);

	padding: 0;

	display: flex;
	justify-content: center;
	align-items: center;	
}
.page-event .upcoming-sec .event-heading {
	padding : 0px 0;
	font-size: 20px;
	color: black;
}
@media only screen and (max-width: 1035px){
	.page-event{
		/* width: 1035px;
		max-width: 1035px; */
		/* padding: 0 130px; */
		margin: 0;
	}
}

@media only screen and (max-width: 1020px){
	/* .page-event{
		width:1025px; 
   		max-width:1025px;
		display: inline-block;
	} */
	.event-cover{
		/* width: 770px; */
		/* padding: 0 130px;
		margin: 0; */
		/* margin: 0 80px; */
	}
}
@media only screen and (max-width:500px){
	.page-event .event-cover .event-heading{
		font-size: 70px;
	}
}
@media only screen and (max-width:300px){
	.page-event .event-cover .event-heading{
		font-size: 50px;
	}
}
body{
    margin: 0;
    /* background-color: #212121; */
}
/* .main{
    margin: 0; 
} */
@font-face {
  font-family: "Telefon Black";
  src: url("https://raw.githubusercontent.com/arnabmunshi/fontface/master/TelefonBlack/Telefon-Black.woff2")
      format("woff2"),
    url("https://raw.githubusercontent.com/arnabmunshi/fontface/master/TelefonBlack/Telefon-Black.woff")
      format("woff");
  font-weight: 900;
  font-style: normal;
}

.event-container {
  display: flex;
  justify-content: space-evenly;
  height: auto;
  align-items: center;
  margin: 20px 0;
  padding: 20px 0;
}
td:nth-child(1) {
  margin-left: 30px;
}
.parentflex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-button{
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-top: 5px;
}

.event-button a{
  padding: 5px 15px;
  background-color: #a81f6a;
  color: white;
  border-radius: 5px;
  margin-top: 5px;
  transition: .5s;
}

.event-button a:hover{
  color: black;
  font-weight: 700;
  background-color: #a85784
}

.event-card1 {
  position: relative;
  width: 43vw;
  margin: 20px 0px;
  margin-bottom: 10px;
  padding: 10px;
  font-family: "Varela Round", sans-serif;
  color: black;
  background: linear-gradient(135deg, #f76b1c 0%, #fad961 100%);
  border-radius: 8px;
  box-shadow: 20px 20px 22px rgba(0, 0, 0, 0.2);
}
table {
  vertical-align: text-top;
  width: 100%;
}
td:nth-child(1) {
  width: 10%;
}
table .month {
  text-transform: uppercase;
  color: #a1045a;
  font-size: 28px;
  -webkit-transform: scaleY(1.9);
          transform: scaleY(1.9);
  margin-left: 0px;
  font-weight: 700;
  padding-bottom: 1.4vh;
}
table .month-date-devider {
  width: 10vh;
  background-color: #3cbfaf;
  height: 5px;
}
table .date {
  font-size: 42px;
  font-weight: bold;
  line-height: normal;
  color: #fff;
  -webkit-transform: scaleY(2.7);
          transform: scaleY(2.7);
  padding-top: 2vh;
  margin-right: 6vh;
  text-shadow: 6px 4px 4px rgb(59, 59, 59);
}
.event-thumbnail {
  border-radius: 5px;
  padding-right: 0;
  width: 100%;
}
.event-title > h3 {
  font-size: 25px;
  font-weight: 800;
  text-align: center;
  padding-top: 10px;
}
.event-id {
  text-align: center;
}
.event-location,
.event-time {
  display: block;
}
.event-time{
  text-decoration: underline;
  font-size: 15px;
}
.event-location{
  font-size: 20px;
}

.event-time,
.event-location {
  font-weight: 600;
  color: rgba(20, 20, 20, 0.822);
}
.event-time {
  padding-left: 2px;
}
.event-quote {
  width: 100%;
  font-size: 16px;
  font-weight: normal;
}
.event-form {
  width: 145px;
  margin: 20px auto;
  font-weight: 700;
}
.event-formlink {
  width: 100%;
  min-width: 100%;
  text-align: center;
  align-items: center;
  background-color: #3cbfaf;
  color: #fff;
  margin-bottom: 10px;
  padding: 10px 10px;
  border-radius: 2px;
  text-decoration: none;
}

.eventsection-seemore {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  margin-left: -10px;
  font-size: 2em;
  color: antiquewhite;
  background-color: black;
  opacity: 0.8;
  z-index: 500;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 950px) {
  .event-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .event-thumbnail {
    width: 100%;
    padding: 0;
    margin: 0 20px;
  }
  .event-card1 {
    width: 60%;
    margin: 30px 0px;
    padding: 10px 30px;
    display: inline-block;
  }
  .eventsection-seemore {
    margin-left: -30px;
  }
}
@media only screen and (max-width: 800px) {
  .event-container {
    grid-gap: 10px;
    gap: 10px;
    justify-items: center;
  }
}
@media only screen and (max-width: 700px) {
  .event-card1 {
    width: 70%;
  }
}
@media only screen and (max-width: 650px) {
  .event-container {
    padding: 0;
    margin: 0;
  }
  .event-location,
  table .month {
    font-size: 18px;
  }
  table .date {
    font-size: 40px;
  }
  .event-time,
  .event-quote {
    font-size: 14px;
    width: 100%;

    margin: 0;
    padding: 0;
  }
  div .event-title h3 {
    font-size: 24px;
    margin: 0;
  }
  .event-form .event-formlink {
    font-size: 14px;
    width: auto;
    position: relative;
    left: -5%;
  }
  
  .event-card1 {
    padding: 10px 20px;
  }
  .event-quote {
    font-size: 14px;
    width: 100%;
  }
  .eventsection-seemore {
    margin-left: -20px;
  }
}
@media only screen and (max-width: 500px) {
  .event-card1 {
    width: 80%;
  }
  table .month {
    font-size: 24px;
  }
  table .date {
    font-size: 30px;
    margin-left: 2.5vw;
    margin-right: 2vw;
  }
  .event-form .event-formlink {
    font-size: 12px;
    width: auto;
    position: relative;
    left: -5%;
  }
  .event-title {
    margin-top: 3vh;
  }
}
@media (max-width: 400px) {
  table .date {
    font-size: 25px;
  }
  .event-thumbnail{
    width: 100%;
  }
}
@media only screen and (max-width: 335px) {
  .event-title {
    margin-top: 3vh;
  }
  table .month {
    font-size: 18px;
    margin: 0;
  }
  table .date {
    font-size: 18px;
    margin: 0;
  }
  table .month-date-devider {
    width: 6vh;
  }
  .event-thumbnail {
    height: 20vh;
    width: 105%;
    margin: 0 0 0 6px;
  }
}
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .event-card1 {
    width: 700px;
    max-width: 700px;
  }
  .event-thumbnail {
    width: 550px;
  }
  .event-location,
  .event-time,
  .event-quote {
    font-size: 18px;
    width: auto;
    margin: 0;
    padding: 0;
  }
  div .event-title h3 {
    font-size: 48px;
  }
  .event-form .event-formlink {
    font-size: 24px;
    width: 260px;
  }
}

.event-image-grid {
  --gap: 16px;
  --num-cols: 4;
  --row-height: 300px;
  box-sizing: border-box;
  padding: var(--gap);
  padding-top: 6px;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  grid-auto-rows: var(--row-height);
  grid-gap: var(--gap);
  gap: var(--gap);
  margin-bottom: 10px;
  background-color: white;
}
.event-image-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.4s ease-in-out;
  cursor: pointer;
}

.event-image-grid img:hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.event-image-grid-col-2 {
  grid-column: span 2;
}

.event-image-grid-row-2 {
  grid-row: span 2;
}

.photo-seemore:hover {
  opacity: 1;
  -webkit-transform: rotate3d(45deg);
          transform: rotate3d(45deg);
}
.photo-seemore .seemore {
  position: relative;
  width: 100%;
  height: 100%;
  top: -100%;
  font-size: 2em;
  color: antiquewhite;
  background-color: black;
  opacity: 0.5;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.photo-seemore .seemore:hover {
  opacity: 0.8;
  text-decoration: underline;
}
.seemore p {
  margin: 0;
}
.photo-modal{
  width: 500px;
  height: 500px;
}
.modal {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 65px;
  left: 0;
  background-color:rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  color: #fff;
  z-index: 1000;
  overflow: auto;
  opacity: 0;
  pointer-events: none;

  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalImg {
  width: 80%;
  max-width: 700px;
  max-height: 80vh;
}
.modalTxt {
  margin-top: 1em;
}
.close {
  position: relative;
  top: 1em;
  right:45%;
  font-size: 1.5em;
  cursor: pointer;
}
.modal.appear { 
  opacity: 1;
  pointer-events: all;
}
.modal.appear .modalImg,
.modal.appear .modalTxt {
  -webkit-animation: zoom 0.3s linear;
          animation: zoom 0.3s linear;
}
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes zoom {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 1024px) {
  .event-image-grid {
    --num-cols: 2;
    --row-height: 200px;
  }
}
@media screen and (max-width: 600px) {
  .event-image-grid {
    --num-cols: 2;
    --row-height: 100px;
  }
}
@media screen and (max-width: 300px) {
  .modalContent{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    position: relative;
    top: 20%;
  }
}

/*video*/
.event-video-container {
  width: 100%;
  margin: 6px 0 0 0;
}

iframe {
  width: 95%;
  height: calc(110ew / eval("width/height"));
  display: flex;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 20px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.heading {
  margin: 6px auto;
  width: 80%;
  font-size: 20px;
  font-weight: bold;

  letter-spacing: -1px;
  justify-content: center;
  flex-wrap: wrap;
}
.headingSec {
  display: flex;
  align-items: center;
  font-size: 16px;
  letter-spacing: -1px;
  color: black;
  flex-wrap: wrap;
  margin: 6px auto;
  /* justify-content: center; */
}

.headingSec h1 {
  margin-right: 10px;
}
.headingSec h2 {
  margin-top: 25px;
  font-size: 20px;
}
.event-video {
  display: grid;
  grid-template-columns: auto 5vw auto;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  /* margin-bottom: 25px; */
  padding: 60px;
}
.event-video:nth-child(2) {
  padding-top: 0;
  padding-bottom: 0;
}
.event-video1 {
  padding: 10px 0;
}
.videosection-seemore {
  position: relative;
  width: 95%;
  height: 81%;
  top: -81%;
  font-size: 2em;
  color: antiquewhite;
  background-color: black;
  opacity: 0.8;
  z-index: 500;
  /* text-decoration: underline; */
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.videosection-seemore:hover {
  opacity: 0.8;
  text-decoration: underline;
}
.seemore p {
  margin: 0;
}
.videosection-seemore p a {
  color: antiquewhite;
}

@media screen and (max-width: 800px) {
  .event-video {
    margin: 0 auto;
    grid-template-columns: auto;
  }
}
@media screen and (max-width: 500px) {
  .event-video {
    padding: 20px;
    grid-template-columns: 90%;
    margin: 0 auto;
  }
  iframe {
    width: 110%;
    height: 250px;
  }
  .videosection-seemore {
    width: 110%;
    height: 78%;
    position: relative;
    top: -78%;
  }
}
@media screen and (max-width: 410px) {
  .event-video {
    grid-template-columns: 90%;
    margin: 0;
    padding: 0 20px;
  }
  .event-video:nth-child(1) {
    padding-top: 20px;
  }
  iframe {
    width: 110%;
    height: 150px;
  }

  .videosection-seemore {
    height: 150px;
    position: relative;
    top: -83%;
  }
  .event-video1:nth-child(3) {
    height: 200px;
  }
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  iframe {
    width: 900px;
    height: 500px;
    margin: 0;
  }
  .event-video {
    margin: 0 auto;
    grid-template-columns: auto;
  }
}

.slide h1 {
    transition: all 0.3s ease;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }

  .slide button {
    transition: all 0.2s ease;
    -webkit-transform: translateY(120px);
    transform: translateY(20px);
    opacity: 0;
  }
  
  .slide p {
    transition: all 0.2s ease;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
  
  .slide section span {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }
  
  .slide section span strong {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 0;
  }
  
  .slide.animateIn.previous h1,
  .slide.current h1,
  .slide.animateIn.next h1,
  .slide.animateIn.previous button,
  .slide.current button,
  .slide.animateIn.next button,
  .slide.animateIn.previous p,
  .slide.current p,
  .slide.animateIn.next p,
  .slide.animateIn.previous section *,
  .slide.current section *,
  .slide.animateIn.next section * {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    transition-delay: 0.7s;
    opacity: 1;
  }
  
  .slide.animateIn.previous p,
  .slide.animateIn.next p {
    transition-delay: 0.9s;
  }
  
  .slide.animateIn.previous button,
  .slide.animateIn.next button {
    transition-delay: 1.1s;
  }
  
  .slide.animateIn.previous section img,
  .slide.animateIn.next section img {
    transition-delay: 1.1s;
  }
  
  .slide.animateIn.previous section span,
  .slide.animateIn.next section span {
    transition-delay: 1.2s;
  }
  
  .slide.animateIn.previous section span strong,
  .slide.animateIn.next section span strong {
    transition-delay: 1.3s;
  }
  
  .slide.animateOut h1 {
    transition-delay: 0.1s;
  }
  
  .slide.animateOut p {
    transition-delay: 0.1s;
  }
  
  .slide.animateOut section span {
    transition-delay: 0.2s;
  }
  
  .slide.animateOut section span strong {
    transition-delay: 0s;
  }
  
.journal-slider-wrapper {
  position: relative;
  height: 70vh;
  overflow: hidden;
}

.slide {
  /* height: h;
  background-size: cover !important; */
}

.jr-sliderMain {
  padding-top: 65px;
}

.slide::before {
  content: "";
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0.9))
  );
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.9)); */
  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 15;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABHklEQVRIia2VPWrDQBBGZWGMS5ETBhNS+iYujBEpjA+QIlUwLnKuFMboPReRwiL0k+x4YGGK5ft23u7sFMVIqAXwDByA5di+rGjFX9VvVaBW1w8TVzfA1TaARq3VWCVqqW7VG9CJp+sUMlFX6ocTARxCuNRKvUwYNK1JqJI1cEkR9XP1GDIBKvVzglYD7B+B65yc2l7eqPtQnwzhSpfaqG+PwHWewbVTV5FKnoCvGVy7Pq4yYDiUs1gs+LfYHKLu9FmI0ksewBK7ZLUCLkON1uaNus8S/0Ojqeb9S30sPd5desxqMKACzhNYVOtc8aX6PvFa9Gfw5P0/aglsgduIx8lIt7ZVFOqLek2xtHM5Jp5UUqi/Qz+EZcoE2Bgc9nfOlDhYD/My5AAAAABJRU5ErkJggg==) no-repeat center center / 25px;
  width: 35px;
  height: 35px;
  text-indent: -9999px;
  cursor: pointer;
  background-color: black;
  opacity: 0.5;
  padding: 50px 20px;
  /* margin: 0 20px; */
  /* border-radius: 20px; */
  transition: 0.2s;
}

.previousButton:hover,
.nextButton:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABHklEQVRIia2VPWrDQBBGZWGMS5ETBhNS+iYujBEpjA+QIlUwLnKuFMboPReRwiL0k+x4YGGK5ft23u7sFMVIqAXwDByA5di+rGjFX9VvVaBW1w8TVzfA1TaARq3VWCVqqW7VG9CJp+sUMlFX6ocTARxCuNRKvUwYNK1JqJI1cEkR9XP1GDIBKvVzglYD7B+B65yc2l7eqPtQnwzhSpfaqG+PwHWewbVTV5FKnoCvGVy7Pq4yYDiUs1gs+LfYHKLu9FmI0ksewBK7ZLUCLkON1uaNus8S/0Ojqeb9S30sPd5desxqMKACzhNYVOtc8aX6PvFa9Gfw5P0/aglsgduIx8lIt7ZVFOqLek2xtHM5Jp5UUqi/Qz+EZcoE2Bgc9nfOlDhYD/My5AAAAABJRU5ErkJggg==) no-repeat center center / 25px;
  background-color: black;
  opacity: 1;
  /* border-radius: 54px; */
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
  left: 2px;
}

.nextButton {
  right: 0px;
}

.nextButton:hover {
  right: 2px;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 64px;
  line-height: 1;
}

.slider-content .inner p {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

@media (max-height: 500px) {
  .journal-slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
}

@media (max-width: 640px) {
  /* .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  } */
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}
/* @media (max-width: 1200px) {
  .journal-slider-wrapper {
    height: calc(70vh - (1200px - var(--variable-width)) * 0.38);
  }
}

@media (max-height: 1400px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 2.26));
  }
}
@media (max-height: 1200px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 1.45));
  }
}
@media (max-height: 1100px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 1.05));
  }
}
@media (max-height: 950px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.645));
  }
}
@media (max-height: 900px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.615));
  }
}
@media (max-height: 860px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.565));
  }
}

@media (max-height: 800px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.465));
  }
}

@media (max-height: 700px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.425));
  }
}

@media (max-height: 640px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.4));
  }
}

@media (min-width: 1200px) {
  .journal-slider-wrapper {
    height: calc((70vh - ((1200px - var(--variable-width)) * 0.4)) - 20vh);
  }
} */

@media (max-width: 800px) {
  .previousButton,
  .nextButton {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABHklEQVRIia2VPWrDQBBGZWGMS5ETBhNS+iYujBEpjA+QIlUwLnKuFMboPReRwiL0k+x4YGGK5ft23u7sFMVIqAXwDByA5di+rGjFX9VvVaBW1w8TVzfA1TaARq3VWCVqqW7VG9CJp+sUMlFX6ocTARxCuNRKvUwYNK1JqJI1cEkR9XP1GDIBKvVzglYD7B+B65yc2l7eqPtQnwzhSpfaqG+PwHWewbVTV5FKnoCvGVy7Pq4yYDiUs1gs+LfYHKLu9FmI0ksewBK7ZLUCLkON1uaNus8S/0Ojqeb9S30sPd5desxqMKACzhNYVOtc8aX6PvFa9Gfw5P0/aglsgduIx8lIt7ZVFOqLek2xtHM5Jp5UUqi/Qz+EZcoE2Bgc9nfOlDhYD/My5AAAAABJRU5ErkJggg==) no-repeat center center / 15px;
    width: 30px;
    height: 35px;
    text-indent: -9999px;
    cursor: pointer;
    background-color: black;
    opacity: 0.5;
    padding: 35px 12px;
    transition: 0.2s;
  }

  .previousButton:hover,
  .nextButton:hover {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABHklEQVRIia2VPWrDQBBGZWGMS5ETBhNS+iYujBEpjA+QIlUwLnKuFMboPReRwiL0k+x4YGGK5ft23u7sFMVIqAXwDByA5di+rGjFX9VvVaBW1w8TVzfA1TaARq3VWCVqqW7VG9CJp+sUMlFX6ocTARxCuNRKvUwYNK1JqJI1cEkR9XP1GDIBKvVzglYD7B+B65yc2l7eqPtQnwzhSpfaqG+PwHWewbVTV5FKnoCvGVy7Pq4yYDiUs1gs+LfYHKLu9FmI0ksewBK7ZLUCLkON1uaNus8S/0Ojqeb9S30sPd5desxqMKACzhNYVOtc8aX6PvFa9Gfw5P0/aglsgduIx8lIt7ZVFOqLek2xtHM5Jp5UUqi/Qz+EZcoE2Bgc9nfOlDhYD/My5AAAAABJRU5ErkJggg==) no-repeat center center / 15px;
    background-color: black;
    opacity: 1;
    /* border-radius: 54px; */
  }
}

@media (max-width: 500px) {
  .previousButton,
  .nextButton {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABHklEQVRIia2VPWrDQBBGZWGMS5ETBhNS+iYujBEpjA+QIlUwLnKuFMboPReRwiL0k+x4YGGK5ft23u7sFMVIqAXwDByA5di+rGjFX9VvVaBW1w8TVzfA1TaARq3VWCVqqW7VG9CJp+sUMlFX6ocTARxCuNRKvUwYNK1JqJI1cEkR9XP1GDIBKvVzglYD7B+B65yc2l7eqPtQnwzhSpfaqG+PwHWewbVTV5FKnoCvGVy7Pq4yYDiUs1gs+LfYHKLu9FmI0ksewBK7ZLUCLkON1uaNus8S/0Ojqeb9S30sPd5desxqMKACzhNYVOtc8aX6PvFa9Gfw5P0/aglsgduIx8lIt7ZVFOqLek2xtHM5Jp5UUqi/Qz+EZcoE2Bgc9nfOlDhYD/My5AAAAABJRU5ErkJggg==) no-repeat center center / 10px;
    width: 20px;
    height: 35px;
    text-indent: -9999px;
    cursor: pointer;
    background-color: black;
    opacity: 0.5;
    padding: 25px 4px;
    transition: 0.2s;
  }

  .previousButton:hover,
  .nextButton:hover {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABHklEQVRIia2VPWrDQBBGZWGMS5ETBhNS+iYujBEpjA+QIlUwLnKuFMboPReRwiL0k+x4YGGK5ft23u7sFMVIqAXwDByA5di+rGjFX9VvVaBW1w8TVzfA1TaARq3VWCVqqW7VG9CJp+sUMlFX6ocTARxCuNRKvUwYNK1JqJI1cEkR9XP1GDIBKvVzglYD7B+B65yc2l7eqPtQnwzhSpfaqG+PwHWewbVTV5FKnoCvGVy7Pq4yYDiUs1gs+LfYHKLu9FmI0ksewBK7ZLUCLkON1uaNus8S/0Ojqeb9S30sPd5desxqMKACzhNYVOtc8aX6PvFa9Gfw5P0/aglsgduIx8lIt7ZVFOqLek2xtHM5Jp5UUqi/Qz+EZcoE2Bgc9nfOlDhYD/My5AAAAABJRU5ErkJggg==) no-repeat center center / 10px;
    background-color: black;
    opacity: 1;
    /* border-radius: 54px; */
  }
}

.EventOuter {
  background-color: #efefef;
  animation: fadeIn ease 4s;
  -webkit-animation: fadeIn ease 4s;
  -moz-animation: fadeIn ease 4s;
  -o-animation: fadeIn ease 4s;
  -ms-animation: fadeIn ease 4s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@charset "UTF-8";

.res_app {
  background-color: #e1ebee;
  animation: fadeIn ease 4s;
  -webkit-animation: fadeIn ease 4s;
  -moz-animation: fadeIn ease 4s;
  -o-animation: fadeIn ease 4s;
  -ms-animation: fadeIn ease 4s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.res_row {
  max-width: 1024px;
}

.res_resources-heading {
  margin-top: 70px;
  margin-bottom: -5px;
  color: black;
  font-weight: 800;
  font-size: 55px;
  text-align: center;
  text-decoration: underline;
}

.res_resources-details {
  margin-top: 10px;
  color: rgb(114, 103, 103);
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  font-family: "PT Serif", serif;
}

.res_button {
  /* background-color: rgb(3, 2, 2); */
  border: none;
  color: rgb(0, 0, 0);
  padding: 5px 20px;
  border-radius: 10px;
  font-weight: 800;
}

.res_button:hover {
  /* background-color: #f6be00; */
  /* background-color: #fec701; */
  /* color: black; */
  background-color: black !important;
  color: rgb(255, 255, 255);
}

.res_main-container {
  position: relative;
  overflow-x: hidden;
  margin-left: 7%;
  margin-right: 7%;
}

#res_grid {
  margin-bottom: 60px;
  display: grid;
  grid-template-columns: 49% 49%;
  grid-column-gap: 5px;
}
@media only screen and (max-width: 900px) {
  #res_grid {
    grid-template-columns: 99%;
  }
}

.res_book-item {
  display: flex;
  margin: 10px 0;
  padding: 15px;
  list-style-type: none;
}
.res_book-item_content {
  font-family: "PT Serif", serif;
}
.res_book-item:after {
  content: "";
  position: absolute;
  top: 0;
  right: 15px;
  width: calc(100% - 105px);
  height: 100%;
  border-radius: 13px;
  box-shadow: 0 0 0 0 transparent;
  background-color: rgba(255, 255, 255, 0);
  z-index: -1;
  transition: all 250ms ease-out;
}
.res_book-item:hover {
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
}

.res_book-item:hover a.button {
  background-color: black;
}
.res_book-item:hover .res_bk-bookdefault {
  -webkit-transform: rotate3d(0, 1, 0, 25deg);
          transform: rotate3d(0, 1, 0, 25deg);
}

.res_book-item h2 {
  font-weight: 800 !important;
  text-decoration: underline;
}

.res_book-item p:not(.author) {
  display: block;
  display: -webkit-box;
  font-size: 18px;
  line-height: 1.4;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.res_bk-img {
  position: relative;
  padding-right: 30px;
  list-style: none;
}

.res_bk-img .res_bk-wrapper {
  position: relative;
  width: 140px;
  height: 165px;
  float: left;
  z-index: 1;
  -webkit-perspective: 1400px;
          perspective: 1400px;
}

.res_bk-img .res_bk-wrapper:last-child {
  margin-right: 100;
}

.res_bk-img .res_bk-book {
  position: absolute;
  width: 100%;
  height: 175px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.res_bk-img .res_bk-book > div,
.res_bk-img .res_bk-front > div {
  display: block;
  position: absolute;
}

.res_bk-img .res_bk-front {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform: translate3d(0, 0, 20px);
          transform: translate3d(0, 0, 20px);
  z-index: 10;
}
.res_bk-img .res_bk-front > div {
  z-index: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  border-radius: 0 5px 3px 0;
  box-shadow: inset 10px 0 10px rgba(0, 0, 0, 0.1);
}
.res_bk-img .res_bk-front:after {
  content: "...";
  position: absolute;
  top: 1px;
  bottom: 2px;
  left: -1px;
  width: 1px;
}
.res_bk-img .res_bk-front,
.res_bk-img .res_bk-back,
.res_bk-img .res_bk-front > div {
  width: 130px;
  height: 175px;
}
.res_bk-img .res_bk-left,
.res_bk-img .res_bk-right {
  width: 40px;
  left: -20px;
}

.res_bk-img .res_bk-left {
  height: 175px;
  -webkit-transform: rotate3d(0, 1, 0, -90deg);
          transform: rotate3d(0, 1, 0, -90deg);
}

.res_bk-img .res_bk-cover:after {
  content: "";
  position: absolute;
  top: 0;
  left: 10px;
  bottom: 0;
  width: 3px;
  background: rgba(0, 0, 0, 0.06);
  box-shadow: 1px 0 3px rgba(255, 255, 255, 0.1);
}

@media only screen and (max-width: 700px) {
  .res_book-item_content {
    font-size: 12px !important;
  }
}

.res_container {
  width: 750px;
  margin: 50px auto 0px auto;
  text-align: center;
}

@media only screen and (min-width: 1055px) {
  .ant-modal-content {
    width: 625px !important;
    height: 250px !important;
  }
}
.ant-modal-close{
 background-color: transparent !important;
 color: black !important;

}

.res_container h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 30px;
  margin-bottom: 15px;
}
.res_container h1 span {
  font-weight: 700;
  font-style: italic;
  color: #d90368;
  border-bottom: 2px dashed currentColor;
}
.res_container .res_button-effect {
  padding: 1px 10px;
}
.res_container .res_button-effect h2 {
  font-family: "Roboto", sans-serif;
  font-size: 50px;
  margin-bottom: 60px;
}
.res_container .res_button-effect a {
  margin-right: 10px;
}
.res_container .res_button-effect a:nth-child(2) {
  background-color: #370d59;
}
.res_container .res_button-effect a:nth-child(2):hover {
  background-color: #8920df;
}
.res_container .res_button-effect a:nth-child(3) {
  background-color: #660066;
}
.res_container .res_button-effect a:nth-child(3):hover {
  background-color: #ff00ff;
}
.res_container .res_button-effect a:nth-child(4) {
  background-color: #135153;
}
.res_container .res_button-effect a:nth-child(4):hover {
  background-color: #2fcad0;
}
.res_container .res_button-effect a:nth-child(5) {
  background-color: #66000e;
}
.res_container .res_button-effect a:nth-child(5):hover {
  background-color: #ff0022;
}
.res_container .res_button-effect a:nth-child(6) {
  background-color: #1c204a;
}
.res_container .res_button-effect a:nth-child(6):hover {
  background-color: #4650b9;
}
.res_container .res_button-effect a:nth-child(7) {
  background-color: #412539;
}
.res_container .res_button-effect a:nth-child(7):hover {
  background-color: #a25d8e;
}
.res_container .res_button-effect a:nth-child(8) {
  background-color: #444b1b;
}
.res_container .res_button-effect a:nth-child(8):hover {
  background-color: #a9bb44;
}
.res_container .res_button-effect a:nth-child(9) {
  background-color: #513915;
}
.res_container .res_button-effect a:nth-child(9):hover {
  background-color: #cb8f34;
}
.res_container .res_button-effect a:last-child {
  margin-right: 0px;
}

/* button styles !!!YOU NEED THEM 
  !!!ALSO YOU NEED TO ADD FONTWESOME */
.res_effect {
  text-align: center;
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: #fff;
  text-transform: capitalize;
  /* background-color: - add your own background-color */
  font-family: "Roboto", sans-serif;
  /* put your font-family */
  font-size: 18px;
  padding: 20px 0px;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  overflow: hidden;
}

/* effect-1 styles */
.res_effect.res_effect-1 {
  transition: all 0.2s linear 0s;
}
.res_effect.res_effect-1:before {
  content: "\1F872";
  font-family: FontAwesome;
  font-size: 15px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  opacity: 0;
  height: 100%;
  width: 25px;
  transition: all 0.2s linear 0s;
}
.res_effect.res_effect-1:hover {
  text-indent: -25px;
}
.res_effect.res_effect-1:hover:before {
  opacity: 1;
  text-indent: 7px;
}

.res_button-effect {
  padding: 1px 10px;
}
.res_button-effect h2 {
  font-family: "Roboto", sans-serif;
  font-size: 50px;
  margin-bottom: 60px;
  font-weight: 700;
}
.res_button-effect a {
  margin-right: 10px;
}
.res_button-effect a:nth-child(2) {
  background-color: #370d59;
}
.res_button-effect a:nth-child(2):hover {
  background-color: #8920df;
}
.res_button-effect a:nth-child(3) {
  background-color: #660066;
}
.res_button-effect a:nth-child(3):hover {
  background-color: #ff00ff;
}
.res_button-effect a:nth-child(4) {
  background-color: #135153;
}
.res_button-effect a:nth-child(4):hover {
  background-color: #2fcad0;
}
.res_button-effect a:nth-child(5) {
  background-color: #66000e;
}
.res_button-effect a:nth-child(5):hover {
  background-color: #ff0022;
}
.res_button-effect a:nth-child(6) {
  background-color: #1c204a;
}
.res_button-effect a:nth-child(6):hover {
  background-color: #4650b9;
}
.res_button-effect a:nth-child(7) {
  background-color: #412539;
}
.res_button-effect a:nth-child(7):hover {
  background-color: #a25d8e;
}
.res_button-effect a:nth-child(8) {
  background-color: #444b1b;
}
.res_button-effect a:nth-child(8):hover {
  background-color: #a9bb44;
}
.res_button-effect a:nth-child(9) {
  background-color: #513915;
}
.res_button-effect a:nth-child(9):hover {
  background-color: #cb8f34;
}
.res_button-effect a:last-child {
  margin-right: 0px;
}

/* res_button styles !!!YOU NEED THEM 
  !!!ALSO YOU NEED TO ADD FONTWESOME */
.res_effect {
  text-align: center;
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: #fff;
  text-transform: capitalize;
  /* background-color: - add your own background-color */
  font-family: "Roboto", sans-serif;
  /* put your font-family */
  font-size: 18px;
  padding: 20px 0px;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  overflow: hidden;
}

/* effect-1 styles */
.res_effect.res_effect-1 {
  transition: all 0.2s linear 0s;
}
.res_effect.res_effect-1:before {
  content: "\1F872";
  font-family: FontAwesome;
  font-size: 15px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 5px;
  top: 5px;
  opacity: 0;
  height: 100%;
  width: 25px;
  transition: all 0.2s linear 0s;
}
.res_effect.res_effect-1:hover {
  text-indent: -25px;
}
.res_effect.res_effect-1:hover:before {
  opacity: 1;
  text-indent: 7px;
}

.lock {
  pointer-events: none;
  cursor: default;
}
.res_lock {
  position: absolute;
  top: 0;
  background-color: black;
  opacity: 0.8;
  z-index: 2000;
  width: 60px;
  height: 60px;
}

.ant-modal-content {
  background-color: white !important;
  border: black 2px solid !important;
  box-shadow: none !important;
  border-radius: 25px !important;
}

.res_button-effect a:hover {
  color: white !important;
  font-size: 23px;
  bottom: 10px;
}

.jr {
  background-color: #efefef;
  animation: fadeIn ease 4s;
  -webkit-animation: fadeIn ease 4s;
  -moz-animation: fadeIn ease 4s;
  -o-animation: fadeIn ease 4s;
  -ms-animation: fadeIn ease 4s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.jr_componentss {
  background-color: #f0f0ef7d;
  padding-bottom: 60px;
  margin: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  flex-wrap: wrap;
}
.jr_card {
  position: relative;
  width: 600px;
  height: 460px;
  cursor: pointer;
  border-radius: 16px;
  box-shadow: 0 0 13px 0px rgba(0, 0, 0, 0.3);
  transition: 0.5s;
  overflow: hidden;
  margin: 1.85rem;
}
.jr_card:hover {
  height: 600px;
}
.jr_card img {
  height: 400px;
  width: 100%;
  border-radius: 16px;
  transition: 0.5s;
}
.jr_card:hover img {
  border-radius: 16px 16px 0 0;
}

.jr_card .jr_card-body {
  padding: 0.5rem 0.85rem 1rem;
  height: 0;
}

.jr_card .jr_card-body h6 {
  color: #464646;
  /* margin: 0.5rem 0; */
  /* font-style: italic; */
  text-align: center;
  font-size: 1.5rem;
  letter-spacing: 3px;
  font-family: "Mochiy Pop P One", sans-serif;
}
.jr_card:hover h6 {
  color: blue;
  transition: all 1s ease-in-out;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}
.jr_card .jr_card-body p {
  text-align: justify;
  padding-top: 10px;
  font-size: 1.3rem;
  line-height: 25px;
  font-weight: 400;
  font-family: "Merriweather", serif;
}

.jr_card .jr_card-body div {
  text-align: right;
  width: 100%;
}
@media (max-width: 560px) {
  .jr_card {
    width: 455px;
    height: 350px;
  }
  .jr_card img {
    height: 85%;
  }
  .jr_card:hover {
    height: 500px;
  }
  .jr_card:hover img {
    height: 300px;
  }
  .jr_card .jr_card-body h6 {
    font-size: 25px;
  }
  .jr_card .jr_card-body p {
    font-size: 16px;
  }
  .jr_card:hover h6 {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}
@media (max-width: 430px) {
  .jr_card {
    width: 412px;
    height: 300px;
  }
  .jr_card img {
    height: 80%;
  }
  .jr_card:hover {
    height: 500px;
  }
  .jr_card:hover img {
    height: 280px;
  }
  .jr_card .jr_card-body h6 {
    font-size: 20px;
  }
}
@media (max-width: 360px) {
  .jr_card {
    width: 390px;
    height: 245px;
  }
  .jr_card img {
    height: 75%;
  }
  .jr_card:hover {
    height: 530px;
  }
  .jr_card:hover img {
    height: 240px;
  }
  .jr_card .jr_card-body h6 {
    /* padding-top: 5px; */
    margin-bottom: 12px;
    font-size: 18px;
  }
}
@media (max-width: 320px) {
  .jr_card {
    width: 351px;
    height: 270px;
  }
  .jr_card img {
    height: 70%;
  }
  .jr_card:hover {
    height: 530px;
  }
  .jr_card:hover img {
    height: 180px;
  }
  .jr_card .jr_card-body h6 {
    padding-top: 20px;
    font-size: 17px;
  }
}

.slide h1 {
  transition: all 0.3s ease;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0;
}

.slide button {
  transition: all 0.2s ease;
  -webkit-transform: translateY(120px);
  transform: translateY(20px);
  opacity: 0;
}

.slide p {
  transition: all 0.2s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}


.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition-delay: 0.7s;
  opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
  transition-delay: 0.9s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
  transition-delay: 1.1s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
  transition-delay: 1.1s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
  transition-delay: 1.2s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
  transition-delay: 1.3s;
}

.slide.animateOut h1 {
  transition-delay: 0.1s;
}

.slide.animateOut p {
  transition-delay: 0.1s;
}

.journal-slider-wrapper {
  position: relative;
  height: 70vh;
  overflow: hidden;
}

.slide {
  /* height: h;
  background-size: cover !important; */
}

.journal-slider-content img {
  width: 100%;
  height: 70vh;
}

.jr-sliderMain {
  padding-top: 65px;
}

.slide::before {
  content: "";
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0.9))
  );
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.9)); */
  bottom: 0;
  left: 0;
}

/* .previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 15;
  background: url("https://www.svgrepo.com/show/1024/right-arrow.svg") no-repeat
    center center / 16px;
  width: 35px;
  height: 35px;
  text-indent: -9999px;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 20px;
}

.previousButton:hover,
.nextButton:hover {
  background: url("https://www.svgrepo.com/show/1024/right-arrow.svg") no-repeat
    center center / 18px;
  background-color: #ffffff;
  border-radius: 54px;
} */

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
  left: 2px;
}

.nextButton {
  right: 0px;
}

.nextButton:hover {
  right: 2px;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 64px;
  line-height: 1;
}

.slider-content .inner p {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

@media (max-height: 500px) {
  .journal-slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
}

@media (max-width: 640px) {
  /* .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  } */
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}
@media (max-width: 1200px) {
  .journal-slider-wrapper {
    /* height: calc(70vh - (1200px - var(--variable-width)) * 0.38); */
    height: 420px;
  }
  .journal-slider-content img {
    height: 420px;
  }
}
@media (max-width: 1100px) {
  .journal-slider-wrapper {
    height: 410px;
  }
  .journal-slider-content img {
    height: 410px;
  }
}
@media (max-width: 1050px) {
  .journal-slider-wrapper {
    height: 400px;
  }
  .journal-slider-content img {
    height: 400px;
  }
}
@media (max-width: 1000px) {
  .journal-slider-wrapper {
    height: 390px;
  }
  .journal-slider-content img {
    height: 390px;
  }
}
@media (max-width: 950px) {
  .journal-slider-wrapper {
    height: 380px;
  }
  .journal-slider-content img {
    height: 380px;
  }
}
@media (max-width: 900px) {
  .journal-slider-wrapper {
    height: 370px;
  }
  .journal-slider-content img {
    height: 370px;
  }
}
@media (max-width: 850px) {
  .journal-slider-wrapper {
    height: 355px;
  }
  .journal-slider-content img {
    height: 355px;
  }
}
@media (max-width: 800px) {
  .journal-slider-wrapper {
    height: 340px;
  }
  .journal-slider-content img {
    height: 340px;
  }
}
@media (max-width: 750px) {
  .journal-slider-wrapper {
    height: 325px;
  }
  .journal-slider-content img {
    height: 325px;
  }
}
@media (max-width: 700px) {
  .journal-slider-wrapper {
    height: 310px;
  }
  .journal-slider-content img {
    height: 310px;
  }
}
@media (max-width: 650px) {
  .journal-slider-wrapper {
    height: 295px;
  }
  .journal-slider-content img {
    height: 295px;
  }
}
@media (max-width: 625px) {
  .journal-slider-wrapper {
    height: 285px;
  }
  .journal-slider-content img {
    height: 285px;
  }
}
@media (max-width: 600px) {
  .journal-slider-wrapper {
    height: 275px;
  }
  .journal-slider-content img {
    height: 275px;
  }
}
@media (max-width: 575px) {
  .journal-slider-wrapper {
    height: 265px;
  }
  .journal-slider-content img {
    height: 265px;
  }
}
@media (max-width: 550px) {
  .journal-slider-wrapper {
    height: 255px;
  }
  .journal-slider-content img {
    height: 255px;
  }
}
@media (max-width: 525px) {
  .journal-slider-wrapper {
    height: 245px;
  }
  .journal-slider-content img {
    height: 245px;
  }
}
@media (max-width: 500px) {
  .journal-slider-wrapper {
    height: 235px;
  }
  .journal-slider-content img {
    height: 235px;
  }
}
@media (max-width: 475px) {
  .journal-slider-wrapper {
    height: 225px;
  }
  .journal-slider-content img {
    height: 225px;
  }
}
@media (max-width: 450px) {
  .journal-slider-wrapper {
    height: 215px;
  }
  .journal-slider-content img {
    height: 215px;
  }
}
@media (max-width: 425px) {
  .journal-slider-wrapper {
    height: 205px;
  }
  .journal-slider-content img {
    height: 205px;
  }
}
@media (max-width: 400px) {
  .journal-slider-wrapper {
    height: 195px;
  }
  .journal-slider-content img {
    height: 195px;
  }
}
@media (max-width: 375px) {
  .journal-slider-wrapper {
    height: 185px;
  }
  .journal-slider-content img {
    height: 185px;
  }
}
@media (max-width: 350px) {
  .journal-slider-wrapper {
    height: 175px;
  }
  .journal-slider-content img {
    height: 175px;
  }
}
@media (max-width: 325px) {
  .journal-slider-wrapper {
    height: 165px;
  }
  .journal-slider-content img {
    height: 165px;
  }
}
@media (max-width: 300px) {
  .journal-slider-wrapper {
    height: 155px;
  }
  .journal-slider-content img {
    height: 155px;
  }
}
/* 
@media (max-height: 1400px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 2.26));
  }
}
@media (max-height: 1200px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 1.45));
  }
}
@media (max-height: 1100px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 1.05));
  }
}
@media (max-height: 950px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.645));
  }
}
@media (max-height: 900px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.615));
  }
}
@media (max-height: 860px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.565));
  }
}

@media (max-height: 800px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.465));
  }
}

@media (max-height: 700px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.425));
  }
}

@media (max-height: 640px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.4));
  }
}

@media (min-width: 1200px) {
  .journal-slider-wrapper {
    height: calc((70vh - ((1200px - var(--variable-width)) * 0.4)) - 20vh);
  }
} */

/* 


@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");

.slider-wrapper {
  position: relative;
  height: 70vh;
  overflow: hidden;
}

.slide {
  height: 100vh;
  background-size: cover !important;
}
.jr-sliderMain{
  padding-top: 65px;
}

.slide::before {
  content: "";
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0.9))
  );
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 15;
  background: url("https://www.svgrepo.com/show/1024/right-arrow.svg") no-repeat
    center center / 16px;
  width: 35px;
  height: 35px;
  text-indent: -9999px;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 20px;
}

.previousButton:hover,
.nextButton:hover {
  background: url("https://www.svgrepo.com/show/1024/right-arrow.svg") no-repeat
    center center / 18px;
  background-color: #ffffff;
  border-radius: 54px;
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
  left: 2px;
}

.nextButton {
  right: 0px;
}

.nextButton:hover {
  right: 2px;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 64px;
  line-height: 1;
}

.slider-content .inner p {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

@media (max-height: 500px) {
  .slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
} */

@media (max-width: 640px) {
  /* .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  } */
}
/* 
@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
} */

.j-scontainer {
  z-index: -8;
  background-color: #f0f0ef7d;
  width: 100%;
  padding-bottom: 40px;
  display: flex;
  flex-direction: row;
}
.j-sbox1 {
  padding-left: 120px;
  padding-bottom: 50px;
  height: auto;
}
.j-sbox1,
.j-sbox2 {
  width: 785px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.j-text2,
.j-text1 {
  margin-left: 0;
  font-family: serif;
  font-size: 5rem;
}
.j-text1 {
  color: rgb(30, 50, 56);
}
.j-text2 {
  color: rgb(250, 197, 0);
}
.j-sbox1-content {
  padding-top: 50px;
  font-family: serif;
  padding-right: 40px;
  font-size: 30px;
}
.j-text {
  position: relative;
  width: 100%;
  height: 20vh;
  display: flex;
  overflow: hidden;
}
.j-text-1 {
  color: rgb(250, 197, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  height: 100%;
}
.j-text .j-text-1:nth-child(1) {
  z-index: 5;
}
.j-text .j-text-1:nth-child(2) {
  z-index: 1;
  width: 175%;
  background: rgb(250, 197, 0);
  -webkit-clip-path: circle(60px at center);
          clip-path: circle(60px at center);
  color: transparent;
  -webkit-animation: animate 6.5s linear infinite;
          animation: animate 6.5s linear infinite;
}
.j-text .j-text-1 h3 {
  left: 0;
  font-size: 5em;
  white-space: nowrap;
  line-height: 20vh;
  cursor: default;
}
.j-text .j-text-1:nth-child(2) h3 {
  -webkit-text-stroke: 2px;
  -webkit-text-color: #fff;
}
@-webkit-keyframes animate {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  50% {
    background: rgb(250, 197, 0);
  }
  65% {
    background: rgb(250, 197, 0);
  }
  72% {
    background: rgb(30, 50, 56);
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    background: rgb(30, 50, 56);
    opacity: 0;
  }
}
@keyframes animate {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  50% {
    background: rgb(250, 197, 0);
  }
  65% {
    background: rgb(250, 197, 0);
  }
  72% {
    background: rgb(30, 50, 56);
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    background: rgb(30, 50, 56);
    opacity: 0;
  }
}
.j-sbox2 {
  height: 100vh;
}
.j-journal-img {
  position: relative;
  height: 450px;
  width: 320px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 15px;
}
.j-sbox2 {
  position: relative;
}
.j-sbox2::before {
  content: "";
  background: url(/static/media/magzine_cover.6bcb37d4.png) no-repeat center center / cover;
  position: absolute;
  top: 0;
  left: 20%;
  bottom: 0;
  right: 0;
  opacity: 0.7;
}
.j-sbox2-image {
  width: 42%;
  height: 450px;
  border-radius: 5px;
}
.j-sbox22 {
  display: none;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.j-sbox22 img {
  height: 400px;
  width: 280px;
  border-radius: 15px;
}
@media (max-width: 1315px) {
  .j-text1,
  .j-text2 {
    font-size: 4.5rem;
  }
  .j-sbox1-title {
    padding-left: 0px;
  }
  .j-sbox1-content {
    /* height: 50vh; */
    font-size: 27px;
  }
}
@media (max-width: 1165px) {
  .j-text1,
  .j-text2 {
    font-size: 4rem;
  }
}
@media (max-width: 1030px) {
  .j-scontainer {
    padding-bottom: 0;
    display: inline-flex;
    justify-content: center;
  }
  .j-sbox1-content {
    text-align: justify;
    font-size: 30px;
  }
  .j-sbox1-title {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .j-sbox1-title {
    padding-left: 180px;
  }
  .j-sbox1 {
    padding-bottom: 10px;
    padding-left: 0;
  }
  .j-sbox2 {
    display: none;
  }
  .j-sbox22 {
    display: flex;
    padding-top: 10px;
  }
  .j-sbox1-content {
    padding: 50px 60px;
    font-size: 27px;
  }
  .j-text .j-text-1:nth-child(2) {
    width: 130%;
  }
}
@media (max-width: 772px) {
  .j-sbox1-title {
    padding-left: 150px;
  }
  .j-sbox1-content {
    font-size: 25px;
    /* height: 60vh; */
    padding-bottom: 20px;
  }
}

@media (max-width: 660px) {
  .j-text1,
  .j-text2 {
    font-size: 3.5rem;
  }
  .j-sbox1-title {
    padding-left: 100px;
  }
  .j-text .j-text-1:nth-child(2) {
    -webkit-clip-path: circle(45px at center);
            clip-path: circle(45px at center);
    height: 115%;
  }
  .j-sbox1-content {
    font-size: 20px;
    /* height: 220px; */
    /* margin-bottom: 40px; */
  }
}
@media (max-width: 570px) {
  .j-text .j-text-1:nth-child(2) {
    width: 150%;
  }
  .j-text1,
  .j-text2 {
    font-size: 3.2rem;
  }
  .j-sbox1-title {
    padding-left: 80px;
  }
  .j-sbox22 img {
    height: 300px;
    width: 210px;
    border-radius: 15px;
  }
  .j-sbox1-content {
    font-size: 16px;
    /* padding: 30px 40px; */
    /* height: 50vh; */
    margin-bottom: 0;
  }
}
@media (max-width: 436px) {
  .j-text1,
  .j-text2 {
    font-size: 2.3rem;
  }
  .j-sbox1-title {
    padding-left: 80px;
  }
  .j-sbox1-content {
    /* height: 60vh; */
    font-size: 14px;
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .j-sbox22 {
    padding-bottom: 20px;
  }
  .j-sbox22 img {
    height: 270px;
    width: 180px;
    border-radius: 15px;
  }

  .j-text .j-text-1:nth-child(2) {
    -webkit-clip-path: circle(40px at center);
            clip-path: circle(40px at center);
    height: 125%;
  }
}
@media (max-width: 376px) {
  .j-scontainer {
    padding-top: 0px;
  }
  .j-sbox1-title {
    padding-left: 40px;
  }
  .j-sbox22 img {
    height: 180px;
    width: 120px;
  }
  .j-sbox1-content {
    /* padding: 30px 30px; */
    font-size: 14px;
  }
  .j-sbox22 {
    height: 180px;
  }
}
@media (max-width: 320px) {
  .j-scontainer {
    padding-top: 0px;
  }
  /* .j-sbox1-content {
    height: 70vh;
  } */
}
/* @media screen and (min-width: 910px) and (max-width: 911px) {
  .j-sbox1-content {
    font-size: 28px;
    height: 60vh;
  }
}
@media screen and (min-width: 911px) and (max-width: 912px) {
  .j-sbox1-content {
    font-size: 35px;
    height: 78vh;
  }
}
@media screen and (min-width: 817px) and (max-width: 819px) {
  .j-sbox1-content {
    font-size: 35px;
    height: 80vh;
  }
}
@media screen and (min-width: 819px) and (max-width: 820px) {
  .j-sbox1-content {
    font-size: 37px;
    height: 60vh;
  }
}
@media screen and (min-width: 769px) and (max-width: 772px) {
  .j-sbox1-content {
    font-size: 30px;
    height: 70vh;
  }
}
@media screen and (min-width: 767px) and (max-width: 768px) {
  .j-sbox1-content {
    font-size: 30px;
    height: 65vh;
  }
}
@media screen and (min-width: 570px) and (max-width: 585px) {
  .j-sbox1-content {
    height: 72vh;
  }
}
@media screen and (min-width: 376px) and (max-width: 409px) {
  .j-sbox1-content {
    height: 78vh;
  }
} */

.jc-outerdiv {
  padding-bottom: 70px;
}

.jc-container {
  width: 80vw;
  height: 35vh;
  margin: 0 auto;
  border-radius: 2em;
  font-family: Roboto, Arial;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    -45deg,
    #405de6,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d
  );
  background-size: 400% 400%;
  -webkit-animation: gradient 10s ease infinite;
          animation: gradient 10s ease infinite;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.jc-heading {
  height: 14vh;
  font-size: 40px;
  font-weight: 600;
  color: #161616;
  /* border: 2px solid black; */
  /* color: #fff;
  text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #0fa,
    0 0 82px #0fa,
    0 0 92px #0fa,
    0 0 102px #0fa,
    0 0 151px #0fa; */
}
.jc-heading-edition {
  text-align: right;
  font-size: 20px;
  width: 66vh;
  color: white;
  margin-top: -50px;
}
.jc-release {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin: 5px 0 15px 0;
}
.jc-release hr {
  width: 80%;
  margin: 0 12px;
}
.jc-release-date {
  text-align: center;
  color: white;
  width: 80vh;
  font-size: 20px;
}
.dribbble {
  position: fixed;
  display: block;
  right: 24px;
  bottom: 24px;
}
.dribbble img {
  display: block;
  width: 76px;
}
@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.upload {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  position: relative;
  background-color: #161616;
  border: 2px solid #08090a;
  cursor: pointer;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.upload .path {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  margin: -36px 0 0 -36px;
  width: 72px;
  height: 72px;
}
.upload .path .arrow {
  bottom: 27px;
  width: 2px;
  height: 21px;
  position: absolute;
  left: 50%;
  border-radius: 1px;
  margin: 0 0 0 -1px;
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
}

.upload .path .arrow:before,
.upload .path .arrow:after {
  content: "";
  display: block;
  width: 2px;
  height: 14px;
  bottom: -2px;
  background: #fff;
  position: absolute;
  border-radius: 1px;
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
}
.upload .path .arrow:before {
  right: 50%;
  -webkit-transform: rotate(-44deg) translateX(2px);
          transform: rotate(-44deg) translateX(2px);
}
.upload .path .arrow:after {
  left: 50%;
  -webkit-transform: rotate(44deg) translateX(-2px);
          transform: rotate(44deg) translateX(-2px);
}
.upload .path svg {
  width: 72px;
  height: 72px;
  display: block;
  fill: none;
  stroke: #fff;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-dashoffset: 592.73;
  stroke-dasharray: 0 592.73 20 592.73;
}

.upload .circle {
  position: absolute;
  width: 58px;
  height: 58px;
  margin: -29px 0 0 -29px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  overflow: hidden;
}
.upload .circle .water {
  -webkit-transform: translateY(116%);
          transform: translateY(116%);
  background: #ffaa33;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.upload .circle .water svg {
  display: block;
  width: 116px;
  height: 6px;
  position: absolute;
  bottom: 100%;
}
.upload .circle .water svg:nth-child(1) {
  right: 0;
  fill: #fff01f;
  -webkit-animation: forward 1.65s infinite;
          animation: forward 1.65s infinite;
}
.upload .circle .water svg:nth-child(2) {
  left: 0;
  fill: #fec701;
  margin-bottom: -1px;
  -webkit-animation: backward 0.825s infinite linear;
          animation: backward 0.825s infinite linear;
}

.upload.loading .path {
  -webkit-animation: movePath 0.4s linear forwards;
          animation: movePath 0.4s linear forwards;
}
.upload.loading .path .arrow {
  -webkit-animation: arrow 0.5s linear forwards 3.7s;
          animation: arrow 0.5s linear forwards 3.7s;
}
.upload.loading .path .arrow:before {
  -webkit-animation: arrowB 0.4s linear forwards, arrowBCheck 0.5s linear forwards 3.7s;
          animation: arrowB 0.4s linear forwards, arrowBCheck 0.5s linear forwards 3.7s;
}
.upload.loading .path .arrow:after {
  -webkit-animation: arrowA 0.4s linear forwards, arrowACheck 0.5s linear forwards 3.7s;
          animation: arrowA 0.4s linear forwards, arrowACheck 0.5s linear forwards 3.7s;
}
.upload.loading .path svg {
  -webkit-animation: load 3s linear forwards 0.45s, reset 0.7s linear forwards 3.7s;
          animation: load 3s linear forwards 0.45s, reset 0.7s linear forwards 3.7s;
}
.upload.loading .circle .water {
  -webkit-animation: fill 3s linear forwards 0.45s;
          animation: fill 3s linear forwards 0.45s;
}
.upload:active {
  -webkit-transform: scale(0.92) translateZ(0);
          transform: scale(0.92) translateZ(0);
}
@-webkit-keyframes load {
  0% {
    stroke-dashoffset: 592.73;
    stroke-dasharray: 0 592.73 20 592.73;
  }
  42% {
    stroke-dasharray: 0 592.73 80 592.73;
  }
  85% {
    stroke-dashoffset: 80;
    stroke-dasharray: 0 592.73 32 592.73;
  }
  100% {
    stroke-dashoffset: 32;
    stroke-dasharray: 0 592.73 32 592.73;
  }
}
@keyframes load {
  0% {
    stroke-dashoffset: 592.73;
    stroke-dasharray: 0 592.73 20 592.73;
  }
  42% {
    stroke-dasharray: 0 592.73 80 592.73;
  }
  85% {
    stroke-dashoffset: 80;
    stroke-dasharray: 0 592.73 32 592.73;
  }
  100% {
    stroke-dashoffset: 32;
    stroke-dasharray: 0 592.73 32 592.73;
  }
}
@-webkit-keyframes reset {
  0%,
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes reset {
  0%,
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes movePath {
  70% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}
@keyframes movePath {
  70% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}
@-webkit-keyframes arrow {
  0% {
    background: #fff;
    -webkit-transform: rotate(34deg) translate(-2.5px, 2px);
            transform: rotate(34deg) translate(-2.5px, 2px);
  }
  40% {
    -webkit-transform: rotate(-7deg) translate(0, 0);
            transform: rotate(-7deg) translate(0, 0);
  }
  99% {
    -webkit-transform: rotate(0) translate(0, 0);
            transform: rotate(0) translate(0, 0);
  }
  100% {
    background: #fff;
  }
}
@keyframes arrow {
  0% {
    background: #fff;
    -webkit-transform: rotate(34deg) translate(-2.5px, 2px);
            transform: rotate(34deg) translate(-2.5px, 2px);
  }
  40% {
    -webkit-transform: rotate(-7deg) translate(0, 0);
            transform: rotate(-7deg) translate(0, 0);
  }
  99% {
    -webkit-transform: rotate(0) translate(0, 0);
            transform: rotate(0) translate(0, 0);
  }
  100% {
    background: #fff;
  }
}
@-webkit-keyframes arrowBCheck {
  0%,
  20% {
    -webkit-transform: rotate(-86deg) translateX(2px) translateY(1px) scaleY(0.714);
            transform: rotate(-86deg) translateX(2px) translateY(1px) scaleY(0.714);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-44deg) translateX(2px) scaleY(1) translateY(0);
            transform: rotate(-44deg) translateX(2px) scaleY(1) translateY(0);
    opacity: 1;
  }
}
@keyframes arrowBCheck {
  0%,
  20% {
    -webkit-transform: rotate(-86deg) translateX(2px) translateY(1px) scaleY(0.714);
            transform: rotate(-86deg) translateX(2px) translateY(1px) scaleY(0.714);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-44deg) translateX(2px) scaleY(1) translateY(0);
            transform: rotate(-44deg) translateX(2px) scaleY(1) translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes arrowACheck {
  0% {
    -webkit-transform: rotate(0deg) translate(-1px, -1px);
            transform: rotate(0deg) translate(-1px, -1px);
    opacity: 1;
  }
  40% {
    -webkit-transform: rotate(60deg) translate(-2px, 1px);
            transform: rotate(60deg) translate(-2px, 1px);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(44deg) translateX(-2px);
            transform: rotate(44deg) translateX(-2px);
    opacity: 1;
  }
}
@keyframes arrowACheck {
  0% {
    -webkit-transform: rotate(0deg) translate(-1px, -1px);
            transform: rotate(0deg) translate(-1px, -1px);
    opacity: 1;
  }
  40% {
    -webkit-transform: rotate(60deg) translate(-2px, 1px);
            transform: rotate(60deg) translate(-2px, 1px);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(44deg) translateX(-2px);
            transform: rotate(44deg) translateX(-2px);
    opacity: 1;
  }
}
@-webkit-keyframes arrowB {
  0% {
    -webkit-transform: rotate(-44deg) translateX(2px);
            transform: rotate(-44deg) translateX(2px);
  }
  60% {
    -webkit-transform: rotate(-60deg) translateX(2px);
            transform: rotate(-60deg) translateX(2px);
  }
  99% {
    -webkit-transform: rotate(0deg) translateX(1px);
            transform: rotate(0deg) translateX(1px);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(0deg) translateX(1px);
            transform: rotate(0deg) translateX(1px);
    opacity: 0;
  }
}
@keyframes arrowB {
  0% {
    -webkit-transform: rotate(-44deg) translateX(2px);
            transform: rotate(-44deg) translateX(2px);
  }
  60% {
    -webkit-transform: rotate(-60deg) translateX(2px);
            transform: rotate(-60deg) translateX(2px);
  }
  99% {
    -webkit-transform: rotate(0deg) translateX(1px);
            transform: rotate(0deg) translateX(1px);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(0deg) translateX(1px);
            transform: rotate(0deg) translateX(1px);
    opacity: 0;
  }
}
@-webkit-keyframes arrowA {
  0% {
    -webkit-transform: rotate(44deg) translateX(-2px);
            transform: rotate(44deg) translateX(-2px);
  }
  60% {
    -webkit-transform: rotate(60deg) translateX(-2px);
            transform: rotate(60deg) translateX(-2px);
  }
  99% {
    -webkit-transform: rotate(0deg) translateX(-1px);
            transform: rotate(0deg) translateX(-1px);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(0deg) translateX(-1px);
            transform: rotate(0deg) translateX(-1px);
    opacity: 0;
  }
}
@keyframes arrowA {
  0% {
    -webkit-transform: rotate(44deg) translateX(-2px);
            transform: rotate(44deg) translateX(-2px);
  }
  60% {
    -webkit-transform: rotate(60deg) translateX(-2px);
            transform: rotate(60deg) translateX(-2px);
  }
  99% {
    -webkit-transform: rotate(0deg) translateX(-1px);
            transform: rotate(0deg) translateX(-1px);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(0deg) translateX(-1px);
            transform: rotate(0deg) translateX(-1px);
    opacity: 0;
  }
}
@-webkit-keyframes fill {
  0% {
    -webkit-transform: translateY(116%);
            transform: translateY(116%);
  }
  80% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes fill {
  0% {
    -webkit-transform: translateY(116%);
            transform: translateY(116%);
  }
  80% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes backward {
  100% {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
@keyframes backward {
  100% {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
@-webkit-keyframes forward {
  100% {
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
}
@keyframes forward {
  100% {
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
}
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

/* @media only screen and (max-width : 670px) {
    .jc-release hr{
        width: 80%;
        margin: 0 -50px;
    }
} */
@media screen and (max-width: 850px) {
  .jc-release hr {
    width: 40%;
    margin: 0 -30px;
  }
}
@media screen and (max-width: 700px) {
  .jc-release hr {
    width: 30%;
    margin: 0 -80px;
  }
}

@media screen and (max-width: 525px) {
  .jc-container {
    height: 43vh;
  }
  .jc-release hr {
    width: 0%;
    margin: 0;
  }
  .jc-heading-edition {
    width: auto;
    text-align: center;
    margin: -30px -150px 0 0;
  }
  .jc-release-date {
    width: auto;
  }
}
@media screen and (max-width: 300px) {
  .jc-heading {
    font-size: 28px;
  }
  .jc-release-date {
    font-size: 16px;
  }
}

* {
  margin: 0;
  padding: 0;
  font-family: "poppins", sans-serif;
  box-sizing: border-box;
}

footer {
  width: 100%;
  position: relative;
  bottom: 0;
  background: #161616;
  color: #fff;
  padding: 30px 0 10px;
  font-size: 13px;
  line-height: 18px;
}
.row {
  width: 85%;
  margin: 0 10%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.col {
  flex-basis: 25%;
  margin-bottom: 5px;
  padding: 10px;
  text-align: left;
}
.col:nth-child(3) {
  flex-basis: 20%;
}
.col:nth-child(4) {
  flex-basis: 25%;
}
.footer-logo {
  width: 160px;
  margin-bottom: 30px;
}
.col h3 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 20px;
  color: #f5c656;
  position: relative;
}
.phone_text {
  color: #ccc;
}
.email-id {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-decoration: none;
  margin: 20px 0;
}
ul li {
  list-style: none;
  margin-bottom: 10px;
}
ul li a {
  text-decoration: none;
  color: #fff;
}
.quicklinks {
  margin-bottom: 20px;
  cursor: pointer;
}
.quicklinks i {
  font-size: 14px;
  margin-right: 10px;
}
.quicklink_text {
  display: inline-block;
  font-size: 16px;
}
.footer_text {
  font-size: 10px;
}

.quicklinks:hover {
  /* border-bottom: 1px solid #ccc; */
  color: #f5c656;
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
}
.quicklink_text:hover {
  color: #f5c656;
}
.far {
  font-size: 18px;
  margin-right: 10px;
}
.input {
  width: 10%;
  background: transparent;
  color: #ccc;
  border: 0;
  outline: none;
}
.button {
  background: transparent;
  border: 0px;
  outline: none;
  cursor: pointer;
}
.fas {
  font: size 16px;
  color: #f5c656;
}
.social-icons {
  margin-top: 20px;
}
.social-icons .fa {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  color: #000;
  background: #fff;
  margin-right: 15px;
  cursor: pointer;
}

.social-icons li {
  display: inline-block;
  margin: 6px 6px;
  margin-left: 0;
}
hr {
  width: 90%;
  border: 0;
  border-bottom: 1.5px solid #ccc;
  margin: 20px auto;
  margin-top: 30px;
}
.copyright {
  text-align: center;
  color: #f5c656;
}
.underline {
  width: 100%;
  height: 5px;
  background: #767676;
  border-radius: 3px;
  position: absolute;
  top: 25px;
  left: 0;
  overflow: hidden;
}
.underline span {
  width: 15px;
  height: 100%;
  background: #fff;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 10px;
  -webkit-animation: moving 2s linear infinite;
          animation: moving 2s linear infinite;
}
@-webkit-keyframes moving {
  0% {
    left: -20px;
  }
  100% {
    left: 100%;
  }
}
@keyframes moving {
  0% {
    left: -20px;
  }
  100% {
    left: 100%;
  }
}
@media (max-width: 700px) {
  footer {
    bottom: unset;
  }
  .col {
    flex-basis: 100%;
  }
  .col:nth-child(3),
  .col:nth-child(4) {
    flex-basis: 100%;
  }

  .row {
    align-items: flex-end;
  }

  .row .col:nth-child(1) p,
  .row .col:nth-child(1) h3 {
    display: none;
  }

  .row .col:nth-child(3) ul,
  .row .col:nth-child(3) h3 {
    display: none;
  }
}

.fa-facebook:hover {
  background-color: #4867aa;
  color: white;
}
.fa-instagram:hover {
  background-image: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  color: white;
}
.fa-youtube:hover {
  background-color: #e64a41;
  color: white;
}
.fa-linkedin:hover {
  background-color: #0073a4;
  color: white;
}
.fa-twitter:hover {
  background-color: #00acee;
  color: white;
}
.fa-discord:hover {
  background-color: #738adb;
  color: white;
}
.email-id a {
  color: #fff;
}
@media screen and (max-width: 850px) and (min-width: 701px) {
  .col {
    flex-basis: 50%;
  }
  .col:nth-child(4),
  .col:nth-child(3) {
    flex-basis: 50%;
  }
}

@media screen and (min-width: 700px) and (max-width: 772px) {
  .underline {
    position: absolute;
    top: 40px;
  }
}
@media screen and (min-width: 701px) and (max-width: 1066px) {
  .row {
    margin-left: 10%;
  }
}
@media (max-width: 282px) {
  .social-icons .fa {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
}
@media (max-width: 960px) {
  .social-icons br {
    display: none;
  }
}

@media (max-width: 850px) {
  .social-icons br {
    display: block;
  }
}

.c-outer {
  margin: 12px;
}
.c-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5c656;
  overflow: hidden;
  animation: fadeIn ease 4s;
  -webkit-animation: fadeIn ease 4s;
  -moz-animation: fadeIn ease 4s;
  -o-animation: fadeIn ease 4s;
  -ms-animation: fadeIn ease 4s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.c-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 63%;
  height: 100%;
  background: #3c4043;
}
.c-container {
  position: relative;
  min-width: 1100px;
  min-height: 550px;
  display: flex;
  z-index: 2;
  /* background-color: #ffff; */
}
.c-container .c-contactinfo {
  border-radius: 3px;
  color: #3c4043;
  position: absolute;
  top: 40px;
  width: 350px;
  height: calc(100% - 80px);
  background: #f5c656;
  z-index: 1;
  padding: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: space-between;
  /* box-shadow:0 20px 25px rgba(0,0,0,.15); */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.c-container .c-contactinfo h2,
.c-container .c-contactform h2 {
  color: #3c4043;
  font-size: 27px;
  font-weight: 700;
  font-family: "PT Serif", serif;
}
.c-container .c-contactinfo ul.c-info {
  position: relative;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.c-container .c-contactinfo ul.c-info li {
  position: relative;
  list-style: none;
  display: flex;
  margin: 20px 0;
  /* cursor: pointer; */
  /* pointer-events: none; */
  align-items: flex-start;
}
.c-container .c-contactinfo ul.c-info li span:nth-child(1) {
  width: 30px;
  min-width: 30px;
  margin-left: 0;
}
.c-container .c-contactinfo ul.c-info li span:nth-child(1) div {
  color: #3c4043;
  max-width: 100%;
  /* cursor: pointer; */
  /* filter: invert(1); */
}
.fas {
  /* color: #3c4043; */
  font-size: 30px;
}
.c-container .c-contactinfo ul.c-info li span:nth-child(2) {
  font-size: 17px;
  color: #3c4043;
  margin-left: 10px;
  font-weight: 500;
  font-family: "PT Serif", serif;
}
.c-container .c-contactinfo ul.c-sci {
  /* color: #3c4043; */
  position: relative;
  display: flex;
  justify-content: space-evenly;
}
.c-container .c-contactinfo ul.c-sci li {
  list-style: none;
  margin-right: 15px;
}
.c-container .c-contactinfo ul.c-sci li a {
  text-decoration: none;
  /* color: #3c4043; */
}
.c-twitter,
.c-fb,
.c-insta,
.c-link,
.c-pin,
.c-discord {
  padding-top: 5px;
  color: #3c4043;
}
.c-twitter:hover {
  color: #1da1f2;
}
.c-fb:hover {
  color: #4267b2;
}
.c-pin:hover {
  color: #e60023;
}
.c-link:hover {
  color: #0e76a8;
}
.c-insta:hover {
  color: #c13584;
}
.c-discord:hover {
  color: #738adb;
}
.fab {
  padding-top: 5px;
  font-size: 30px;
  /* color: #3c4043; */
}
.c-container .c-contactform {
  border-radius: 3px;
  position: absolute;
  padding: 40px 50px;
  padding-left: 250px;
  margin-left: 150px;
  width: calc(100% - 150px);
  height: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 50px 50px rgba(0, 0, 0, 0.25);
}
/* .c-container .c-contactform h2{
    color:#3c4043;
    font-size: 24px;
    font-weight: 500;
  } */
.c-container .c-contactform .c-formbox {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* padding-top: 30px; */
}
.c-container .c-contactform .c-formbox .c-inputbox {
  position: relative;
  margin-bottom: 42px;
}
.c-container .c-contactform .c-formbox .c-inputbox.w50 {
  width: 47%;
  font-family: "PT Serif", serif;
}
.c-container .c-contactform .c-formbox .c-inputbox.w100 {
  width: 100%;
  font-family: "PT Serif", serif;
}
.c-container .c-contactform .c-formbox .c-inputbox input {
  width: 100%;
  resize: none;
  padding: 5px 0;
  font-size: 18px;
  font-weight: 300;
  color: #333;
  border: none;
  outline: none;
  border-bottom: 1px solid #777;
  font-family: "PT Serif", serif;
}
.c-container .c-contactform .c-formbox .c-inputbox textarea {
  /* padding:55px; */
  width: 100%;
  resize: none;
  padding: 5px;
  font-size: 18px;
  font-weight: 300;
  color: #333;
  outline: none;
  height: 120px;
  border: 1px solid #777;
}
.c-container .c-contactform .c-formbox .c-inputbox span {
  position: absolute;
  left: -25px;
  padding: 5px 0;
  pointer-events: none;
  font-size: 18px;
  font-weight: 300;
  transition: 0.3s;
  font-family: "PT Serif", serif;
}
.c-container .c-contactform .c-formbox .c-inputbox input:focus ~ span,
.c-container .c-contactform .c-formbox .c-inputbox input:valid ~ span {
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #3c4043;
  font-weight: 500;
}
/* placeholder{
    margin-left: 1%;
    transform: translateY(-20px);
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 1px;
    color:#095a55;
    font-weight: 500;
  } */
.c-container .c-contactform .c-formbox .c-inputbox input[type="submit"] {
  border-radius: 3px;
  font-weight: 500;
  position: relative;
  cursor: pointer;
  background: #3c4043;
  color: #fff;
  border: None;
  max-width: 150px;
  padding: 12px;
}
.c-container .c-contactform .c-formbox .c-inputbox input[type="submit"]:hover {
  background: #f5c656;
  color: #3c4043;
}
.c-received {
  /* padding: 3px 5px; */
  padding-left: 5px;
  color: #3c4043;
  font-size: 20px;
  background-color: #f5c656;
  border-radius: 5px;
}
.c-receivepara {
  margin-bottom: 0;
}
@media (max-width: 1200px) {
  .c-container {
    width: 90%;
    min-width: auto;
    margin: 20px;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
  }
  .c-container .c-contactinfo {
    top: 0;
    height: 550px;
    position: relative;
    box-shadow: none;
  }
  .c-contactinfo .c-contactform {
    position: relative;
    width: calc(100% - 350px);
    padding-left: 0;
    margin-left: 0;
    padding: 40px;
    height: 550px;
    box-shadow: none;
  }
}
@media (max-width: 991px) {
  .c-section {
    background: #fff38e;
    min-height: 75vh;
    padding-top: 55px;
  }
  .c-section::before {
    display: none;
  }
  .c-container {
    box-shadow: none;
    display: flex;
    flex-direction: column-reverse;
  }
  .c-container .c-contactform {
    margin-left: 0;
    /* padding-left: ; */
    padding: 40px 50px;
    width: 100%;
    height: 540px;
  }
  .c-container .c-contactinfo {
    display: none;
    width: 100%;
    height: auto;
    flex-direction: row;
  }
  .c-container .c-contactinfo ul.c-sci {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .c-container .c-contactform .c-formbox .c-inputbox.cbtn {
    margin-bottom: 0;
  }
  .c-contactinfo {
    display: none;
  }
}

@media (max-width: 600px) {
  .c-container .c-contactform {
    padding: 25px;
    height: 520px;
  }
  .c-container .c-contactinfo {
    padding: 25px;
    flex-direction: column;
    align-items: flex-start;
  }
  .c-container .c-contactinfo ul.c-sci {
    width: 100%;
    justify-content: space-around;
  }
  .c-section {
    padding-top: 40px;
  }
}
@media (max-width: 500px) {
  .c-section {
    min-height: 90vh;
  }
  .c-section {
    padding-top: 200px;
  }
  .c-container .c-contactform {
    height: 670px;
  }
  .c-container .c-contactform .c-formbox .c-inputbox input:focus ~ span,
  .c-container .c-contactform .c-formbox .c-inputbox textarea:focus ~ span,
  .c-container .c-contactform .c-formbox .c-inputbox input:valid ~ span,
  .c-container .c-contactform .c-formbox .c-inputbox textarea:valid ~ span {
    font-size: 15px;
  }
  .c-container .c-contactform .c-formbox .c-inputbox.w50 {
    width: 100%;
  }
}
@media (max-width: 350px) {
  .c-container .c-contactinfo ul.c-sci {
    justify-content: center;
  }
  .c-container .c-contactinfo {
    padding: 10px;
  }
  .c-container .c-contactinfo ul.c-info li span:nth-child(2) {
    font-size: 15px;
  }
  .c-received {
    font-size: 15px;
  }
  .c-container .c-contactform h2 {
    font-size: 22.9px;
  }
}
@media (max-width: 292px) {
  .c-container .c-contactinfo {
    padding: 10px;
  }
  .c-container .c-contactform h2 {
    font-size: 21px;
  }
}

body {
  background: #161616;
}
.loader {
  /* filter: blur(2px); */
  /* background: rgb(255, 255, 255); */
  background: url(/static/media/load.be060c71.gif) no-repeat center / cover;
  height: 100vh;
}
/* https://i.gifer.com/7Gt8.gif */
/* https://i.gifer.com/my9.gif */
/* https://i.gifer.com/4Cb2.gif */
/* https://i.gifer.com/V5y.gif */

/* .loader::before {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(transparent),
    to(rgba(0, 0, 0, 0.9))
  );
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
  z-index: 2;
} */
.loader-head {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.loader-head p {
  font-size: 4.5rem;
  margin-bottom: 10px;
  color: white;
  font-family: PT-serif;
  transition: 1s;
}
.loader-img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  /* background-image: url("lightblue_darkbg.png"); */
  /* background-color: red; */
  /* margin-top: 20vh; */
}

.loader-img img {
  width: 30%;
  margin-top: 20px;
  animation: fadeIn ease 4s;
  -webkit-animation: fadeIn ease 4s;
  -moz-animation: fadeIn ease 4s;
  -o-animation: fadeIn ease 4s;
  -ms-animation: fadeIn ease 4s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.loader-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-progress {
  /* height: 30px; */
  width: 25%;
  /* border: 5px solid #f3b833; */
  background: #9ad0ec;
  /* border-radius: 10px; */
}
.loading-progress .loading-color {
  background-color: #1572a1;
  width: 100%;
  height: 5px;
  border-radius: 0px;
  -webkit-animation: progres 3.4s linear;
          animation: progres 3.4s linear;
}
@-webkit-keyframes progres {
  0% {
    width: 0%;
  }
  10% {
    width: 0%;
  }
  25% {
    width: 25%;
  }
  50% {
    width: 50%;
  }
  75% {
    width: 75%;
  }
  100% {
    width: 100%;
  }
}
@keyframes progres {
  0% {
    width: 0%;
  }
  10% {
    width: 0%;
  }
  25% {
    width: 25%;
  }
  50% {
    width: 50%;
  }
  75% {
    width: 75%;
  }
  100% {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .loader-img img {
    margin-top: 60px;
    width: 35%;
  }
  .loader-head p {
    font-size: 4rem;
  }
  .loading-progress {
    width: 30%;
  }
}

@media (max-width: 800px) {
  .loader-img img {
    margin-top: 70px;
    width: 40%;
  }
  .loader-head p {
    font-size: 3rem;
  }
  .loading-progress {
    width: 30%;
  }
}

@media (max-width: 600px) {
  .loader-img {
    height: 350px;
  }
  .loader-img img {
    margin-top: 80px;
    width: 50%;
  }
  .loader-head p {
    font-size: 2rem;
  }
  .loading-progress {
    width: 35%;
  }
}
@media (max-width: 600px) {
  .loader-img img {
    margin-top: 110px;
  }
}
@media (max-width: 400px) {
  .loader-img img {
    margin-top: 130px;
    width: 60%;
  }
  .loader-head p {
    font-size: 2rem;
  }
  .loading-progress {
    width: 35%;
  }
}

@media (max-width: 350px) {
  .loader-img img {
    width: 60%;
  }
  .loader-head p {
    font-size: 1.5rem;
  }
  .loading-progress {
    width: 35%;
  }
}

