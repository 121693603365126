body {
  background: #161616;
}
.loader {
  /* filter: blur(2px); */
  /* background: rgb(255, 255, 255); */
  background: url(load.gif) no-repeat center / cover;
  height: 100vh;
}
/* https://i.gifer.com/7Gt8.gif */
/* https://i.gifer.com/my9.gif */
/* https://i.gifer.com/4Cb2.gif */
/* https://i.gifer.com/V5y.gif */

/* .loader::before {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(transparent),
    to(rgba(0, 0, 0, 0.9))
  );
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
  z-index: 2;
} */
.loader-head {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.loader-head p {
  font-size: 4.5rem;
  margin-bottom: 10px;
  color: white;
  font-family: PT-serif;
  transition: 1s;
}
.loader-img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  /* background-image: url("lightblue_darkbg.png"); */
  /* background-color: red; */
  /* margin-top: 20vh; */
}

.loader-img img {
  width: 30%;
  margin-top: 20px;
  animation: fadeIn ease 4s;
  -webkit-animation: fadeIn ease 4s;
  -moz-animation: fadeIn ease 4s;
  -o-animation: fadeIn ease 4s;
  -ms-animation: fadeIn ease 4s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.loader-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-progress {
  /* height: 30px; */
  width: 25%;
  /* border: 5px solid #f3b833; */
  background: #9ad0ec;
  /* border-radius: 10px; */
}
.loading-progress .loading-color {
  background-color: #1572a1;
  width: 100%;
  height: 5px;
  border-radius: 0px;
  animation: progres 3.4s linear;
}
@keyframes progres {
  0% {
    width: 0%;
  }
  10% {
    width: 0%;
  }
  25% {
    width: 25%;
  }
  50% {
    width: 50%;
  }
  75% {
    width: 75%;
  }
  100% {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .loader-img img {
    margin-top: 60px;
    width: 35%;
  }
  .loader-head p {
    font-size: 4rem;
  }
  .loading-progress {
    width: 30%;
  }
}

@media (max-width: 800px) {
  .loader-img img {
    margin-top: 70px;
    width: 40%;
  }
  .loader-head p {
    font-size: 3rem;
  }
  .loading-progress {
    width: 30%;
  }
}

@media (max-width: 600px) {
  .loader-img {
    height: 350px;
  }
  .loader-img img {
    margin-top: 80px;
    width: 50%;
  }
  .loader-head p {
    font-size: 2rem;
  }
  .loading-progress {
    width: 35%;
  }
}
@media (max-width: 600px) {
  .loader-img img {
    margin-top: 110px;
  }
}
@media (max-width: 400px) {
  .loader-img img {
    margin-top: 130px;
    width: 60%;
  }
  .loader-head p {
    font-size: 2rem;
  }
  .loading-progress {
    width: 35%;
  }
}

@media (max-width: 350px) {
  .loader-img img {
    width: 60%;
  }
  .loader-head p {
    font-size: 1.5rem;
  }
  .loading-progress {
    width: 35%;
  }
}
