.Video {
  padding-top: 2rem;
  box-sizing: content-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}
.containerVideo {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 900px;
  user-select: none;
  cursor: pointer;
}
.containerVideo > .boxVideo {
  position: relative;
  width: 450px;
  height: 500px;
  margin-left: 30px;
  margin-right: 30px;
  background: #000;
}
.containerVideo > .boxVideo > .imgBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.containerVideo > .boxVideo > .imgBox > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.containerVideo > .boxVideo > .contentBox {
  position: absolute;
  top: 120px;
  bottom: 120px;
  right: 40px;
  width: 100%;
  height: 60%;
  padding: 0px 0px;
  background: rgba(14, 14, 14, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s;
  transform-origin: right;
  transform: scaleX(0);
}
.containerVideo > .boxVideo:hover > .contentBox {
  transition: transform 0.5s;
  transform-origin: left;
  border-radius: 0px 10px 10px 0px;
  box-shadow: 2px 2.5px 50px rgba(245, 245, 245, 0.322);
  transform: scaleX(1);
  background: blur(2px);
}
.containerVideo > .boxVideo > .imgBox > img {
  transition: 0.5s;
  transition-delay: 0.2s;

  filter: grayscale(0);
}
.containerVideo > .boxVideo:hover > .imgBox > img {
  transform: scale(1.1);
  filter: grayscale(0);
  border-radius: 10px;
  box-shadow: 10px 5px 50px black;
}

.containerVideo > .boxVideo > .contentBox > .content {
  font-size: 1.2em;
  position: relative;
  padding: 20px 60px;
  opacity: 0;
}
.containerVideo > .boxVideo:hover > .contentBox > .content {
  opacity: 1;
  transition: 0.5s;
  transition-delay: 0.5s;
}
.containerVideo > .boxVideo > .contentBox > .content > h2 {
  color: #fff;
  font-weight: 500;
  margin-bottom: 10px;
  font-family: "PT Serif", serif;
  font-weight: 600;
}
.containerVideo > .boxVideo > .contentBox > .content > p {
  color: #fff;
  font-weight: 300;
  font-family: "PT Serif", serif;
}

@media only screen and (max-width: 1000px) {
  .Video {
    padding-top: 0;
  }
  .containerVideo {
    flex-direction: column;
    justify-content: space-between;
  }
  .boxVideo {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .containerVideo > .boxVideo {
    position: relative;
    width: 450px;
    height: 540px;
    background: #000;
  }

  .containerVideo > .boxVideo > .imgBox {
    width: 100%;
    height: 100%;
  }
  .containerVideo > .boxVideo > .contentBox > .content {
    font-size: 1.8em;
  }
  .containerVideo > .boxVideo > .contentBox {
    font-size: 10px;
    position: absolute;
    top: 100px;
    bottom: 120px;
    right: 90px;
    width: 105%;
    height: 60%;
  }
}
@media only screen and (max-width: 680px) {
  .containerVideo > .boxVideo {
    position: relative;
    width: 300px;
    height: 380px;
    background: #000;
  }
  .containerVideo > .boxVideo > .imgBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .containerVideo > .boxVideo > .imgBox > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .containerVideo > .boxVideo > .contentBox {
    font-size: 100px;
    position: absolute;
    top: 70px;
    bottom: 120px;
    right: 60px;
    width: 105%;
    height: 60%;
  }
  .containerVideo > .boxVideo > .contentBox > .content {
    position: relative;
    padding: 10px 30px;
    opacity: 0;
  }
  .containerVideo > .boxVideo > .contentBox > .content {
    font-size: 10px;
  }
}

@media only screen and (max-width: 450px) {
  .Video {
    /* padding-left: 60px;
    padding-right: 60px; */
    width: 100%;
    font-size: 10px;
  }
  .containerVideo > .boxVideo > .contentBox {
    font-size: 8px;
    position: absolute;
    top: 60px;
    bottom: 120px;
    right: 30px;
    width: 105%;
    height: 60%;
    /* margin-left: 50px; */
  }

  .containerVideo > .boxVideo {
    position: relative;
    width: 250px;
    height: 320px;
    background: #000;
  }
  .containerVideo > .boxVideo > .imgBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
