.res_button-effect {
  padding: 1px 10px;
}
.res_button-effect h2 {
  font-family: "Roboto", sans-serif;
  font-size: 50px;
  margin-bottom: 60px;
  font-weight: 700;
}
.res_button-effect a {
  margin-right: 10px;
}
.res_button-effect a:nth-child(2) {
  background-color: #370d59;
}
.res_button-effect a:nth-child(2):hover {
  background-color: #8920df;
}
.res_button-effect a:nth-child(3) {
  background-color: #660066;
}
.res_button-effect a:nth-child(3):hover {
  background-color: #ff00ff;
}
.res_button-effect a:nth-child(4) {
  background-color: #135153;
}
.res_button-effect a:nth-child(4):hover {
  background-color: #2fcad0;
}
.res_button-effect a:nth-child(5) {
  background-color: #66000e;
}
.res_button-effect a:nth-child(5):hover {
  background-color: #ff0022;
}
.res_button-effect a:nth-child(6) {
  background-color: #1c204a;
}
.res_button-effect a:nth-child(6):hover {
  background-color: #4650b9;
}
.res_button-effect a:nth-child(7) {
  background-color: #412539;
}
.res_button-effect a:nth-child(7):hover {
  background-color: #a25d8e;
}
.res_button-effect a:nth-child(8) {
  background-color: #444b1b;
}
.res_button-effect a:nth-child(8):hover {
  background-color: #a9bb44;
}
.res_button-effect a:nth-child(9) {
  background-color: #513915;
}
.res_button-effect a:nth-child(9):hover {
  background-color: #cb8f34;
}
.res_button-effect a:last-child {
  margin-right: 0px;
}

/* res_button styles !!!YOU NEED THEM 
  !!!ALSO YOU NEED TO ADD FONTWESOME */
.res_effect {
  text-align: center;
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: #fff;
  text-transform: capitalize;
  /* background-color: - add your own background-color */
  font-family: "Roboto", sans-serif;
  /* put your font-family */
  font-size: 18px;
  padding: 20px 0px;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  overflow: hidden;
}

/* effect-1 styles */
.res_effect.res_effect-1 {
  transition: all 0.2s linear 0s;
}
.res_effect.res_effect-1:before {
  content: "\1F872";
  font-family: FontAwesome;
  font-size: 15px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 5px;
  top: 5px;
  opacity: 0;
  height: 100%;
  width: 25px;
  transition: all 0.2s linear 0s;
}
.res_effect.res_effect-1:hover {
  text-indent: -25px;
}
.res_effect.res_effect-1:hover:before {
  opacity: 1;
  text-indent: 7px;
}

.lock {
  pointer-events: none;
  cursor: default;
}
.res_lock {
  position: absolute;
  top: 0;
  background-color: black;
  opacity: 0.8;
  z-index: 2000;
  width: 60px;
  height: 60px;
}

.ant-modal-content {
  background-color: white !important;
  border: black 2px solid !important;
  box-shadow: none !important;
  border-radius: 25px !important;
}

.res_button-effect a:hover {
  color: white !important;
  font-size: 23px;
  bottom: 10px;
}
