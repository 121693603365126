.c-outer {
  margin: 12px;
}
.c-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5c656;
  overflow: hidden;
  animation: fadeIn ease 4s;
  -webkit-animation: fadeIn ease 4s;
  -moz-animation: fadeIn ease 4s;
  -o-animation: fadeIn ease 4s;
  -ms-animation: fadeIn ease 4s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.c-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 63%;
  height: 100%;
  background: #3c4043;
}
.c-container {
  position: relative;
  min-width: 1100px;
  min-height: 550px;
  display: flex;
  z-index: 2;
  /* background-color: #ffff; */
}
.c-container .c-contactinfo {
  border-radius: 3px;
  color: #3c4043;
  position: absolute;
  top: 40px;
  width: 350px;
  height: calc(100% - 80px);
  background: #f5c656;
  z-index: 1;
  padding: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: space-between;
  /* box-shadow:0 20px 25px rgba(0,0,0,.15); */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.c-container .c-contactinfo h2,
.c-container .c-contactform h2 {
  color: #3c4043;
  font-size: 27px;
  font-weight: 700;
  font-family: "PT Serif", serif;
}
.c-container .c-contactinfo ul.c-info {
  position: relative;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.c-container .c-contactinfo ul.c-info li {
  position: relative;
  list-style: none;
  display: flex;
  margin: 20px 0;
  /* cursor: pointer; */
  /* pointer-events: none; */
  align-items: flex-start;
}
.c-container .c-contactinfo ul.c-info li span:nth-child(1) {
  width: 30px;
  min-width: 30px;
  margin-left: 0;
}
.c-container .c-contactinfo ul.c-info li span:nth-child(1) div {
  color: #3c4043;
  max-width: 100%;
  /* cursor: pointer; */
  /* filter: invert(1); */
}
.fas {
  /* color: #3c4043; */
  font-size: 30px;
}
.c-container .c-contactinfo ul.c-info li span:nth-child(2) {
  font-size: 17px;
  color: #3c4043;
  margin-left: 10px;
  font-weight: 500;
  font-family: "PT Serif", serif;
}
.c-container .c-contactinfo ul.c-sci {
  /* color: #3c4043; */
  position: relative;
  display: flex;
  justify-content: space-evenly;
}
.c-container .c-contactinfo ul.c-sci li {
  list-style: none;
  margin-right: 15px;
}
.c-container .c-contactinfo ul.c-sci li a {
  text-decoration: none;
  /* color: #3c4043; */
}
.c-twitter,
.c-fb,
.c-insta,
.c-link,
.c-pin,
.c-discord {
  padding-top: 5px;
  color: #3c4043;
}
.c-twitter:hover {
  color: #1da1f2;
}
.c-fb:hover {
  color: #4267b2;
}
.c-pin:hover {
  color: #e60023;
}
.c-link:hover {
  color: #0e76a8;
}
.c-insta:hover {
  color: #c13584;
}
.c-discord:hover {
  color: #738adb;
}
.fab {
  padding-top: 5px;
  font-size: 30px;
  /* color: #3c4043; */
}
.c-container .c-contactform {
  border-radius: 3px;
  position: absolute;
  padding: 40px 50px;
  padding-left: 250px;
  margin-left: 150px;
  width: calc(100% - 150px);
  height: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 50px 50px rgba(0, 0, 0, 0.25);
}
/* .c-container .c-contactform h2{
    color:#3c4043;
    font-size: 24px;
    font-weight: 500;
  } */
.c-container .c-contactform .c-formbox {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* padding-top: 30px; */
}
.c-container .c-contactform .c-formbox .c-inputbox {
  position: relative;
  margin-bottom: 42px;
}
.c-container .c-contactform .c-formbox .c-inputbox.w50 {
  width: 47%;
  font-family: "PT Serif", serif;
}
.c-container .c-contactform .c-formbox .c-inputbox.w100 {
  width: 100%;
  font-family: "PT Serif", serif;
}
.c-container .c-contactform .c-formbox .c-inputbox input {
  width: 100%;
  resize: none;
  padding: 5px 0;
  font-size: 18px;
  font-weight: 300;
  color: #333;
  border: none;
  outline: none;
  border-bottom: 1px solid #777;
  font-family: "PT Serif", serif;
}
.c-container .c-contactform .c-formbox .c-inputbox textarea {
  /* padding:55px; */
  width: 100%;
  resize: none;
  padding: 5px;
  font-size: 18px;
  font-weight: 300;
  color: #333;
  outline: none;
  height: 120px;
  border: 1px solid #777;
}
.c-container .c-contactform .c-formbox .c-inputbox span {
  position: absolute;
  left: -25px;
  padding: 5px 0;
  pointer-events: none;
  font-size: 18px;
  font-weight: 300;
  transition: 0.3s;
  font-family: "PT Serif", serif;
}
.c-container .c-contactform .c-formbox .c-inputbox input:focus ~ span,
.c-container .c-contactform .c-formbox .c-inputbox input:valid ~ span {
  transform: translateY(-20px);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #3c4043;
  font-weight: 500;
}
/* placeholder{
    margin-left: 1%;
    transform: translateY(-20px);
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 1px;
    color:#095a55;
    font-weight: 500;
  } */
.c-container .c-contactform .c-formbox .c-inputbox input[type="submit"] {
  border-radius: 3px;
  font-weight: 500;
  position: relative;
  cursor: pointer;
  background: #3c4043;
  color: #fff;
  border: None;
  max-width: 150px;
  padding: 12px;
}
.c-container .c-contactform .c-formbox .c-inputbox input[type="submit"]:hover {
  background: #f5c656;
  color: #3c4043;
}
.c-received {
  /* padding: 3px 5px; */
  padding-left: 5px;
  color: #3c4043;
  font-size: 20px;
  background-color: #f5c656;
  border-radius: 5px;
}
.c-receivepara {
  margin-bottom: 0;
}
@media (max-width: 1200px) {
  .c-container {
    width: 90%;
    min-width: auto;
    margin: 20px;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
  }
  .c-container .c-contactinfo {
    top: 0;
    height: 550px;
    position: relative;
    box-shadow: none;
  }
  .c-contactinfo .c-contactform {
    position: relative;
    width: calc(100% - 350px);
    padding-left: 0;
    margin-left: 0;
    padding: 40px;
    height: 550px;
    box-shadow: none;
  }
}
@media (max-width: 991px) {
  .c-section {
    background: #fff38e;
    min-height: 75vh;
    padding-top: 55px;
  }
  .c-section::before {
    display: none;
  }
  .c-container {
    box-shadow: none;
    display: flex;
    flex-direction: column-reverse;
  }
  .c-container .c-contactform {
    margin-left: 0;
    /* padding-left: ; */
    padding: 40px 50px;
    width: 100%;
    height: 540px;
  }
  .c-container .c-contactinfo {
    display: none;
    width: 100%;
    height: auto;
    flex-direction: row;
  }
  .c-container .c-contactinfo ul.c-sci {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .c-container .c-contactform .c-formbox .c-inputbox.cbtn {
    margin-bottom: 0;
  }
  .c-contactinfo {
    display: none;
  }
}

@media (max-width: 600px) {
  .c-container .c-contactform {
    padding: 25px;
    height: 520px;
  }
  .c-container .c-contactinfo {
    padding: 25px;
    flex-direction: column;
    align-items: flex-start;
  }
  .c-container .c-contactinfo ul.c-sci {
    width: 100%;
    justify-content: space-around;
  }
  .c-section {
    padding-top: 40px;
  }
}
@media (max-width: 500px) {
  .c-section {
    min-height: 90vh;
  }
  .c-section {
    padding-top: 200px;
  }
  .c-container .c-contactform {
    height: 670px;
  }
  .c-container .c-contactform .c-formbox .c-inputbox input:focus ~ span,
  .c-container .c-contactform .c-formbox .c-inputbox textarea:focus ~ span,
  .c-container .c-contactform .c-formbox .c-inputbox input:valid ~ span,
  .c-container .c-contactform .c-formbox .c-inputbox textarea:valid ~ span {
    font-size: 15px;
  }
  .c-container .c-contactform .c-formbox .c-inputbox.w50 {
    width: 100%;
  }
}
@media (max-width: 350px) {
  .c-container .c-contactinfo ul.c-sci {
    justify-content: center;
  }
  .c-container .c-contactinfo {
    padding: 10px;
  }
  .c-container .c-contactinfo ul.c-info li span:nth-child(2) {
    font-size: 15px;
  }
  .c-received {
    font-size: 15px;
  }
  .c-container .c-contactform h2 {
    font-size: 22.9px;
  }
}
@media (max-width: 292px) {
  .c-container .c-contactinfo {
    padding: 10px;
  }
  .c-container .c-contactform h2 {
    font-size: 21px;
  }
}
