@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");

.journal-slider-wrapper {
  position: relative;
  height: 70vh;
  overflow: hidden;
}

.slide {
  /* height: h;
  background-size: cover !important; */
}

.jr-sliderMain {
  padding-top: 65px;
}

.slide::before {
  content: "";
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0.9))
  );
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.9)); */
  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 15;
  background: url("./arrow.png") no-repeat center center / 25px;
  width: 35px;
  height: 35px;
  text-indent: -9999px;
  cursor: pointer;
  background-color: black;
  opacity: 0.5;
  padding: 50px 20px;
  /* margin: 0 20px; */
  /* border-radius: 20px; */
  transition: 0.2s;
}

.previousButton:hover,
.nextButton:hover {
  background: url("./arrow.png") no-repeat center center / 25px;
  background-color: black;
  opacity: 1;
  /* border-radius: 54px; */
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
  left: 2px;
}

.nextButton {
  right: 0px;
}

.nextButton:hover {
  right: 2px;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 64px;
  line-height: 1;
}

.slider-content .inner p {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

@media (max-height: 500px) {
  .journal-slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
}

@media (max-width: 640px) {
  /* .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  } */
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}
/* @media (max-width: 1200px) {
  .journal-slider-wrapper {
    height: calc(70vh - (1200px - var(--variable-width)) * 0.38);
  }
}

@media (max-height: 1400px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 2.26));
  }
}
@media (max-height: 1200px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 1.45));
  }
}
@media (max-height: 1100px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 1.05));
  }
}
@media (max-height: 950px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.645));
  }
}
@media (max-height: 900px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.615));
  }
}
@media (max-height: 860px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.565));
  }
}

@media (max-height: 800px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.465));
  }
}

@media (max-height: 700px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.425));
  }
}

@media (max-height: 640px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.4));
  }
}

@media (min-width: 1200px) {
  .journal-slider-wrapper {
    height: calc((70vh - ((1200px - var(--variable-width)) * 0.4)) - 20vh);
  }
} */

@media (max-width: 800px) {
  .previousButton,
  .nextButton {
    background: url("./arrow.png") no-repeat center center / 15px;
    width: 30px;
    height: 35px;
    text-indent: -9999px;
    cursor: pointer;
    background-color: black;
    opacity: 0.5;
    padding: 35px 12px;
    transition: 0.2s;
  }

  .previousButton:hover,
  .nextButton:hover {
    background: url("./arrow.png") no-repeat center center / 15px;
    background-color: black;
    opacity: 1;
    /* border-radius: 54px; */
  }
}

@media (max-width: 500px) {
  .previousButton,
  .nextButton {
    background: url("./arrow.png") no-repeat center center / 10px;
    width: 20px;
    height: 35px;
    text-indent: -9999px;
    cursor: pointer;
    background-color: black;
    opacity: 0.5;
    padding: 25px 4px;
    transition: 0.2s;
  }

  .previousButton:hover,
  .nextButton:hover {
    background: url("./arrow.png") no-repeat center center / 10px;
    background-color: black;
    opacity: 1;
    /* border-radius: 54px; */
  }
}
