@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

.containers {
  overflow: hidden;
  padding: 4rem 0 0;
  font-size: 2rem;
  width: 100%;
  z-index: -1;
  color: black;
  /* font-family: "Roboto", sans-serif; */
  display: flex;
  flex-direction: column;
}

.box2,
.box3 {
  margin: 0rem 12rem;
}
.box2 {
  margin-top: 3rem;
  color: rgb(30, 50, 56);
  display: inline-block;
  position: relative;
}
.line {
  margin-left: 0;
  display: block;
  font-size: 3rem;
}
.text1,
.text12 {
  margin-left: 0;
  font-size: 4rem;
  margin-bottom: 1rem;
  position: relative;
  animation: text 2.5s linear infinite;
}
.text12 {
  color: rgb(250, 197, 0);
}
@keyframes text {
  0% {
    margin-bottom: -3rem;
  }
  35% {
    letter-spacing: 0.2rem;
    margin-bottom: -3rem;
  }
  85% {
    letter-spacing: 0rem;
    margin-bottom: -3rem;
  }
}
.box3 {
  display: flex;
  justify-content: space-between;
}
.box3first {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}
.box3firstcontent {
  font-family: "PT Serif", serif;
  display: flex;
  align-items: center;
  font-size: 2rem;
}
.box3second {
  display: flex;
  align-items: center;
}
.box3second img {
  padding: 1.5rem;
  width: 30rem;
  height: 29rem;
  animation: updown 3s linear infinite;
}
@keyframes updown {
  0% {
    padding-top: 0rem;
  }
  33% {
    padding-top: 0.4rem;
  }
  66% {
    padding-top: 0.8rem;
  }
  100% {
    padding-top: 0rem;
  }
}
.land-box-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 210vh;
  overflow: hidden;
}
.land-box-area li {
  position: absolute;
  display: block;
  list-style: none;
  width: 25px;
  height: 25px;
  background: rgba(250, 196, 0, 0.651);
  animation: animate 20s linear infinite;
  bottom: -120px;
  z-index: 0;
}
.land-box-area li:nth-child(1) {
  left: 86%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}
.land-box-area li:nth-child(2) {
  left: 12%;
  width: 30px;
  height: 30px;
  animation-delay: 1.5s;
  animation-duration: 8s;
}
.land-box-area li:nth-child(3) {
  left: 70%;
  width: 100px;
  height: 100px;
  animation-delay: 5s;
}
.land-box-area li:nth-child(4) {
  left: 42%;
  width: 90px;
  height: 90px;
  animation-delay: 0s;
  animation-duration: 15s;
}
.land-box-area li:nth-child(5) {
  left: 65%;
  width: 40px;
  height: 40px;
  animation-delay: 0s;
}
.land-box-area li:nth-child(6) {
  left: 15%;
  width: 110px;
  height: 110px;
  animation-delay: 3.5s;
}
@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 0;
  }
  10% {
    transform: translateY(0) rotate(20deg);
    opacity: 0.2;
  }
  30% {
    transform: translateY(0) rotate(80deg);
    opacity: 1;
  }
  100% {
    transform: translateY(-700px) rotate(360deg);
    opacity: 0;
  }
}
.land-flex-box2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 50px 50px;
}
.land-counter {
  font-size: 3.8rem;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-weight: 900;
}
.land-outer-counter {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}
.counter-title {
  font-size: 2rem;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  font-family: "PT Serif", serif;
}
@media (max-width: 1398px) {
  .box2 {
    font-size: 140%;
  }
  .box3firstcontent {
    font-size: 90%;
  }
  .box3second img {
    width: 25rem;
    height: 24rem;
  }
  /* .land-counter {
    font-size: 120%;

  } */
  .counter-title {
    font-size: 100%;
  }
}
@media (max-width: 1241px) {
  .text1,
  .text12 {
    font-size: 120%;
  }
  .box2,
  .box3 {
    margin: 0rem 5rem;
  }
}
@media (max-width: 1005px) {
  .box2 {
    margin: 0rem 7rem;
  }
  .box2 {
    text-align: justify;
  }
  .text1,
  .text12,
  .line {
    font-size: 100%;
  }
  .box3second img {
    width: 20rem;
    height: 19rem;
  }
  .box3 {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 0;
  }
  .box3first {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .box3firstcontent {
    font-size: 80%;
    height: 25vh;
    text-align: justify;
    padding: 0rem;
    margin-top: 25px;
  }
  .land-box-area li:nth-child(4) {
    display: none;
  }
  .box3second img {
    width: 24rem;
    height: 23rem;
  }
  .land-counter {
    /* font-size: 100%; */
    font-size: 3rem;
    margin: 0px 25px;
  }
  .counter-title {
    font-size: 90%;
  }
  .land-flex-box2 {
    margin-top: 25px;
  }
}
@media (max-width: 837px) {
  .land-counter {
    /* font-size: 90%; */
    font-size: 2.7rem;
  }
  .counter-title {
    font-size: 80%;
  }
  .land-flex-box2 {
    margin-top: 25px;
  }
  .box3firstcontent {
    font-size: 70%;
  }
}
@media (max-width: 693px) {
  .land-counter-set {
    padding-left: 20px;
    /* background-color: brown; */
  }
  .box2,
  .box3 {
    margin: 0rem 3rem;
  }
  .text1,
  .text12 {
    font-size: 85%;
  }
  .line {
    font-size: 75%;
  }
  .box3firstcontent {
    /* height: 38vh; */
    /* font-size: 75%; */
    margin: 25px 0rem;
  }
  .box3second img {
    width: 20rem;
    height: 19rem;
  }
  .land-outer-counter {
    margin: 0 65px;
  }
  .land-flex-box2 {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: -20px;
  }
  .land-counter-set {
    padding-left: 12px;
    /* background-color: brown; */
  }
  .box3firstcontent {
    font-size: 60%;
  }
}
@media (max-width: 510px) {
  .box2 {
    text-align: center;
  }
  .line {
    font-size: 70%;
  }
  .box3firstcontent {
    /* height: 30vh; */
    /* font-size: 80%; */
    margin-top: 0;
  }
  .box3second img {
    width: 15rem;
    height: 15rem;
    padding-bottom: 0;
  }
  .land-counter {
    /* font-size: 80%; */
    font-size: 2.2rem;
  }
  .counter-title {
    font-size: 70%;
  }
  .land-outer-counter {
    margin-left: 50px;
  }
  .land-flex-box2 {
    /* margin-top: 60px; */
  }
  .box3firstcontent {
    font-size: 50%;
  }
}
@media (max-width: 462px) {
  .line {
    font-size: 65%;
  }
  .box2 {
    margin: 0 1rem;
  }
  /* .box3firstcontent {
    font-size: 70%;
  } */
  .land-outer-counter {
    margin: 0 40px;
  }
  .box3firstcontent {
    font-size: 50%;
    margin-top: 20px;
  }
}

@media (max-width: 400px) {
  /* .land-flex-box2 {
    margin-top: 40px;
  } */
  .land-outer-counter {
    margin: 0 35px;
  }
  .box3second {
    padding-bottom: 20px;
  }
  .box3firstcontent {
    margin-top: 0;
    margin-bottom: 30px;
  }
}
@media (max-width: 371px) {
  /* .land-flex-box2 {
    margin-top: 40px;
  } */
  .land-outer-counter {
    margin: 0 20px;
  }
  .box3firstcontent {
    margin-bottom: 30px;
  }
}

@media (max-width: 365px) {
  .text1,
  .text12 {
    font-size: 70%;
  }
  .line {
    font-size: 50%;
  }
  .box3 {
    margin: 0rem 1.5rem;
  }
  /* .box3firstcontent {
    height: 48vh;
  } */
  .land-flex-box2 {
    margin-top: 0;
  }
}
@media (max-width: 320px) {
  .box3second {
    padding-bottom: 30px;
  }
  .land-flex-box2 {
    margin-top: 20px;
  }
  .land-outer-counter {
    margin: 0 10px;
  }
}
