.event-image-grid {
  --gap: 16px;
  --num-cols: 4;
  --row-height: 300px;
  box-sizing: border-box;
  padding: var(--gap);
  padding-top: 6px;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  grid-auto-rows: var(--row-height);
  gap: var(--gap);
  margin-bottom: 10px;
  background-color: white;
}
.event-image-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.4s ease-in-out;
  cursor: pointer;
}

.event-image-grid img:hover {
  filter: brightness(80%);
}

.event-image-grid-col-2 {
  grid-column: span 2;
}

.event-image-grid-row-2 {
  grid-row: span 2;
}

.photo-seemore:hover {
  opacity: 1;
  transform: rotate3d(45deg);
}
.photo-seemore .seemore {
  position: relative;
  width: 100%;
  height: 100%;
  top: -100%;
  font-size: 2em;
  color: antiquewhite;
  background-color: black;
  opacity: 0.5;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.photo-seemore .seemore:hover {
  opacity: 0.8;
  text-decoration: underline;
}
.seemore p {
  margin: 0;
}
.photo-modal{
  width: 500px;
  height: 500px;
}
.modal {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 65px;
  left: 0;
  background-color:rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
  color: #fff;
  z-index: 1000;
  overflow: auto;
  opacity: 0;
  pointer-events: none;

  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalImg {
  width: 80%;
  max-width: 700px;
  max-height: 80vh;
}
.modalTxt {
  margin-top: 1em;
}
.close {
  position: relative;
  top: 1em;
  right:45%;
  font-size: 1.5em;
  cursor: pointer;
}
.modal.appear { 
  opacity: 1;
  pointer-events: all;
}
.modal.appear .modalImg,
.modal.appear .modalTxt {
  animation: zoom 0.3s linear;
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@media screen and (max-width: 1024px) {
  .event-image-grid {
    --num-cols: 2;
    --row-height: 200px;
  }
}
@media screen and (max-width: 600px) {
  .event-image-grid {
    --num-cols: 2;
    --row-height: 100px;
  }
}
@media screen and (max-width: 300px) {
  .modalContent{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    position: relative;
    top: 20%;
  }
}
