@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");

.journal-slider-wrapper {
  position: relative;
  height: 70vh;
  overflow: hidden;
}

.slide {
  /* height: h;
  background-size: cover !important; */
}

.journal-slider-content img {
  width: 100%;
  height: 70vh;
}

.jr-sliderMain {
  padding-top: 65px;
}

.slide::before {
  content: "";
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0.9))
  );
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.9)); */
  bottom: 0;
  left: 0;
}

/* .previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 15;
  background: url("https://www.svgrepo.com/show/1024/right-arrow.svg") no-repeat
    center center / 16px;
  width: 35px;
  height: 35px;
  text-indent: -9999px;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 20px;
}

.previousButton:hover,
.nextButton:hover {
  background: url("https://www.svgrepo.com/show/1024/right-arrow.svg") no-repeat
    center center / 18px;
  background-color: #ffffff;
  border-radius: 54px;
} */

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
  left: 2px;
}

.nextButton {
  right: 0px;
}

.nextButton:hover {
  right: 2px;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 64px;
  line-height: 1;
}

.slider-content .inner p {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

@media (max-height: 500px) {
  .journal-slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
}

@media (max-width: 640px) {
  /* .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  } */
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}
@media (max-width: 1200px) {
  .journal-slider-wrapper {
    /* height: calc(70vh - (1200px - var(--variable-width)) * 0.38); */
    height: 420px;
  }
  .journal-slider-content img {
    height: 420px;
  }
}
@media (max-width: 1100px) {
  .journal-slider-wrapper {
    height: 410px;
  }
  .journal-slider-content img {
    height: 410px;
  }
}
@media (max-width: 1050px) {
  .journal-slider-wrapper {
    height: 400px;
  }
  .journal-slider-content img {
    height: 400px;
  }
}
@media (max-width: 1000px) {
  .journal-slider-wrapper {
    height: 390px;
  }
  .journal-slider-content img {
    height: 390px;
  }
}
@media (max-width: 950px) {
  .journal-slider-wrapper {
    height: 380px;
  }
  .journal-slider-content img {
    height: 380px;
  }
}
@media (max-width: 900px) {
  .journal-slider-wrapper {
    height: 370px;
  }
  .journal-slider-content img {
    height: 370px;
  }
}
@media (max-width: 850px) {
  .journal-slider-wrapper {
    height: 355px;
  }
  .journal-slider-content img {
    height: 355px;
  }
}
@media (max-width: 800px) {
  .journal-slider-wrapper {
    height: 340px;
  }
  .journal-slider-content img {
    height: 340px;
  }
}
@media (max-width: 750px) {
  .journal-slider-wrapper {
    height: 325px;
  }
  .journal-slider-content img {
    height: 325px;
  }
}
@media (max-width: 700px) {
  .journal-slider-wrapper {
    height: 310px;
  }
  .journal-slider-content img {
    height: 310px;
  }
}
@media (max-width: 650px) {
  .journal-slider-wrapper {
    height: 295px;
  }
  .journal-slider-content img {
    height: 295px;
  }
}
@media (max-width: 625px) {
  .journal-slider-wrapper {
    height: 285px;
  }
  .journal-slider-content img {
    height: 285px;
  }
}
@media (max-width: 600px) {
  .journal-slider-wrapper {
    height: 275px;
  }
  .journal-slider-content img {
    height: 275px;
  }
}
@media (max-width: 575px) {
  .journal-slider-wrapper {
    height: 265px;
  }
  .journal-slider-content img {
    height: 265px;
  }
}
@media (max-width: 550px) {
  .journal-slider-wrapper {
    height: 255px;
  }
  .journal-slider-content img {
    height: 255px;
  }
}
@media (max-width: 525px) {
  .journal-slider-wrapper {
    height: 245px;
  }
  .journal-slider-content img {
    height: 245px;
  }
}
@media (max-width: 500px) {
  .journal-slider-wrapper {
    height: 235px;
  }
  .journal-slider-content img {
    height: 235px;
  }
}
@media (max-width: 475px) {
  .journal-slider-wrapper {
    height: 225px;
  }
  .journal-slider-content img {
    height: 225px;
  }
}
@media (max-width: 450px) {
  .journal-slider-wrapper {
    height: 215px;
  }
  .journal-slider-content img {
    height: 215px;
  }
}
@media (max-width: 425px) {
  .journal-slider-wrapper {
    height: 205px;
  }
  .journal-slider-content img {
    height: 205px;
  }
}
@media (max-width: 400px) {
  .journal-slider-wrapper {
    height: 195px;
  }
  .journal-slider-content img {
    height: 195px;
  }
}
@media (max-width: 375px) {
  .journal-slider-wrapper {
    height: 185px;
  }
  .journal-slider-content img {
    height: 185px;
  }
}
@media (max-width: 350px) {
  .journal-slider-wrapper {
    height: 175px;
  }
  .journal-slider-content img {
    height: 175px;
  }
}
@media (max-width: 325px) {
  .journal-slider-wrapper {
    height: 165px;
  }
  .journal-slider-content img {
    height: 165px;
  }
}
@media (max-width: 300px) {
  .journal-slider-wrapper {
    height: 155px;
  }
  .journal-slider-content img {
    height: 155px;
  }
}
/* 
@media (max-height: 1400px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 2.26));
  }
}
@media (max-height: 1200px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 1.45));
  }
}
@media (max-height: 1100px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 1.05));
  }
}
@media (max-height: 950px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.645));
  }
}
@media (max-height: 900px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.615));
  }
}
@media (max-height: 860px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.565));
  }
}

@media (max-height: 800px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.465));
  }
}

@media (max-height: 700px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.425));
  }
}

@media (max-height: 640px) {
  .journal-slider-wrapper {
    height: calc(70vh - ((1200px - var(--variable-width)) * 0.4));
  }
}

@media (min-width: 1200px) {
  .journal-slider-wrapper {
    height: calc((70vh - ((1200px - var(--variable-width)) * 0.4)) - 20vh);
  }
} */

/* 


@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");

.slider-wrapper {
  position: relative;
  height: 70vh;
  overflow: hidden;
}

.slide {
  height: 100vh;
  background-size: cover !important;
}
.jr-sliderMain{
  padding-top: 65px;
}

.slide::before {
  content: "";
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0.9))
  );
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 15;
  background: url("https://www.svgrepo.com/show/1024/right-arrow.svg") no-repeat
    center center / 16px;
  width: 35px;
  height: 35px;
  text-indent: -9999px;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 20px;
}

.previousButton:hover,
.nextButton:hover {
  background: url("https://www.svgrepo.com/show/1024/right-arrow.svg") no-repeat
    center center / 18px;
  background-color: #ffffff;
  border-radius: 54px;
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
  left: 2px;
}

.nextButton {
  right: 0px;
}

.nextButton:hover {
  right: 2px;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 64px;
  line-height: 1;
}

.slider-content .inner p {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

@media (max-height: 500px) {
  .slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
} */

@media (max-width: 640px) {
  /* .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  } */
}
/* 
@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
} */
