.land-box {
  margin: 15px 35px;
  height: 8%;
  width: 15%;
  border-radius: 5px;
  /* padding-bottom: 30px; */
}

.kand-spon-heading {
  margin: 50px 0;
}

.land-flex-box {
  display: flex;
  justify-content: space-evenly;
  padding: 10px 50px 10px;
  align-items: center;
  flex-wrap: wrap;
}

.company-image {
  width: 100%;
  height: auto;
  padding: 10px 20px 5px;
}

.land-sponhead {
  text-align: center;
  font-size: 3rem;
  padding-top: 80px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: rgb(30, 50, 56);
}

@media only screen and (max-width: 800px) {
  .land-box {
    width: 20%;
    margin: 0 100px;
  }
  /* .company-image{
          margin: 0 20px;
      } */
  .land-flex-box {
    /* padding-bottom: 10px; */
    padding: 10px 0;
    /* justify-content: left; */
  }
}

@media only screen and (max-width: 700px) {
  .land-box {
    width: 20%;
    margin: 0 80px;
  }
}

@media only screen and (max-width: 600px) {
  .land-box {
    /* border: 4px solid yellow ; */
    width: 25%;
    margin: 0 60px;
  }
}
@media only screen and (max-width: 500px) {
  .land-box {
    /* border: 4px solid yellow ; */
    width: 25%;
    margin: 0 40px;
  }
  .land-sponhead {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 400px) {
  .land-box {
    /* border: 4px solid yellow ; */
    width: 25%;
    margin: 0 20px;
  }
  .land-sponhead {
    font-size: 2rem;
  }
}

/* @media only screen and (max-width : 350px) {
    .land-box{
        border: 4px solid yellow ;
        width: 45%;
        margin: 5px;
    }
    .land-sponhead{
        font-size: 2rem;
    }
} */
@media only screen and (max-width: 300px) {
  .land-box {
    /* border: 4px solid yellow ; */
    width: 25%;
    /* margin: 5px; */
  }
}
