@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro");
@import url("https://fonts.googleapis.com/css?family=Varela+Round");
@font-face {
	font-family: 'Telefon Black';
	src: url('https://raw.githubusercontent.com/arnabmunshi/fontface/master/TelefonBlack/Telefon-Black.woff2') format('woff2'), url('https://raw.githubusercontent.com/arnabmunshi/fontface/master/TelefonBlack/Telefon-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}
.page-event {
	background-color: #fdca1294 ;
	
	/* padding-bottom: 40px; */
	font-family: "Varela Round", sans-serif !important;
}
.page-event .event-cover {
	/* background-color: #e0b107e1; */
	width: calc(98vw +5px);
	/* height: 75px; */
	padding: 0 15px;
	border-bottom: 7px solid rgba(0, 0, 0, 0.623);
	/* background-image: url('https://goo.gl/NA3iQP'); */
	/* background-position: center center;
	background-size: cover; */
}
.page-event .event-cover .event-heading {
	font-family: 'Telefon Black';
	font-size: 120px;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	text-align: center;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: black;
	text-shadow: 2px 2px 8px #fdcc0c;
	transition: font-size 0.3s;
}
.page-event .event-cover .event-heading::first-letter {
	color: #fdcc0c;
	text-shadow: 2px 2px 8px black;
}
.page-event .upcoming-sec {
	width: 70%; /* or whatever width you want. */
   	max-width:70%;
	display: inline-block;
    height: 5vh;
	margin: 0px auto;
	border-bottom: 7px solid rgba(0, 0, 0, 0.623);

	padding: 0;

	display: flex;
	justify-content: center;
	align-items: center;	
}
.page-event .upcoming-sec .event-heading {
	padding : 0px 0;
	font-size: 20px;
	color: black;
}
@media only screen and (max-width: 1035px){
	.page-event{
		/* width: 1035px;
		max-width: 1035px; */
		/* padding: 0 130px; */
		margin: 0;
	}
}

@media only screen and (max-width: 1020px){
	/* .page-event{
		width:1025px; 
   		max-width:1025px;
		display: inline-block;
	} */
	.event-cover{
		/* width: 770px; */
		/* padding: 0 130px;
		margin: 0; */
		/* margin: 0 80px; */
	}
}
@media only screen and (max-width:500px){
	.page-event .event-cover .event-heading{
		font-size: 70px;
	}
}
@media only screen and (max-width:300px){
	.page-event .event-cover .event-heading{
		font-size: 50px;
	}
}