@import url("https://fonts.googleapis.com/css?family=Robot:300,400,500,700,900&display=swap");

.about-full {
  background-color: #efefef;
  animation: fadeIn ease 4s;
  -webkit-animation: fadeIn ease 4s;
  -moz-animation: fadeIn ease 4s;
  -o-animation: fadeIn ease 4s;
  -ms-animation: fadeIn ease 4s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
/* about css */

#section_logo {
  display: grid;
  grid-template-columns: 45% 50%;
  margin: 0px auto;
  width: "100%";
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 80px;
  margin-bottom: 120px;
}

.section_logo {
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
  /* border: 3px solid #e21212; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.section_logo:hover {
  transition: 1s;
  transform: scale(1.1);
}

.section_logo::after {
  transition: 0.4s;
  transform: scale(1);
}
.section_logo img {
  width: 450px;
  height: 430px;
  /* border-radius: 50%; */
  /* margin-top: -20px; */
  border-radius: 50%;
  /* background-color: rgb(255, 255, 255); */
}

.section_logo img:hover {
  box-shadow: 0 25px 30px rgba(0, 0, 0, 0.5);
  /* box-shadow: 0 25px 130px rgba(228, 198, 62, 0.603); */
  /* width: 460px; */
  /* border-radius: 50%; */
  /* height: 440px; */
  transform: scale(1.1);
  transition: all ease-in-out 0.8s;
}

@media (max-width: 900px) {
  #section_logo {
    grid-template-columns: 100%;
    margin: 0 auto;
    width: "90%";
  }
  .section_logo img {
    width: 300px;
    height: 300px;
    border-radius: 1000px;
    /* background-color: rgb(255, 255, 255); */
  }
}

.about-box {
  transform: translate(0%, -0%);
  width: 100%; /* accordingly change this */
  height: fit-content; /* accordingly change this */
  /* background: #262626; */
  box-sizing: border-box;
  padding: 15px;
  transition: 0.5s;
  overflow: hidden;
  /* background:linear-gradient( #fac117 , #f8e856 ); */
  background: linear-gradient(#ffe608, #ff9900);
  border-radius: 10px;
}

.about-box p {
  margin: 10px;
  padding: 20px;
  /* color: #fff; */
  font-size: 1.5rem;
  transition: 0.5s;
  transition-delay: 0.5s;
  transform: scale(0.8);
}

.about-box:before {
  content: "";
  position: absolute;
  width: 0%;
  height: 0%;
  top: 0;
  left: 0;
  border-top: 15px solid #fff;
  /* accordingly change this 8*/
  border-left: 15px solid #fff;
  /* accordingly change this 8*/
  opacity: 0;
  transition: 2.3s;
  box-sizing: border-box;

  transform: skew(5deg, 4deg);
}

.about-box:after {
  content: "";
  position: absolute;
  width: 0%;
  height: 0%;
  bottom: 0;
  right: 0;
  border-bottom: 15px solid #fff;
  /*accordingly change this*/
  border-right: 15px solid #fff;
  /* accordingly change this*/
  opacity: 0;
  transition: 2.9s;
  box-sizing: border-box;

  transform: skew(5deg, 4deg);
}

.about-box:hover:before {
  width: 100%;
  height: 100%;
  opacity: 1;
}

.about-box:hover:after {
  width: 100%;
  height: 100%;
  opacity: 1;
}

.about-box:hover p {
  transform: scale(0.9);
}

.about-box:hover {
  box-shadow: 0 25px 30px rgba(0, 0, 0, 0.5);
  /* background:linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%); */
  /* background:linear-gradient( #fac117 , #f8e856 );
    border-radius: 10px; */
}

.about-box span {
  position: absolute;
  top: 0;
  left: 120%;
  width: 100%;
  height: 100%;
  /* background: rgba(255, 255, 255, 0.1); */
  background: rgba(150, 146, 146, 0.089);
  transition: 0.5s;
  transition-delay: 1s;
  transform: skewX(5deg);
}

.about-box:hover span {
  left: -120%;
}

/*------------ honey comb css----------------- */

h1 {
  font-size: 60px;
}

.comb_0 {
  display: flex;
  /* background-color: #ffeb3b; */
  justify-content: center;
  grid-template-columns: 195px 195px 195px 195px;
  margin: -2.2vw 0px 0px 0vw;
}

.comb_1 {
  display: flex;
  justify-content: center;
  /* background-color: #fdd835; */
  grid-template-columns: 195px 195px 195px 195px;
  margin: -2.2vw 0px 0px 0vw;
}

.comb_2 {
  display: flex;
  justify-content: center;
  /* background-color: #fbc02d; */
  grid-template-columns: 195px 195px 195px 195px;
  margin: -2.2vw 0px 0px 0vw;
}

.comb_3 {
  display: flex;
  justify-content: center;
  /* background-color: #f9a825; */
  grid-template-columns: 15px 15px 95px 15px;
  margin: -2.2vw 0px 0px 0vw;
}

.comb_4 {
  display: flex;
  justify-content: center;
  /* background-color: #f57f17; */
  grid-template-columns: 195px 190px 138px 55px;
  margin: -2.2vw 1vw 0 -29vw;
  /*upper ki height   left side  #  right side */
}

.comb_5 {
  display: flex;
  justify-content: center;
  /* background-color: #f57f17; */
  grid-template-columns: 195px 10px 18px 5px;
  margin: -10vw 1vw 0 31vw;
}

/* ------------------------------------------------- */

.comb_0 .item {
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: -150px 0px; */
}

.comb_1 .item {
  background-size: cover;
  background-repeat: no-repeat;
}

.comb_2 .item:nth-child(1) {
  background-size: cover;
  background-repeat: no-repeat;
}

.comb_2 .item:nth-child(2) {
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position:0 -60px; */
}

.comb_2 .item:nth-child(3) {
  background-size: cover;
  background-repeat: no-repeat;
}

.comb_2 .item:nth-child(4) {
  background-size: cover;
  background-repeat: no-repeat;
}

.comb_2 .item:nth-child(5) {
  background-size: cover;
}

.comb_3 .item:nth-child(1) {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: -50px 0px;
}

.comb_3 .item:nth-child(2) {
  /* background-image: url('./1.jpg'); */
  background-size: cover;
}

.comb_3 .item:nth-child(3) {
  /* background-image: url('./1.jpg'); */
  background-size: cover;
  background-position: -50px 0px;
}

.comb_3 .item:nth-child(4) {
  /* background-image: url('./1.jpg'); */
  background-size: cover;
}

.comb_3 .item:nth-child(5) {
  /* background-image: url('./1.jpg'); */
  background-size: cover;
}

.comb_3 .item:nth-child(6) {
  /* background-image: url('./1.jpg'); */
  background-size: cover;
}

.comb_4 .item:nth-child(1) {
  /* background-image: url('./1.jpg'); */
  background-size: 200px 225px;
  background-repeat: no-repeat;
  background-position: 0 -30px;
}

.comb_4 .item:nth-child(2) {
  /* background-image: url('./1.jpg'); */
  background-size: cover;
}

.comb_5 .item:nth-child(1) {
  /* background-image: url('./1.jpg'); */
  background-size: cover;
}

.comb_5 .item:nth-child(2) {
  /* background-image: url('./1.jpg'); */
  background-size: cover;
}

.honeycomb-cell_title {
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  font-size: 1em;
  transition: opacity 350ms;
}

.item:hover .honeycomb-cell_title {
  opacity: 0;
}

.honeycomb-cell:hover .honeycomb-cell_title {
  opacity: 0;
}

.honeycomb-cell:hover::after {
  opacity: 0;
}

.honeycomb_hidden {
  display: none;
  opacity: 0;
  width: 250px;
  margin: 0 12.5px;
}
.honey {
  /* background: linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%); */
  /* background:linear-gradient( #fac117 , #f8e856 ); */
  /* background:linear-gradient( #ffe608,#ff9900  ); */
  background-image: url(/src/pages/About/temp9.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0px auto;
}

.item {
  /* */
  width: 9vw;
  height: 9vw;
  background-size: cover;
  background-color: #fff;
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  margin: 0.5vw;
  cursor: pointer;
}

.item:hover {
  transition: 0.5s;
  transform: scale(1.3);
}
.item:before {
  content: " ";
  position: absolute;
  width: 9vw;
  height: 9vw;
  background-color: rgba(0, 0, 0, 0.493);
}

.item:hover:before {
  background-color: rgb(24, 29, 35, 0);
  transform: scale(1.1);
}

.honeyDiv {
  display: block;
  margin-left: 11.6vw;
  position: absolute;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 5px;
  top: 1;
  z-index: 900;
  border-radius: 15px;
  transform: scale(1);
  /* background: linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%); */
  background-color: #161616;
  color: #fed76b;
  box-shadow: 0 30px 40px rgba(0, 0, 0, 0.3);
  min-width: 150px;
  /* background: linear-gradient(#f3bb15, #ece380); */
}

.honeyDiv h1 {
  padding-top: 9px;
  font-size: 26px;
  margin-bottom: -10px;
  margin-left: 4px;
  margin-right: 4px;
  margin-top: -5px;
  font-weight: 800;
  color: #fed76b;
  text-align: center;
}

.honeyDiv p {
  padding-top: 6px;
  margin-top: -10px;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 17px;
  text-align: center;
  /* font-weight: 400; */
  font-family: "PT Serif", serif;
}

@media (max-width: 650px) {
  .honeyDiv {
    display: none;
  }
}

.notHoneyDiv {
  display: none;
}

/*--------------- department css-------------------------- */

#department {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-bottom: 40px;
}

@media (max-width: 700px) {
  #department {
    grid-template-columns: auto;
  }
}
@media (min-width: 700px) {
  .dept {
    margin: 0 23%;
  }
}
.card {
  background: #fff;
  transform-style: preserve-3d;
  transform: perspective(1000px);
  box-shadow: 10px 20px 40px rgba(251, 201, 15, 0.5);
  transition: 10s;
  margin-top: 50px;
  margin-left: 7%;
  margin-right: 7%;
  background: linear-gradient(#fcbf09, #faf9ef);
  border-radius: 15px;
}

.card .imgBox {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  transform-origin: left;
  transform-style: preserve-3d;
  background-color: white;
  transition: 7s;
  /* box-shadow: 10px 20px 40px rgba(226, 67, 67, 0.5); */

  /* background: linear-gradient(#fcbf09, #faf9ef); */
  background: linear-gradient(120deg, #ffc60a, yellow, #ad8605);
  border-color: solid;
  border-radius: 15px;
  border-width: 0 100px 100px 0;
  /* background:linear-gradient( #ff7300 , #ffe608 ); */
}
.card .imgBox:hover {
  box-shadow: 10px 20px 40px rgba(251, 201, 15, 0.5);
}
.card .imgBox:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  width: 3rem;
  height: 5rem;
  transform: translate(-1rem, -1.5rem) rotate(-32.7deg);
  background: linear-gradient(
    to left bottom,
    transparent 50%,
    #7da9b3 50px,
    #d2f1fb 60px,
    #025364 50px
  );
  /* box-shadow: 0 6px 4px -4px rgba(251, 201, 15, 0.5); */
}

.card .imgBox p {
  font-size: 18px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  padding: 0 40px 0 40px;
  text-align: justify;
  font-weight: 400;
}

@media (max-width: 1300px) {
  .card .imgBox p {
    padding: 10px;
    font-size: 16.5px;
  }
}
@media (max-width: 990px) {
  .card .imgBox p {
    padding: 10px;
    font-size: 15px;
  }
}
@media (max-width: 790px) {
  .card .imgBox p {
    padding: 10px;
    font-size: 20px;
  }
}
@media (max-width: 540px) {
  .card .imgBox p {
    padding: 10px;
    font-size: 15px;
  }
}
@media (max-width: 370px) {
  .card .imgBox p {
    padding: 10px;
    font-size: 13px;
  }
}
h1 {
  font-size: 70px;
  font-family: "Courier New", Courier, monospace;
}

.card:hover .imgBox {
  transform: rotateY(-350deg);
  transition-duration: 2s;
}

.card .imgBox div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

.card .imgBox div img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

.card .details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#faf9ef, #87cefa);
  color: rgb(32, 32, 32);
  border-radius: 15px;
}

.card .details .content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  flex-direction: column;
}

.card .details .content h2 {
  text-align: center;
  font-weight: 700;
  letter-spacing: 1px;
  word-spacing: 3px;
  margin-top: -50px;
  font-size: 32px;
  color: #913831;
}
.card .details .content h3 {
  font-weight: 500;
  margin-right: 30px;
  align-self: flex-end;
}
#social p {
  font-size: 18px;
}

#social {
  padding: 20px;
}
.card .details .content h2 span {
  font-size: 0.8em;
  color: rgb(32, 32, 32);
  background-color: yellow;
}

.card .details .content .social-icons {
  position: relative;
  display: flex;
}

.card .details .content .social-icons a {
  display: inline-block;
  margin-top: 10px;
  width: 35px;
  height: 35px;
  background: #333;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin: 5px;
  font-size: 18px;
  transition: 0.2s;
}

.card .details .content .social-icons a:hover {
  background: #e21212;
}

@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css");
#social {
  margin: 20px 10px;
  text-align: center;
}

.headingEffect::before {
  transform: scaleX(0);
  transform-origin: bottom right;
}
.headingEffect:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.headingEffect::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  /* background: rgba(251, 201, 15, 0.5); */
  background-color: #fec90f;
  z-index: -1;
  transition: transform 0.3s ease;
}
.headingEffect {
  position: relative;
  font-size: 2.4rem !important;
}

.drop {
  position: relative;
  width: 450px;
  height: 450px;
  /* background-color: rgb(231, 231, 231); */
  /* opacity: .6; */
  box-shadow: inset 10px 10px 10px rgba(0, 0, 0, 0.05),
    15px 25px 10px rgba(0, 0, 0, 0.05), 15px 25px 10px rgba(0, 0, 0, 0.05),
    inset -10px -10px 15px rgba(225, 225, 225, 0.9);
  border-radius: 50%;
  /* background-color: red; */
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.drop::before {
  content: "";
  position: absolute;
  top: 105px;
  left: 55px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #fff;
}

.drop::after {
  content: "";
  position: absolute;
  top: 75px;
  left: 80px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
}
.drop img {
  width: 100%;
}

@media (max-width: 1090px) {
  .drop {
    width: 400px;
    height: 400px;
  }
}
@media (max-width: 960px) {
  .drop {
    width: 350px;
    height: 350px;
  }
}
@media (max-width: 900px) {
  .drop {
    width: 350px;
    height: 350px;
    margin-bottom: 40px;
  }
}

@media (max-width: 400px) {
  .drop {
    width: 80%;
    height: auto;
  }
}

@media (max-width: 330px) {
  .drop {
    width: 80%;
  }
}

/* 
#department {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 40px;
}

@media (max-width:800px) {
    #department {
        grid-template-columns: auto;
    }
}

.card {
    background: #fff;
    transform-style: preserve-3d;
    transform: perspective(1000px);
    box-shadow: 10px 20px 40px rgba(2, 2, 2, 0.5);
    transition: 10s;
    margin-top: 50px;
    margin-left: 7%;
    margin-right: 7%;


    background: linear-gradient(#fcbf09, #faf9ef);
}

.card .imgBox {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    transform-origin: left;
    transform-style: preserve-3d;
    background-color: white;
    transition: 1s;
    
    box-shadow: 10px 20px 40px rgba(2, 2, 2, 0.5);

    
    background:linear-gradient( #f87509 , #ffe608 );
}
.card .imgBox p{
    padding: 20px;
    font-size: 20px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  
}


@media (max-width:1300px){
    .card .imgBox p{
        padding: 10PX;
    font-size: 16.5px;
    }
}
@media (max-width:990px){
    .card .imgBox p{
        padding: 10PX;
    font-size: 15px;
    }
}
@media (max-width:790px){
    .card .imgBox p{
        padding: 10PX;
    font-size: 20px;
    }
}
@media (max-width:540px){
    .card .imgBox p{
        padding: 10PX;
    font-size: 15px;
    }
}
@media (max-width:370px){
    .card .imgBox p{
        padding: 10PX;
    font-size: 13px;
    }
}




.card:hover .imgBox {
    
    transform: translateY(-930px);
    transition-duration: 1s;
}

.card .imgBox div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform-style: preserve-3d;
    backface-visibility: hidden;
}

.card .imgBox div img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    transform: rotateY(180deg);
}

.card .details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card .details .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}

.card .details .content h2 {
    text-align: center;
    font-weight: 700;
    font-size: 50px;
    line-height: 1em;
    
}
.card .details .social p{
    padding: 20PX;
    font-size: 60px;

}
.card .details .content h2 span {
    font-size: 0.8em;
    color: #e21212;
}

.card .details .content .social-icons {
    position: relative;
    display: flex;
}

.card .details .content .social-icons a {
    display: inline-block;
    margin-top: 10px;
    width: 35px;
    height: 35px;
    background: #333;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 5px;
    font-size: 18px;
    transition: 0.2s;
}

.card .details .content .social-icons a:hover {
    background: #e21212;
}

@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css');
#social {
    margin: 20px 10px;
    text-align: center;
} */
