/*video*/
@import url("https://fonts.googleapis.com/css?family=Fira+Sans:300,400,500,700,300italic,400italic,500italic,700italic");
@import url("https://fonts.googleapis.com/css?family=Varela+Round");
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:400,700");
.event-video-container {
  width: 100%;
  margin: 6px 0 0 0;
}

iframe {
  width: 95%;
  height: calc(110ew / eval("width/height"));
  display: flex;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 20px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.heading {
  margin: 6px auto;
  width: 80%;
  font-size: 20px;
  font-weight: bold;

  letter-spacing: -1px;
  justify-content: center;
  flex-wrap: wrap;
}
.headingSec {
  display: flex;
  align-items: center;
  font-size: 16px;
  letter-spacing: -1px;
  color: black;
  flex-wrap: wrap;
  margin: 6px auto;
  /* justify-content: center; */
}

.headingSec h1 {
  margin-right: 10px;
}
.headingSec h2 {
  margin-top: 25px;
  font-size: 20px;
}
.event-video {
  display: grid;
  grid-template-columns: auto 5vw auto;
  column-gap: 10px;
  /* margin-bottom: 25px; */
  padding: 60px;
}
.event-video:nth-child(2) {
  padding-top: 0;
  padding-bottom: 0;
}
.event-video1 {
  padding: 10px 0;
}
.videosection-seemore {
  position: relative;
  width: 95%;
  height: 81%;
  top: -81%;
  font-size: 2em;
  color: antiquewhite;
  background-color: black;
  opacity: 0.8;
  z-index: 500;
  /* text-decoration: underline; */
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.videosection-seemore:hover {
  opacity: 0.8;
  text-decoration: underline;
}
.seemore p {
  margin: 0;
}
.videosection-seemore p a {
  color: antiquewhite;
}

@media screen and (max-width: 800px) {
  .event-video {
    margin: 0 auto;
    grid-template-columns: auto;
  }
}
@media screen and (max-width: 500px) {
  .event-video {
    padding: 20px;
    grid-template-columns: 90%;
    margin: 0 auto;
  }
  iframe {
    width: 110%;
    height: 250px;
  }
  .videosection-seemore {
    width: 110%;
    height: 78%;
    position: relative;
    top: -78%;
  }
}
@media screen and (max-width: 410px) {
  .event-video {
    grid-template-columns: 90%;
    margin: 0;
    padding: 0 20px;
  }
  .event-video:nth-child(1) {
    padding-top: 20px;
  }
  iframe {
    width: 110%;
    height: 150px;
  }

  .videosection-seemore {
    height: 150px;
    position: relative;
    top: -83%;
  }
  .event-video1:nth-child(3) {
    height: 200px;
  }
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  iframe {
    width: 900px;
    height: 500px;
    margin: 0;
  }
  .event-video {
    margin: 0 auto;
    grid-template-columns: auto;
  }
}
