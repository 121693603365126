@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");

:root {
  --variable-width: 100vw;
  --variable-height: 100vh;
  --temp: var(--variable-height /);
}

.land-slider-wrapper {
  position: relative;
  top: 65px;
  height: 92vh;
  overflow: hidden;
}

.slide {
  /* height: 100vh; */
  /* background-size: cover !important; */
}

.slider-content img {
  width: 100%;
  height: 92vh;
}

.slide::before {
  content: "";
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
  left: 2px;
}

.nextButton {
  right: 0;
}

.nextButton:hover {
  right: 2px;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 64px;
  line-height: 1;
}

.slider-content .inner p {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}
/* slider media queries  */

@media (max-width: 1100px) {
  .land-slider-wrapper {
    height: 90vh;
  }
  .slider-content img {
    height: 90vh;
  }
}

@media (max-width: 1000px) {
  .land-slider-wrapper {
    height: 550px;
  }
  .slider-content img {
    height: 550px;
  }
}
@media (max-width: 950px) {
  .land-slider-wrapper {
    height: 500px;
  }
  .slider-content img {
    height: 500px;
  }
}

@media (max-width: 900px) {
  .land-slider-wrapper {
    height: 450px;
  }
  .slider-content img {
    height: 450px;
  }
}
@media (max-width: 850px) {
  .land-slider-wrapper {
    height: 425px;
  }
  .slider-content img {
    height: 425px;
  }
}
@media (max-width: 800px) {
  .land-slider-wrapper {
    height: 400px;
  }
  .slider-content img {
    height: 400px;
  }
}
@media (max-width: 750px) {
  .land-slider-wrapper {
    height: 380px;
  }
  .slider-content img {
    height: 380px;
  }
}
@media (max-width: 700px) {
  .land-slider-wrapper {
    height: 365px;
  }
  .slider-content img {
    height: 365px;
  }
}
@media (max-width: 650px) {
  .land-slider-wrapper {
    height: 350px;
  }
  .slider-content img {
    height: 350px;
  }
}
@media (max-width: 600px) {
  .land-slider-wrapper {
    height: 330px;
  }
  .slider-content img {
    height: 330px;
  }
}
@media (max-width: 550px) {
  .land-slider-wrapper {
    height: 310px;
  }
  .slider-content img {
    height: 310px;
  }
}
@media (max-width: 500px) {
  .land-slider-wrapper {
    height: 280px;
  }
  .slider-content img {
    height: 280px;
  }
}
@media (max-width: 475px) {
  .land-slider-wrapper {
    height: 265px;
  }
  .slider-content img {
    height: 265px;
  }
}
@media (max-width: 450px) {
  .land-slider-wrapper {
    height: 250px;
  }
  .slider-content img {
    height: 250px;
  }
}
@media (max-width: 425px) {
  .land-slider-wrapper {
    height: 235px;
  }
  .slider-content img {
    height: 235px;
  }
}
@media (max-width: 400px) {
  .land-slider-wrapper {
    height: 210px;
  }
  .slider-content img {
    height: 210px;
  }
}

@media (max-width: 375px) {
  .land-slider-wrapper {
    height: 195px;
  }
  .slider-content img {
    height: 195px;
  }
}
@media (max-width: 350px) {
  .land-slider-wrapper {
    height: 180px;
  }
  .slider-content img {
    height: 180px;
  }
}
@media (max-width: 325px) {
  .land-slider-wrapper {
    height: 165px;
  }
  .slider-content img {
    height: 165px;
  }
}
@media (max-width: 300px) {
  .land-slider-wrapper {
    height: 150px;
  }
  .slider-content img {
    height: 150px;
  }
}
