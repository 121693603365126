.main1 {
  padding: 1px 0 1px 0;
}

.member {
  list-style: none;
  margin-bottom: 0;
}

/* style member component */
.list-members {
  background: #fffdde;
  width: 90%;
  margin: 4% auto;
  display: flex;
  flex-wrap: wrap;
}

.member {
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.member-image {
  width: 50%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.member-image img {
  width: 100%;
  height: 100%;
  transition: 1s;
}

.member-image:hover img {
  transform: scale(1.1);
}

.member-info {
  width: 50%;
  text-align: center;
  font-family: "PT Serif", serif;
}

.member-info h6 {
  margin: 20px 0;
  padding: 0 10px;
  font-weight: lighter;
  font-family: "PT Serif", serif;
}

.before-info {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  font-family: "PT Serif", serif;
}

.before-info h3 {
  font-size: 1.5rem;
  padding-top: 10px;
  transition: all 0.5s;
  text-align: center;
  margin-bottom: -10px;
  font-family: "PT Serif", serif;
  font-weight: 600;
}

.before-info h6 {
  font-size: 1rem;
  transition: all 0.5s;
  font-family: "PT Serif", serif;
}

.about-showit {
  display: none;
  margin-left: 0;
  font-family: "PT Serif", serif;
}

.about-hideit {
  margin-left: 0;
  font-family: "PT Serif", serif;
}
.about-firstletter {
  margin-left: 0;
  font-family: "PT Serif", serif;
}
.after-info {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  transform: translateY(-20px);
  transition: all 0.1s;
  opacity: 0;
  font-family: "PT Serif", serif;
}

.about-techhead {
  margin-bottom: 50px;
  font-family: "PT Serif", serif;
}

.after-info p {
  padding: 0 10px;
  font-size: 1rem;
  margin-top: -40px;
  text-align: center;
  margin-left: 2px;
  font-family: "PT Serif", serif;
}

.after-info h4 {
  padding: 0 10px 15px;
  margin-top: 15px;
  font-size: 1.4rem;
  text-align: center;
  font-family: "PT Serif", serif;
  font-weight: 600;
}

.before-info:hover h3 {
  transform: rotateX(720deg) scale(0);
  opacity: 0;
  font-family: "PT Serif", serif;
  font-weight: 600;
}

.before-info:hover h6 {
  transform: rotateX(720deg) scale(0);
  opacity: 0;
  font-family: "PT Serif", serif;
}

.before-info:hover .after-info {
  transform: translateY(0);
  transition-delay: 0.6s;
  opacity: 1;
  font-family: "PT Serif", serif;
}

/* style social link */
.social-link .fab {
  width: 35px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #000;
  margin: 0 7px;
  cursor: pointer;
  transition: transform 0.5s;
}

.social-link .fab:hover {
  background: #000;
  color: #ffe501;
  transform: translateY(-7px);
}

/* Membuat segitiga */
.member-image::after {
  content: "";
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 15px solid #fffdde;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

/* Merubah posisi member-image dengan member-info */
@media screen and (min-width: 950px) {
  .member:nth-child(4n + 3) .member-info,
  .member:nth-child(4n + 4) .member-info {
    order: 1;
  }
  .member:nth-child(4n + 3) .member-image,
  .member:nth-child(4n + 4) .member-image {
    order: 2;
  }

  /* Merubah posisi sigitiga pada baris genap */
  .member:nth-child(4n + 3) .member-image::after,
  .member:nth-child(4n + 4) .member-image::after {
    left: 0;
    right: auto;
    transform: translateY(-50%) rotateZ(180deg);
  }
}

/* Mobile Styles */
@media screen and (max-width: 950px) {
  .list-members {
    width: 95%;
  }
  .member {
    flex-basis: 100%;
    font-size: 14px;
  }
  .social-link .fab {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .member:nth-child(even) .member-info {
    order: 1;
  }
  .member:nth-child(even) .member-image {
    order: 2;
  }

  /* Merubah posisi sigitiga elemen genap */
  .member:nth-child(even) .member-image::after {
    left: 0;
    right: auto;
    transform: translateY(-50%) rotateZ(180deg);
  }
}

@media (max-width: 500px) {
  .about-hideit {
    display: none;
  }
  .about-firstletter {
    text-transform: uppercase;
    margin-left: 0;
  }
  .before-info h3 {
    font-size: 1rem;
    margin-bottom: -10px;
  }
  .before-info h6 {
    font-size: 0.8rem;
  }
  .about-showit {
    display: inline;
    margin-left: 0;
  }
  .after-info h4 {
    font-size: 1rem;
    margin-bottom: -20px;
  }

  .after-info p {
    margin-top: 10px;
    font-size: 0.7rem;
  }
}
